import * as React from 'react'

const DownloadSvg = ({
  color,
  ...props
}: {
    color: string,
    style: any
}) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)" fill={color}>
      <path d="m9 13.722 5.415-5.415a1.015 1.015 0 0 0 0-1.433 1.015 1.015 0 0 0-1.433 0l-2.968 2.968V1.014a1.015 1.015 0 0 0-2.027 0v8.828L5.019 6.874a1.015 1.015 0 0 0-1.433 0 1.015 1.015 0 0 0 0 1.434L9 13.722Z" />
      <path d="M16.517 12.215c-.56 0-1.014.454-1.014 1.013v1.504c0 .684-.557 1.241-1.241 1.241H3.738a1.243 1.243 0 0 1-1.241-1.241v-1.504a1.015 1.015 0 0 0-2.027 0v1.504A3.272 3.272 0 0 0 3.738 18h10.524a3.272 3.272 0 0 0 3.268-3.268v-1.504c0-.559-.454-1.013-1.013-1.013Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h18v18H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default DownloadSvg
