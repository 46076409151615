import React, { useState } from 'react'
import { format, Locale } from 'date-fns'
import { DatePickerProps } from './types'
import 'react-datepicker/dist/react-datepicker.css'
import Typography from 'src/components/atoms/Typography'
import enLocale from 'src/utils/localeHelper'
import ReactDatePicker from 'react-datepicker'
import { DateFormatCalendar } from 'src/utils/formatHelper'
import Button from 'src/components/atoms/Button'
import { REACT_ENV } from 'src/utils/config'
import {
  ButtonContainer,
  CloseBtn,
  DatePickerBody,
  DatePickerContent,
  DatePickerHeader,
  DatePickerModal,
  DatePickerRange,
  DayDisabled,
  FakeField,
  SubmitBtn,
  Wrapper,
} from './styles'
import Checkbox from 'src/components/atoms/Checkbox'
import styled from 'styled-components'

const today = new Date()
today.setHours(0, 0, 0, 0)

const DateContainer = styled.div`
display: flex;
justify-content: space-around;
width: 100%;
`

const DatePicker: React.FC<DatePickerProps> = ({
  onChange,
  value,
  handleDate,
  onSubmit,
}: DatePickerProps) => {
  const { showDate, setShowDate } = handleDate ?? { showDate: false, setShowDate: () => { } }

  const [tentativeDate, setTentativeDate] = useState<any>({
    startDate: null,
    endDate: null,
  })

  const [isUnknown, setIsUnknown] = useState<boolean>(false)

  const closeDatePicker = () => {
    setShowDate(false)
  }

  const handleChangeDate = () => {
    onChange && onChange(tentativeDate)

    if (tentativeDate.endDate !== null && !showDate) {
      closeDatePicker()
    }
  }

  const onChangeTentativeDate = (dates: [Date | null, Date | null]) => {
    const [newStartDate, newEndDate] = dates
    const validDate = new Date(newStartDate ?? '')
    if (validDate.getTime() <= today.getTime()) {
      return
    }

    setTentativeDate({
      startDate: newStartDate,
      endDate: newEndDate,
    })
    setIsUnknown(false)
  }

  const onSubmitDate = () => {
    handleChangeDate && handleChangeDate()
    onSubmit && onSubmit()
  }

  const renderDayContents = (day: any, date: any) => {
    const validDate = new Date(date ?? '')
    if (validDate.getTime() <= today.getTime()) {
      return <DayDisabled title={date} className="disabled">{day}</DayDisabled>
    }
    return day
  }

  React.useEffect(() => {
    if (isUnknown) {
      setTentativeDate({
        startDate: null,
        endDate: null,
      })
    }
  }, [isUnknown])

  return (
    <>
      <Wrapper>
        <img
          src={`${REACT_ENV.PUBLIC_URL}/images/calendar-icon.png`}
          width="35"
          height="35"
        />
        {isUnknown && (!value[0] && !value[1])
          ? (
          <DateContainer>
            <Typography
              className="text-thin"
              variant="label"
              fontSize=".8rem"
              color="textSecondary"
              data-test="date-from-scanning"
            >
              Unknown
            </Typography>
          </DateContainer>
            )
          : (
          <DateContainer>
            <Typography
              className="text-thin"
              variant="label"
              fontSize=".8rem"
              color="textSecondary"
              data-test="date-from-scanning"
            >
              {value[0] ? format(value[0], DateFormatCalendar) : 'MM/DD/YYYY'}
            </Typography>
            <Typography
              className="text-to"
              variant="label"
              fontSize=".8rem"
              color="primary"
            >
              TO
            </Typography>
            <Typography
              className="text-thin"
              variant="label"
              fontSize=".8rem"
              color="textSecondary"
              data-test="date-to-scanning"
            >
              {value[1] ? format(value[1], DateFormatCalendar) : 'MM/DD/YYYY'}
            </Typography>
          </DateContainer>
            )}
      </Wrapper>
      {showDate && (
        <DatePickerModal>
          <DatePickerContent>
            <DatePickerHeader>
              <ButtonContainer>
                <CloseBtn onClick={closeDatePicker}>&times;</CloseBtn>
              </ButtonContainer>
              <Typography
                align="center"
                fontSize="1.8rem"
                fontSizeMobile="1.2rem"
                variant="label"
                style={{ margin: '0 0', padding: '.5rem 0 0 .5rem' }}
              >
                Scanning Tentative Date
              </Typography>
              <Typography
                align="center"
                color="black"
                fontSize=".8rem"
                variant="p"
                style={{ margin: '0 0', padding: '.5rem 0 0 .5rem' }}
              >
                Select a day, week or month if actual scanning date is not
                confirmed
              </Typography>
              <DatePickerRange>
                <FakeField>
                  <Typography variant="label" fontSize=".6rem">
                    Start Date
                  </Typography>
                  <Typography variant="label" fontSize=".8rem">
                    {value[0]
                      ? format(value[0], DateFormatCalendar)
                      : 'MM/DD/YYYY'}
                  </Typography>
                </FakeField>
                <FakeField>
                  <Typography variant="label" fontSize=".6rem">
                    End Date
                  </Typography>
                  <Typography variant="label" fontSize=".8rem">
                    {value[1]
                      ? format(value[1], DateFormatCalendar)
                      : 'MM/DD/YYYY'}
                  </Typography>
                </FakeField>
              </DatePickerRange>
            </DatePickerHeader>
            <DatePickerBody>
              <ReactDatePicker
                locale={enLocale as Locale}
                disabledKeyboardNavigation
                selected={tentativeDate ? tentativeDate.startDate : null}
                selectsRange
                startDate={tentativeDate ? tentativeDate.startDate : null}
                endDate={tentativeDate ? tentativeDate.endDate : null}
                monthsShown={2}
                onChange={(dates) => {
                  onChangeTentativeDate(dates as [Date | null, Date | null])
                }}
                dayClassName={(date: Date) =>
                  new Date(date ?? '').getTime() < today.getTime()
                    ? 'disabledDay'
                    : null
                }
                renderDayContents={renderDayContents}
                inline
              />
                <Checkbox
                  key="unknown"
                  onChange={() => setIsUnknown(!isUnknown)}
                  label="Unknown"
                  checked={isUnknown}
                />
            </DatePickerBody>
            {showDate && (
              <SubmitBtn >
                <Button
                  align="center"
                  bold
                  borderRadius=".5rem"
                  onClick={() => onSubmitDate()}
                  size="small"
                  uppercase
                  width="10rem"
                  data-test="btn-sumit-date"
                  disabled={!tentativeDate.endDate && !isUnknown}
                >
                  Submit Date
                </Button>
              </SubmitBtn>
            )}
          </DatePickerContent>
        </DatePickerModal>
      )}
    </>
  )
}

export default DatePicker
