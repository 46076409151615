// @ts-nocheck
import { ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, mergeMap, switchMap } from 'rxjs/operators'
import * as actions from 'src/state/quotation/actions'
import * as types from 'src/state/quotation/types'

export const initRFPEpic = (
  action$,
  _, // state
  { QuotationService },
) =>
  action$.pipe(
    ofType(types.INIT_RFP_ACTION),
    switchMap((action) =>
      from(QuotationService.initRFP(action.payload)).pipe(
        mergeMap((resp) =>
          of(
            actions.initRFPActionCompleted({ ...action.payload, ...resp.data }),
          ),
        ),
        catchError((e) => of(actions.initRFPActionFailed(e))),
      ),
    ),
  )

export const updateRFP = (
  action$,
  _, // state
  { QuotationService },
) =>
  action$.pipe(
    ofType(types.UPDATE_RFP_ACTION),
    switchMap((action) =>
      from(
        QuotationService.updateRFP(
          action.payload.stepType,
          action.payload.data,
          action.payload.id,
          action.payload.title,
          action.payload.comment,
        ),
      ).pipe(
        mergeMap((resp) =>
          action.payload.stepType === 'FinalStep'
            ? of(
              actions.updateRFPActionCompleted({
                ...action.payload,
                ...resp.data,
              }),
              actions.finishQuote(),
            )
            : of(
              actions.updateRFPActionCompleted({
                ...action.payload,
                ...resp.data,
              }),
            ),
        ),
        catchError((e) => of(actions.updateRFPActionCompleted(e))),
      ),
    ),
  )

export const donwloadRFP = (
  action$,
  _, // state
  { QuotationService },
) =>
  action$.pipe(
    ofType(types.DONWLOAD_RFP_ACTION),
    switchMap((action) =>
      from(QuotationService.donwloadRFP(action.payload.id)),
    ),
  )
