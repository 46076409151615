import { merge, isEqual } from 'lodash'
import { createSelectorCreator, defaultMemoize } from 'reselect'
import {
  KeyValue, ReducerHandler, ReduxAction,
} from 'src/interfaces/store/redux.helper'

export const mapToState = (map: KeyValue): ReducerHandler => (
  state: KeyValue,
) => merge({}, state, map)

export const createReducer = (
  initialState: KeyValue<any>,
  handlers: KeyValue<ReducerHandler>,
) => {
  return (state: KeyValue<any> = initialState, action: ReduxAction<any>) => {
    // eslint-disable-next-line no-prototype-builtins
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action)
    }

    return state
  }
}

export const forwardPayload = <T>(actionType: string) => (
  payload: T,
): ReduxAction<T> => ({
  payload,
  type: actionType,
})

export const staticPayload = <T = undefined>(
  payload: T,
  actionType: string,
) => (): ReduxAction<T> => ({
    payload,
    type: actionType,
  })

export const noPayload = (actionType: string) =>
  staticPayload(undefined, actionType)

export const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual)
