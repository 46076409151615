import React, { useState } from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { CoverImg, DescriptionModal, NextArrow, PrevArrow, SliderCarousel, SliderDescription, SliderItem, SliderTitle, SliderSubTitle, ContainerImg } from './styles'
import { isMobile, REACT_ENV, SLIDER_NEXT_LABEL, SLIDER_PREV_LABEL } from 'src/utils/config'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import Dialog from '../../../molecules/Dialog'
import Button from 'src/components/atoms/Button'
import DownloadSvg from 'src/components/atoms/Icons/Download'
import { EModalType } from 'src/interfaces/store/types'
import Typography from 'src/components/atoms/Typography'

type ISliderModal = {
  modal: boolean,
  setModal: any,
  info: any,
}
const displayCount = EModalType.LOD ? 2 : EModalType.LOD && isMobile ? 1 : 3
const SliderModal = ({ modal, setModal, info, ...props }: ISliderModal) => {
  const [currentIndex, setSlide] = useState(0)
  const NextCount = isMobile ? info.sliders.length : info.sliders.length - 1

  function handleArrow (currentIndex: number, type: string) {
    let currentSlider = 0
    const countFix = isMobile ? 1 : 2
    if (type === SLIDER_PREV_LABEL) {
      currentSlider = (!currentIndex ? currentIndex - countFix : currentIndex - 1) + info.sliders.length
    }
    if (type === SLIDER_NEXT_LABEL) {
      currentSlider = !currentIndex ? currentIndex + countFix : currentIndex + 1
    }
    return currentSlider % info.sliders.length
  }

  return (
  <Dialog open closeIcon={true} onClose={() => setModal(false)} title={info.title}>
      <Dialog.Body
        justifyContent="center"
        flexDirection="column"
      >
        <DescriptionModal type={info.type} dangerouslySetInnerHTML={{ __html: isMobile && info.descriptionMob ? info.descriptionMob : info.description }}>
        </DescriptionModal>
        {info.sliders.length > displayCount &&
          <PrevArrow onClick={() => setSlide((prev) => prev === info.sliders.length ? info.sliders.length - 1 : handleArrow(currentIndex, SLIDER_PREV_LABEL))}>
            <ArrowBackIosIcon />
          </PrevArrow>
        }
        <SliderCarousel
          centerMode
          centerSlidePercentage={100 / displayCount}
          selectedItem={currentIndex}
          showArrows={false}
          showThumbs={false}
        >
          {info.sliders.map((slider: any, index: number) =>
            <SliderItem key={slider.id}>
            {info.type === EModalType.LOD
              ? <ContainerImg>
                  {slider.img.map((img: any, indexImg: number) =>
                    (<div key={img.name}>
                    <Typography bold={false} fontSize="0.75rem" variant="label">{img.name}</Typography>
                    <CoverImg src={`${REACT_ENV.PUBLIC_URL}/images/${img.url}`} type={info.type} backgroundSize={index === 0 ? `${indexImg === 0 ? '40% auto' : '50% auto'}` : 'contain'}/>
                  </div>))}
                </ContainerImg>
              : <CoverImg src={`${REACT_ENV.PUBLIC_URL}/images/${slider.img}`} type={info.type}/>}
            <SliderTitle {...(slider.subTitle) ? { margin: '0', height: '1rem' } : {}}>{slider.title}</SliderTitle>
            {slider.subTitle && <SliderSubTitle>{slider.subTitle}</SliderSubTitle>}
            <SliderDescription dangerouslySetInnerHTML={{ __html: slider.description }}></SliderDescription>
          </SliderItem>)}
        </SliderCarousel>
            {info.type === EModalType.LOD &&
            <a
              href={`${REACT_ENV.PUBLIC_URL}/pdf/${info.type}/${info.document}`}
              download={info.document}
              target="_blank"
              rel="noreferrer"
            >
              <Button
                  margin='1rem 0 0 0'
                  align='flex-start'
                  width='6.75rem'
                  borderRadius="1rem"
                  onClick={() => {}}
                  size='large'
                  bold
                  minheight="2.188rem"
                  uppercase>
                <DownloadSvg color="white" style={{ marginRight: '0.5rem' }}/>
                PDF
              </Button>
            </a>
            }
        {info.sliders.length > displayCount &&
        <NextArrow onClick={() => setSlide((prev: any) => prev === NextCount ? 0 : handleArrow(currentIndex, SLIDER_NEXT_LABEL))}>
          <ArrowForwardIosIcon />
        </NextArrow>
        }
      </Dialog.Body>
    </Dialog>
  )
}

export default SliderModal
