import { devices } from 'src/utils'
import styled from 'styled-components'

export const FooterContainer = styled.div<{
  currentStep?: string | number
}>`
  align-items: center;
  color: ${({ theme }) => theme.palette.primary};
  display:flex;
  justify-content: start;
  width:100%;

  @media ${devices.mobileS} and (max-width: 63.9375rem) {
    flex-direction: column;
  }

  @media ${devices.mobileS} and (max-width: 35rem) {
    bottom: 5px;
    position: absolute;
    width: 90%;
    z-index: 2;
  }
`

export const LegendFooter = styled.div`
 @media ${devices.mobileS} and (max-width: 760px) {
    display: none;
 }
`
