/* eslint-disable no-prototype-builtins */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import Separator from 'src/components/atoms/Separator'
import Typography from 'src/components/atoms/Typography'
import MultipleSelect from 'src/components/molecules/MultipleSelect'
import { isExist } from '../../../utils/validationsHelper'
import { IdValue } from 'src/interfaces/store/quotation/interfaces'
import { nextStepAction, setAreasScanned, setCurrentStepdAction, setStepsCompletedAction, updateRFPAction } from 'src/state/quotation/actions'
import { selectAreasScanned, selectBasicData, selectCurrentStep } from 'src/state/quotation/selectors'
import styled, { css } from 'styled-components'
import Guard from '../Guard'
import { BgMobile, ContainerMobile, Grid, GridItem, ImgInformation, TitleMobile } from 'src/shared/styles'
import { bimDeliverablesOptions, buildingAreasOptions, DisciplinesInterestOptions, ScanningAreasOptions, scanningDeliverablesSlider, bimDeliverablesSlider, lodAdditionalDetails } from 'src/shared/Data'
import TextArea from 'src/components/atoms/TextArea'
import MultipleCheckbox from 'src/components/molecules/MultipleCheckbox'
import FooterButtons from 'src/components/molecules/FooterButtons'
import Theme from 'src/Theme'
import { MAX_CHARACTERS_TEXTAREA, OTHERLABEL, REACT_ENV } from 'src/utils/config'
import IconButton from '@mui/material/IconButton'
import useModal from 'src/hook/useModal'
import useRefresh from 'src/hook/useQuery/useRefresh'
import { EModalType } from 'src/interfaces/store/types'

const Wrapper = styled.div<{
  padding?: string
}>`
  background-color: ${Theme.palette.borderInput};
  border-radius: .5rem;
  border: 1px solid ${Theme.palette.lightGrey};
  display: flex;
  flex-direction: column;
  ${({ padding }) => padding ? css`padding: ${padding};` : ''}
  width: 100%;

  & button{
    margin: 0rem 1rem;
  }
`

const Step3 = () => {
  const dispatch = useDispatch()
  const router = useNavigate()
  const { setModal, setInfo } = useModal()
  const { reload, setReload, data } = useRefresh('AreasToBeScanned')
  const currentStep = useSelector(selectCurrentStep)
  const [options, setOptions] = useState<IdValue[]>(buildingAreasOptions)
  const [buildingAreas, setBuildingAreas] = useState<IdValue[]>([])
  const [otherBuildingAreaPurpose, setOtherBuildingAreaPurpose] = useState<string>('')
  const [interests, setInterest] = useState<IdValue[]>([])
  const [bimDeliverables, setBimDeliverables] = useState<IdValue[]>([])
  const [otherPurpose, setPurpose] = useState<string>('')
  const [otherScanningDeliverables, setOtherScanningDeliverables] = useState<string>('')
  const [otherBimDeliverablesOption, setOtherBimDeliverables] = useState<string>('')
  const [disabled, setDisabled] = useState({
    interests: true,
    bimDeliverables: true,
    scanningDeliverables: true,
  })
  const [bimDescription, setBimDescription] = useState<string>('')
  const [disabledBtn, setDisabledBtn] = useState<boolean>(false)
  const [scanningDeliverablesOptions, setScanningDeliverablesOptions] = useState<IdValue[]>([])
  const [required, setRequired] = useState<boolean>(false)
  const {
    additionalDetails,
    BuildingAreas,
    disciplinesOfInterest,
    otherBA,
    otherBimDeliverables,
    otherInteres,
    otherScanningRequiredDeliverables,
    scanningDeliverablesList,
    scanningRequiredDeliverables,
  } = useSelector(selectAreasScanned)
  const id = useSelector(selectBasicData).id

  const nextStep = () => {
    const optionsValidate = options.find((x) => !x.disabled && (x.input === undefined ? false : !x.input))
    if (optionsValidate || (!disabled.scanningDeliverables && !otherScanningDeliverables) ||
    (!disabled.bimDeliverables && !otherBimDeliverablesOption) ||
    (!disabled.interests && !otherPurpose)) {
      setRequired(true)
      return
    }
    const updData = {
      additionalDetails: bimDescription,
      BuildingAreas: buildingAreas,
      disciplinesOfInterest: interests,
      otherBA: otherBuildingAreaPurpose,
      otherBimDeliverables: otherBimDeliverablesOption,
      otherInteres: otherPurpose,
      otherScanningRequiredDeliverables: otherScanningDeliverables,
      scanningDeliverablesList: bimDeliverables,
      scanningRequiredDeliverables: scanningDeliverablesOptions,
    }
    dispatch(updateRFPAction({
      id: sessionStorage.getItem('idLanding') ?? id!,
      title: 'Scope Details',
      comment: 'Scope Details',
      data: {
        ...updData,
      },
      stepType: 'AreasToBeScanned',
    }))
    dispatch(setAreasScanned({
      ...updData,
    }))
    dispatch(nextStepAction())
    dispatch(setStepsCompletedAction({ id: 4 }))
    router('/quote/4')
  }

  function handleInput (item: IdValue) {
    const updateInput = buildingAreas.map((option: IdValue) => {
      if (option.id === item.id) {
        option.input = item.input
      }
      return option
    })
    setBuildingAreas(updateInput)
    setDisabledBtn(
      updateInput.some((building:IdValue) => building?.input === '' && !building.disabled),
    )
  }

  function handleChecked (item: IdValue) {
    setRequired(false)
    const enableInput: IdValue[] = options.map((option: IdValue) => {
      if (option.id === item.id) {
        option.disabled = !option.disabled
      }
      if (option.disabled && option.hasOwnProperty('input')) {
        option.input = ''
      }
      return option
    })
    setOptions(enableInput)
    const inputEmpty = enableInput.some(
      (building:IdValue) => building?.input === '' && !building.disabled,
    )
    setDisabledBtn(inputEmpty)
    return setBuildingAreas(
      isExist(buildingAreas, item.id).length ? buildingAreas.filter((opt) => opt.id !== item.id) : [...buildingAreas, item])
  }

  const onChangeDisciplinesInterest = (val: IdValue) => {
    setRequired(false)
    if (isExist(interests, val.id).length) {
      const removeOption = interests.filter((opt) => opt.id !== val.id)
      setInterest(removeOption)
      val.id === '8' && setPurpose('')
    } else {
      setInterest([...interests, val])
      if (val.id === '8') {
        const otherVal = val.value.split(':')
        otherVal.length > 1 && setPurpose(otherVal[1].trim())
      }
    }
  }
  const onChangeBimDeliverables = (val: IdValue) => {
    setRequired(false)
    if (isExist(bimDeliverables, val.id).length) {
      const removeOption = bimDeliverables.filter((opt) => opt.id !== val.id)
      setBimDeliverables(removeOption)
      val.id === '8' && setOtherBimDeliverables('')
    } else {
      setBimDeliverables([...bimDeliverables, val])
      if (val.id === '8') {
        const otherVal = val.value.split(':')
        otherVal.length > 1 && setOtherBimDeliverables(otherVal[1].trim())
      }
    }
  }

  const onChangeScanningDeliverables = (val: IdValue) => {
    setRequired(false)
    if (isExist(scanningDeliverablesOptions, val.id).length) {
      const removeOption = scanningDeliverablesOptions.filter((opt) => opt.id !== val.id)
      setScanningDeliverablesOptions(removeOption)
      val.value === OTHERLABEL && setOtherScanningDeliverables('')
    } else {
      setScanningDeliverablesOptions([...scanningDeliverablesOptions, val])
      if (val.value === OTHERLABEL) {
        const otherVal = val.value.split(':')
        otherVal.length > 1 && setOtherScanningDeliverables(otherVal[1].trim())
      }
    }
  }

  const onChangeBimDescription = (
    evt: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setBimDescription(evt.target.value)
  }

  function goToBack () {
    dispatch(setAreasScanned({
      BuildingAreas: buildingAreas,
      otherBA: otherBuildingAreaPurpose,
      scanningRequiredDeliverables: scanningDeliverablesOptions,
      disciplinesOfInterest: interests,
      otherInteres: otherPurpose,
      scanningDeliverablesList: bimDeliverables,
      additionalDetails: bimDescription,
      otherBimDeliverables: otherBimDeliverablesOption,
      otherScanningRequiredDeliverables: otherScanningDeliverables,
    }))
    dispatch(setCurrentStepdAction({
      currentStep: Number(currentStep) - 1,
    }))
    router('/quote/2')
  }

  function resetInputEmpty () {
    const resetBuilding = buildingAreasOptions.map((building: IdValue) => {
      if (!building.input) building.disabled = true
      return building
    })
    setOptions(resetBuilding)
  }

  function handleModal (type:any) {
    const slider:any = {
      scanning: {
        description: 'SirVey uses the most avanced technology to capture and document buildings of any size and shape.',
        descriptionMob: '',
        sliders: scanningDeliverablesSlider,
        title: 'Scanning Required Deliverables',
        type: 'scanning',
      },
      bim: {
        description: 'After capturing the data and producing point clouds, ENG can create smart BIM models and 2D as-built drawings to represent the captured information. The models can be also integrated into a facilities management system.',
        descriptionMob: 'ENG can create smart BIM models and 2D as-built drawings to represent the captured information. The models can be also integrated into a facilities management system.',
        sliders: bimDeliverablesSlider,
        title: 'BIM Required Deliverables',
        type: 'bim',
      },
      lod: {
        description: 'The Level of Detail determines the amount and degree of information that the BIM models will contain. <br/> To make sure we only include what you need, <strong>download the following PDFs</strong> to determine what LOD your <br/> project needs for the different building systems and components and let us know your preferences.',
        descriptionMob: 'The Level of Detail determines the amount and degree of information that the BIM models will contain. <br/> To make sure we only include what you need, <strong>download the following PDFs</strong> to determine what LOD your <br/> project needs for the different building systems and components and let us know your preferences.',
        sliders: lodAdditionalDetails,
        title: 'Level of Detail (LOD)',
        type: 'lod',
        document: 'LOD-CATALOG.pdf',
      },
    }
    setInfo(slider[type])
    setModal(true)
  }

  function setInfoStep (data:any) {
    const {
      BuildingAreas,
      otherBA,
      scanningRequiredDeliverables,
      disciplinesOfInterest,
      otherInteres,
      scanningDeliverablesList,
      additionalDetails,
      otherBimDeliverables,
      otherScanningRequiredDeliverables,
    } = data

    BuildingAreas && setBuildingAreas(BuildingAreas)
    otherBA && setOtherBuildingAreaPurpose(otherBA)
    scanningRequiredDeliverables && setScanningDeliverablesOptions(scanningRequiredDeliverables)
    disciplinesOfInterest && setInterest(disciplinesOfInterest)
    scanningDeliverablesList && setBimDeliverables(scanningDeliverablesList)
    additionalDetails && setBimDescription(additionalDetails)
    otherInteres && setPurpose(otherInteres)
    otherBimDeliverables && setOtherBimDeliverables(otherBimDeliverables)
    otherScanningRequiredDeliverables && setOtherScanningDeliverables(otherScanningRequiredDeliverables)
    setDisabled({
      bimDeliverables: !otherBimDeliverables,
      interests: !otherInteres,
      scanningDeliverables: !otherScanningRequiredDeliverables,
    })
  }

  useEffect(() => {
    resetInputEmpty()
    setInfoStep({
      BuildingAreas,
      otherBA,
      scanningRequiredDeliverables,
      disciplinesOfInterest,
      otherInteres,
      scanningDeliverablesList,
      additionalDetails,
      otherBimDeliverables,
      otherScanningRequiredDeliverables,
    })
  }, [])

  useEffect(() => {
  }, [buildingAreas, scanningDeliverablesList, disabledBtn])

  useEffect(() => {
    if (!reload) return
    [1, 2].map((step) => dispatch(setStepsCompletedAction({ id: step })))
    if (!data.hasOwnProperty('BuildingAreas')) return
    setInfoStep(data)
    dispatch(setCurrentStepdAction({
      currentStep: 3,
    }))
    setReload(false)
  }, [reload, data])

  return (
    <Guard>
      <TitleMobile>One step to go!</TitleMobile>
      <Grid>
        <GridItem>
          <Wrapper padding='0 0 .5rem'>
            <Typography variant='label' fontSize='1rem' style={{ padding: '1rem 0 0 1rem' }}>
              Areas to be Scanned (select one or more)
            </Typography>
            <Separator size='10px' />
            <MultipleCheckbox
              options={options}
              values={buildingAreas}
              handleChecked={handleChecked}
              handleInput={handleInput}
              required={required}
              sectionName='areas-scanned'
            />
          </Wrapper>
          <Wrapper padding='0 0 .5rem'>
            <Typography variant='label' fontSize='1rem' style={{ padding: '1rem 0 0 1rem' }}>
              Scanning Required Deliverables
              <IconButton onClick={ () => handleModal(EModalType.SCANNING) }>
              <ImgInformation
                src={`${REACT_ENV.PUBLIC_URL}/images/information.png`}
                data-test="info-scanning-required"
              />
              </IconButton>
            </Typography>
            <Separator size="10px" />
            <MultipleSelect
              options={ScanningAreasOptions}
              values={scanningDeliverablesOptions}
              onChange={onChangeScanningDeliverables}
              onChangeOther={(val: string) => setOtherScanningDeliverables(val)}
              other={otherScanningDeliverables}
              disabled={disabled.scanningDeliverables}
              setDisabled={(val) => setDisabled({
                ...disabled,
                scanningDeliverables: val,
              })}
              placeholder={'360 Tour, GPR findings reports, GPR CAD file, etc.'}
              required={required}
              sectionName='scanning-required'
            />
          </Wrapper>
        </GridItem>
        <GridItem>
          <Wrapper padding='0 0 .5rem'>
            <Typography variant='label' fontSize='1rem' style={{ padding: '1rem 0 0 1rem' }}>
              Disciplines of Interest (select one or more)
            </Typography>
            <Separator size="10px" />
            <MultipleSelect
              options={DisciplinesInterestOptions}
              values={interests}
              onChange={onChangeDisciplinesInterest}
              onChangeOther={(val: string) => setPurpose(val)}
              other={otherPurpose}
              disabled={disabled.interests}
              setDisabled={ (val) => setDisabled({
                ...disabled,
                interests: val,
              })}
              placeholder={'To continue you must write an option'}
              required={required}
              sectionName='disciplines-interest'
            />
          </Wrapper>
          <Wrapper padding='0 0 .5rem'>
            <Typography variant='label' fontSize='1rem' justifyContent= 'space-between' style= {{ padding: '1rem 0 0 1rem' }}>
              BIM Required Deliverables
              <IconButton onClick={() => handleModal(EModalType.BIM)}>
              <ImgInformation
                src={`${REACT_ENV.PUBLIC_URL}/images/information.png`}
                data-test="info-bim-required"
              />
              </IconButton>
            </Typography>
            <Separator size="10px" />
            <MultipleSelect
              options={bimDeliverablesOptions}
              values={bimDeliverables}
              onChange={onChangeBimDeliverables}
              onChangeOther={(val: string) => setOtherBimDeliverables(val)}
              other={otherBimDeliverablesOption}
              disabled={disabled.bimDeliverables}
              setDisabled={(val) => setDisabled({
                ...disabled,
                bimDeliverables: val,
              })}
              placeholder={'To continue you must write an option'}
              required={required}
              sectionName='bim-required'
            />
          </Wrapper>
          <Wrapper>
            <TextArea
              label='Additional Details'
              fontSize='1rem'
              value={bimDescription}
              onInput={onChangeBimDescription}
              onClickButton={() => handleModal(EModalType.LOD)}
              placeholder='Indicate other BIM requirements such as Level of Detail, Level of Accuracy, disciplines/elements/areas to be included/excluded, etc.'
              useFrame={true}
              maxlength={MAX_CHARACTERS_TEXTAREA}
              height='6.7rem'
              includeNote
              id="aditional-detail-form"
            />
          </Wrapper>
        </GridItem>
      </Grid>
      <Separator size='20px' />
      <Separator size="5.5rem" mobile/>
      <ContainerMobile>
        <FooterButtons
          gotoNextStep={nextStep}
          goBack={goToBack}
          label='Continue'
          currentStep={currentStep}
          />
        <BgMobile />
      </ContainerMobile>
    </Guard>
  )
}

export default Step3
