import React, { useEffect, useState } from 'react'
import Stepper from 'src/components/organisms/Stepper'
import BasicTemplate from 'src/components/templates/BasicTemplate'
import Step1 from 'src/components/organisms/Steps/Step1'
import Step2 from 'src/components/organisms/Steps/Step2'
import Step3 from 'src/components/organisms/Steps/Step3'
import Step4 from 'src/components/organisms/Steps/Step4'
import { useSelector } from 'react-redux'
import { selectCurrentStep, selectQuotationStatus } from 'src/state/quotation/selectors'
import { StoreStatus } from 'src/interfaces/store/types'
import { useLocation, useNavigate } from 'react-router-dom'

const Quote: React.FC = () => {
  const location = useLocation()
  const [stepContent, setStepContent] = useState<any>({
    0: <Step1 key="1.PI" />,
    1: <Step2 key="2.SW"/>,
    2: <Step3 key="3.ASC" />,
    3: <Step4 key="4.LTSC"/>,
  })
  const stepParams = location.pathname.split('/')[2]
  const currentStep = parseInt(stepParams) || useSelector(selectCurrentStep)
  const status = useSelector(selectQuotationStatus)
  const router = useNavigate()

  const headerLabel:any = {
    1: 'Welcome to SirVey!',
    2: 'Two steps to go!',
    3: 'One step to go!',
    4: 'Almost there!',
  }

  useEffect(() => {
    if (currentStep === 3) {
      const newContent = Object.values(stepContent).concat({})
      setStepContent({ ...newContent })
    }

    // if (status === StoreStatus.NOT_STARTED) {
    //   router.replace('/')
    // }

    if (status === StoreStatus.END_QUOTATION) {
      router('/thankyou')
    }
  }, [currentStep, status])

  return (
    <BasicTemplate onHeader={headerLabel[currentStep] ?? ''}>
      <Stepper currentStep={currentStep}>
        {stepContent[currentStep - 1]}
      </Stepper>
    </BasicTemplate>
  )
}

export default Quote
