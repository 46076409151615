import { devices } from 'src/utils'
import styled, { css } from 'styled-components'
import { PanelProps } from './types'

const Panel = styled.div<PanelProps>`
  ${({ height }) => `height: ${height}`};
  ${({ minHeight }) => `min-height: ${minHeight}`};
  ${({ width }) => `width: ${width}`};
  align-items: ${({ align = 'flex-start' }) => align};
  background-color: ${({ theme, color }) => color || theme.palette.grey_light};
  border-radius: ${({ borderRadius = 0 }) => borderRadius}px;
  ${({ borderColor }) => `border: 1px solid ${borderColor};`}
  display: flex;
  flex-direction: ${({ direction = 'row' }) => direction};
  justify-content: ${({ justify = 'flex-start' }) => justify};
  margin: ${({ margin }) => margin};
  padding: ${({ padding = '0px' }) => padding};
  ${({ shadow }) =>
    shadow &&
    css`filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))  drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));`};
  ${({ maxWidth }) => `max-width: ${maxWidth}`};


  @media ${devices.mobileS} and (max-width: 560px){
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-around;
    padding: ${({ padding = '0px', thankYouPage }) => thankYouPage ? '0.5rem' : padding};
    width: ${({ width, thankYouPage }) => thankYouPage ? '92%' : width};
  }
`

export default Panel
