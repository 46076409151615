import { devices } from 'src/utils'
import styled from 'styled-components'

export const MapWrapper = styled.div<{
    error?: boolean
}>`
    background-color: ${({ theme }) => theme.palette.borderInput};
    border-radius: .5rem;
    border: 1px solid ${({ error, theme }) => error ? theme.palette.errorBorder : theme.palette.lightGrey};
    padding: 1rem 1rem 2rem 1rem;
    width: 100%;

    & .g-map {
        margin-top: 5rem;
        position: relative;
        width: 100%;
    }

    @media ${devices.mobileS} and (max-width: 35rem){
        padding: 1rem .5rem;
    }
`
