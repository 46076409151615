import { createGlobalStyle } from 'styled-components'
import Theme from './Theme'
import { devices } from './utils'

const GlobalStyles = createGlobalStyle`
    h1,h2,h3,h4,h5,h6 {
        margin: 0;
        padding: 0;
    }
    * {
        box-sizing: border-box;
    }
    html,body {
        background: #F0F0F0;
        font-family: 'Monserrat';
        height: 100%;
        margin: 0;
        min-height: 100vh;
        overflow: auto;
        padding: 0;
    }

    #root {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: stretch;
        overflow: auto;
        position: relative;
    }

    .react-tel-input .flag-dropdown {
        z-index: 2;
    }
    .react-tel-input {
        margin: .5rem 0 0;
    }
    .react-tel-input input[type=text],
    .react-tel-input input[type=tel] {
            border-radius: .3rem!important;
            border: 2px solid rgba(0,0,0,0.25)!important;
            font-family: 'Monserrat';
            font-size: 17px!important;
            font-weight: 500;
            height: 2.5rem!important;
            width: 100%!important;
        }
    }

    .msgHeader {
        @media ${devices.mobileS} and (max-width: 760px){
            display: none!important;
        }
    }

    .backmobile {
        @media ${devices.laptop} {
            display:none;
        }
    }

    .backdesktop{
        @media ${devices.mobileS} and (max-width: 1024px){
            display:none
        }
    }

    .logo-sirvey{
        @media ${devices.mobileS} and (max-width: 560px){
            height:1.87rem;
        }
    }

    .logo-eng{
        @media ${devices.mobileS} and (max-width: 560px){
            height:1.56rem;
            margin-top:0.25rem;
        }
    }   
    
    .button-footer {
        @media ${devices.tablet} and (max-width: 1024px){
            align-items:center;
            display:flex;
            flex-direction:column;
        }
    }

    .ng-chat.support-chat-icon,
    .spartez-support-chat-container .top-header,
    .new-conversation,
    .chat-message.my,
    .send-message-button-main {
        background-color: ${Theme.palette.primary} !important;
    }

    @media ${devices.mobileS} and (max-width: 35rem) {
        .support-chat-icon {
            bottom: 6.6rem!important;
            z-index: 2!important;
        }
    }

    .MuiTooltip-arrow:before,
    .MuiTooltip-tooltip {
        background: ${Theme.palette.grey_tooltip}!important;
        @media ${devices.mobileS} and (max-width: 35rem) {
            width: 90%;
        }
    }

    input[type=text] {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .MuiTooltip-tooltip {
        color: ${Theme.palette.white}!important;
        font-family: ${Theme.font.primary} !important;
        font-size: 1rem!important;
        padding:1rem !important;
        font-weight: 400!important;
    }

    .ng-chat.support-chat-icon {
        background-image:url('/images/chat-icon.svg')!important;
        background-size: 60%;
        height: 4.375rem;
        width: 4.375rem;
    }
`

export default GlobalStyles
