import { devices } from 'src/utils'
import styled, { css } from 'styled-components'

const Img = styled.img<{
    height?: string,
    width?: string,
    isMobile?: boolean,
}>`
    height: ${({ height }) => height};
    width: ${({ width }) => width};

    ${({ isMobile }) => isMobile
    ? css`
        @media ${devices.mobileS} and (max-width: 35rem) {
            width:2rem;
        }
    `
    : ''}
`
export default Img
