import { devices } from 'src/utils'
import styled, { css } from 'styled-components'

interface InputStyleProps {
    backgroundColor?: string
    fontSize?: string
    inline?: boolean
    login?: boolean
    maxlength?: string
    withBorder?: boolean
}

interface WrapperProps {
    error?: boolean,
    inline?: boolean,
    maxWidth?: string,
    minHeight?:string,
}

export const InputStyle = styled.input<InputStyleProps>`
background-color: ${({ theme }) => theme.palette.white};
border-radius: .2rem;
border: 0;
border: 1px solid #DFE2E7;
box-shadow: none;
color: ${({ theme }) => theme.palette.black};
display: flex;
font-family: ${({ theme }) => theme.font.primary};
font-size: ${({ fontSize = '.87rem' }) => fontSize};
font-weight: 400;
line-height: 26px;
margin-left: ${({ inline }) => inline ? '15px' : '0'};
margin-top: ${({ inline }) => inline ? '0' : '10px'};
outline: none;
width: ${({ width }) => width || '100%'};

${({ withBorder, theme }) => withBorder && css`border: 1px solid ${theme.palette.lightGrey}`};

background-color: ${({ theme }) => theme.palette.darkGrey};
height: 2.25rem;
padding: .5rem .5rem;

&:disabled {
  background: ${({ theme }) => theme.palette.grey};
}
`
export const InputWarpper = styled.div<WrapperProps>`
    background-color: ${({ theme }) => theme.palette.borderInput};
    border-radius: .5rem;
    border: 1px solid ${({ error, theme }) => error ? theme.palette.errorBorder : theme.palette.lightGrey};
    display: flex;
    padding: 1rem;

    ${({ inline = false }) =>
    inline
        ? css`
            flex-direction: row;
            align-items: center;
        `
        : css`
            flex-direction: column;
            align-items: flex-start;
        `};
    width: 100%;
    ${({ minHeight }) => minHeight ? `min-height:${minHeight};` : ''}

    & label {
        min-width: 6rem;
    }

    @media ${devices.mobileS} and (max-width: 560px) {
        padding:1rem .5rem;
    }
`
