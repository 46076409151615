import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import GlobalStyles from 'src/GlobalStyles'
import Theme from 'src/Theme'
import { ThemeProvider } from 'styled-components'
import App from './App'
import AuthContextProvider from './context/Auth'
import ModalProvider from './context/ModalContext'
import reportWebVitals from './reportWebVitals'
import store from './state'

ReactDOM.render(
  <ThemeProvider theme={Theme}>
    <GlobalStyles/>
    <AuthContextProvider>
      <Provider store={store}>
        <ModalProvider>
        <App />
        </ModalProvider>
      </Provider>
    </AuthContextProvider>
  </ThemeProvider>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
