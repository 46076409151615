import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import Routes from './Routes'
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-WPN39H4',
}

TagManager.initialize(tagManagerArgs)

function App () {
  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  )
}

export default App
