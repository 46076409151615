import React from 'react'
import Button from 'src/components/atoms/Button'
import Typography from 'src/components/atoms/Typography'
import { Grid, GridItem } from 'src/shared/styles'
import { FooterContainer, LegendFooter } from './styles'
import packageJson from '../../../../package.json'
import { isMobile } from 'src/utils/config'

interface FooterButtonsProps {
  gotoNextStep: () => void,
  disabledBack?: boolean,
  disabled?: boolean,
  goBack?: () => void,
  label: string,
  currentStep: number
}

const FooterButtons: React.FC<FooterButtonsProps> = ({
  gotoNextStep,
  disabledBack,
  disabled,
  goBack,
  label,
  currentStep,
}: FooterButtonsProps) => {
  return (
    <FooterContainer currentStep={currentStep}>
      <Grid
        template='50% 50%'
        width='100%'
        className='button-footer'
        buttonFooter
      >
        <GridItem
          direction='row'
          justify={[3, 4].includes(currentStep) ? 'flex-end' : 'space-between'}
          align='center'
        >

          {[1, 2].includes(currentStep)
            ? <LegendFooter><Typography variant='label'>
              Only fields marked with an asterisk (*) are required
            </Typography></LegendFooter>
            : null
          }
          <Button
            align='flex-end'
            bold
            borderRadius="1rem"
            disabled={disabledBack}
            onClick={goBack}
            size='large'
            uppercase
            className='backdesktop'
          >
            Back
          </Button>
        </GridItem>
        <GridItem buttonFooter >
          <Button
            className='backmobile'
            align='flex-start'
            bold
            borderRadius="1rem"
            disabled={disabledBack}
            onClick={goBack}
            size='large'
            uppercase

          >
            Back
          </Button>
          <Button
            align='flex-start'
            bold
            borderRadius="1rem"
            disabled={disabled}
            onClick={gotoNextStep}
            size='large'
            uppercase
            data-test={`btn-${label.toLowerCase()}-${currentStep}`}
          >
            {label}
          </Button>
        </GridItem>
      </Grid>
      {isMobile && <Typography
        color='black'
        fontSize='.7rem'
        variant='versionLabel'
        margin="0"
      >
        v{packageJson.version}
      </Typography>}
    </FooterContainer>
  )
}

export default FooterButtons
