import React, { ChangeEvent, HTMLInputTypeAttribute } from 'react'
import Img from 'src/components/atoms/Image'
import Typography from 'src/components/atoms/Typography'
import { GroupError } from 'src/shared/styles'
import Theme from 'src/Theme'
import { ERRORLABEL, REACT_ENV } from 'src/utils/config'
import { InputStyle, InputWarpper } from './styles'

export interface Props {
  backgroundColor?:string
  disabled?: boolean
  error?: any
  fontSize?: string
  id?: string
  inline?: boolean
  label?: string
  maxlength?: number
  maxWidth?: string
  minHeight?:string
  name?: string
  onBlur?: () => void
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onKeyUp?: () => void
  pattern?: string
  placeholder?: string
  required?: boolean
  type?: HTMLInputTypeAttribute
  value?: any
  width?: string
  withBorder?: boolean
  sectionName?:string
}

const FieldInput: React.FC<Props> = ({
  name,
  id,
  label,
  placeholder = ' ',
  backgroundColor,
  sectionName,
  onKeyUp,
  ...rest
}: Props) => {
  return (
    <>
    <InputWarpper
      inline={rest.inline}
      maxWidth={rest.maxWidth}
      minHeight={rest.minHeight}
      error={rest.required && !rest.value}
    >
      <Typography
        htmlFor={name}
        fontSize={rest.fontSize}
        variant={'label'}
        color='primary'>
        {label}
      </Typography>
      <InputStyle
        value={rest.value}
        name={name}
        id={id}
        type={rest.type}
        placeholder={placeholder}
        withBorder={!!rest.withBorder}
        onChange={rest.onChange}
        onBlur={rest.onBlur}
        fontSize={rest.fontSize}
        inline={rest.inline}
        disabled={rest.disabled}
        pattern={rest.pattern}
        maxLength={rest.maxlength}
        onKeyUp={(evt) => {
          if (evt.key !== 'Enter') return
          onKeyUp && onKeyUp()
        }}
        backgroundColor={backgroundColor}
        data-test={sectionName}
      />
    </InputWarpper>
      {rest.required && !rest.value &&
      <GroupError>
        <Img
          alt="icon-error"
          src={`${REACT_ENV.PUBLIC_URL}/images/icon-error.png`}
          width='1.25rem'
        />
        <Typography variant='span' color={Theme.palette.errorBorder} bold>
          {ERRORLABEL}
        </Typography>
        {rest.error && <span>{rest.error}</span>}
      </GroupError>
      }
    </>
  )
}

export default FieldInput
