import React, { useEffect, useState } from 'react'
import { CardItemProps, CardContainerProps } from './types'
import { CardContainer, CardImg, CardInput, CardTitle, TitleContainer } from './styles'
import { OTHERSLABEL } from 'src/utils/config'

const CardItem: React.FC<CardItemProps & CardContainerProps> = ({
  description,
  image,
  title,
  isSelected,
  onClick,
  width,
  onChangeOther,
  placeholder,
  disabled,
  setDisabled = () => {},
  other = '',
  required,
  id,
}: CardItemProps & CardContainerProps) => {
  const [value, setValue] = useState<string>('')
  const [inputDisabled, setInputDisabled] = useState<boolean>(true)
  function onChangeInput (evt: React.ChangeEvent<HTMLInputElement>) {
    setValue(evt.target.value)
  }

  function handleClick (event: any) {
    onClick && onClick()
    if (title === OTHERSLABEL.toUpperCase()) {
      setDisabled(!disabled)
      setInputDisabled(!inputDisabled)
      !inputDisabled && setValue('')
      if (!disabled) {
        setValue('')
        onChangeOther && onChangeOther('')
      }
    }
  }

  function handleBlur () {
    onChangeOther && onChangeOther(value)
  }

  useEffect(() => {
    other && setValue(other)
    other && setInputDisabled(false)
  }, [other])
  return (
    <CardContainer width={width} isSelected={isSelected} data-test={id}>
      <div onClick={handleClick}>
        <CardImg src={image} />
        <TitleContainer>
          <CardTitle variant='h4' className="card-item-title">
            {title}
          </CardTitle>
        </TitleContainer>
        {title !== OTHERSLABEL.toUpperCase() && <p className="card-item-description" dangerouslySetInnerHTML={{ __html: description }}></p>}
      </div>
      {title === OTHERSLABEL.toUpperCase() &&
      <CardInput
        type='text'
        name='inputValue'
        placeholder= {placeholder}
        value={value}
        maxLenght={70}
        onInput={onChangeInput}
        onBlur={handleBlur}
        disabled={inputDisabled}
        required={required}
      />}
      </CardContainer>
  )
}

export default CardItem
