import React, { ChangeEvent, useEffect } from 'react'
import styled, { css } from 'styled-components'
import Typography from 'src/components/atoms/Typography'
import Tooltip from '@mui/material/Tooltip'
import { ERRORLABEL, isMobile, REACT_ENV } from 'src/utils/config'
import { devices } from 'src/utils'
import { ClickAwayListener, IconButton } from '@mui/material'
import { GroupError, ImgInformation } from 'src/shared/styles'
import Img from './Image'
import Theme from 'src/Theme'

interface TextAreaStyleProps {
  width?: string
  height?: string
  withBorder?: boolean
  fontSize?: string
  error?: boolean,
}

interface WrapperProps {
  error?: boolean,
  maxWidth?: string,
  useFrame?: boolean,
}

export const TextAreaStyle = styled.textarea<TextAreaStyleProps>`
  display: flex;
  outline: none;
  appearance: none;
  -moz-appearance: none;
  --webkit-appearance: none;

  width: ${({ width }) => width || '90%'};
  min-height: ${({ height }) => height};
  line-height: 1.6rem;

  border: 0;
  border-radius: 2.5px;

  background-color: ${({ theme }) => theme.palette.white};
  color: ${({ theme }) => theme.palette.black};
  font-family: ${({ theme }) => theme.font.primary};
  font-size: ${({ fontSize = '.8rem' }) => fontSize};
  font-weight: 400;
  padding: .6rem .8rem;
  margin: .6rem auto 0;
  overflow-y: hidden;
  resize: vertical;

  ${({ withBorder, theme }) =>
  withBorder && css`border: 1px solid ${theme.palette.lightGrey}`};

  &:disabled {
    background: ${({ theme }) => theme.palette.grey};
  }

  @media ${devices.mobileS} and (max-width: 35rem) {
    font-size: .8rem;
    line-height: 1rem;
  }
`
const TextAreaHeader = styled.div`
  align-items:center;
  display:flex;
  width:100%;

  & button {
    margin: 0rem 1rem;
  }

  @media ${devices.mobileS} and (max-width: 760px){
    justify-content: space-between;
  }
`
const InputWarpper = styled.div<WrapperProps>`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  ${({ useFrame }) =>
  useFrame && css`
    border-radius: .5rem;
    background-color: ${({ theme }) => theme.palette.borderInput};
    border: 1px solid ${({ theme }) => theme.palette.lightGrey};
    padding: 1rem;
  `}
  width: 100%;

  ${({ error, theme }) =>
  error && css`border: 1px solid ${theme.palette.errorBorder}`};

  @media ${devices.mobileS} and (max-width: 560px){
    padding: 1rem .5rem;
  } 
`

export interface TextAreaProps {
  disabled?: boolean,
  fontSize?: string
  height?: string
  id?: string
  label?: string
  maxlength?:number
  maxWidth?: string,
  name?: string
  onBlur?: () => void
  onInput?: (event: ChangeEvent<HTMLTextAreaElement>) => void
  onClickButton?: () => void
  placeholder?: string
  required?: boolean
  useFrame?: boolean,
  value?: any
  width?: string
  withBorder?: boolean
  includeNote?:boolean
}

const TextArea: React.FC<TextAreaProps> = ({
  name,
  id,
  label,
  onClickButton,
  useFrame = true,
  ...rest
}: TextAreaProps) => {
  const [open, setOpen] = React.useState(false)
  const refElement = React.useRef<HTMLTextAreaElement>(null)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipToggle = () => {
    setOpen(!open)
  }

  const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    resizeTextArea()
    if (rest.onInput) rest.onInput(evt)
  }

  const resizeTextArea = () => {
    if (refElement?.current) {
      const textAreaElement = (refElement.current as HTMLTextAreaElement)
      textAreaElement.style.height = !textAreaElement.style.height || parseInt(textAreaElement.style.height) < textAreaElement.scrollHeight
        ? `${textAreaElement.scrollHeight}px`
        : textAreaElement.style.height
    }
  }

  useEffect(() => {
    resizeTextArea()
  }, [])

  const tooltipProps = isMobile
    ? {
        open: open,
        onClose: handleTooltipClose,
        disableFocusListener: true,
        disableHoverListener: true,
        disableTouchListener: true,
      }
    : {
        leaveDelay: 800,
      }

  return (
    <>
      <InputWarpper
        error={rest.required && !rest.value}
        maxWidth={rest.maxWidth}
        useFrame={useFrame}
      >
        <TextAreaHeader>
          <Typography htmlFor={name} fontSize={rest.fontSize} variant='label'>
            {label}
          </Typography>
          {!onClickButton
            ? <ClickAwayListener onClickAway={handleTooltipClose}>
              <Tooltip
                arrow
                {...tooltipProps}
                title={rest.placeholder}
                >
                <ImgInformation
                  src={`${REACT_ENV.PUBLIC_URL}/images/information.png`}
                  onClick={() => handleTooltipToggle()}
                  margin="0 0 0 0.5rem"
                />
              </Tooltip>
            </ClickAwayListener>
            : <IconButton onClick={() => onClickButton()}>
              <ImgInformation
                src={`${REACT_ENV.PUBLIC_URL}/images/information.png`}
                data-test={`info-${id}`}
              />
            </IconButton>
        }
        </TextAreaHeader>
        <TextAreaStyle
          value={rest.value}
          name={name}
          id={id}
          placeholder={rest.includeNote ? '' : rest.placeholder}
          withBorder={true}
          fontSize={rest.fontSize}
          width='100%'
          height={rest.height ?? '6rem'}
          onInput={handleChange}
          onBlur={rest.onBlur}
          disabled={rest.disabled}
          maxLength={rest.maxlength}
          data-test={id}
          ref={refElement}
        />
        {rest.includeNote &&
        <Typography fontSize='0.75rem' variant='label' margin="0.5rem 0" color="lightGrey">
          Note: <Typography fontSize='0.75rem' variant='label' color="grey_light2">
            {rest.placeholder}
          </Typography>
      </Typography>}
      </InputWarpper>
      {rest.required && !rest.value &&
      <GroupError>
        <Img
          alt="icon-error"
          src={`${REACT_ENV.PUBLIC_URL}/images/icon-error.png`}
          width='1.25rem'
        />
        <Typography variant='span' color={Theme.palette.errorBorder} bold>
          {ERRORLABEL}
        </Typography>
      </GroupError>
      }
    </>
  )
}

export default TextArea
