import React from 'react'
import Img from 'src/components/atoms/Image'
import styled from 'styled-components'
import { HeaderProps } from './types'
import { devices } from 'src/utils'
import Typography from 'src/components/atoms/Typography'
import { REACT_ENV } from 'src/utils/config'

const StyledHeader = styled.header<HeaderProps>`
  align-items: center;
  align-self: ${({ align = 'flex-start' }) => align};
  background-color: ${({ theme }) => theme.palette.white};
  display: flex;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  height: 3.12rem;
  position:fixed; 
  top:0;
  z-index: 3;

  width: 100%;

  & .btn-small {
    height: 1.6rem;
  }

  @media ${devices.laptop} {
    justify-content: space-between!important;
    padding: 0.5rem 10rem;
  }

  @media ${devices.mobileS} {
    justify-content: space-around;
  }
`

const Header: React.FC<{
  onHeader?: string
}> = ({
  onHeader,
}) => {
  return (
      <StyledHeader>
        <div className='logo-sirvey'>
          <Img src={`${REACT_ENV.PUBLIC_URL}/images/logo_sirvey_horizontal.png`} />
        </div>
        <Typography className='msgHeader'
          variant='label'
          fontSize="1.25rem"
          align="left"
          color='primary'>
          {onHeader}
        </Typography>
        <div style={{ display: 'flex' }} className='logo-eng' >
          <Img src={`${REACT_ENV.PUBLIC_URL}/images/logo_eng.png`} />
        </div>
      </StyledHeader>
  )
}

export default Header
