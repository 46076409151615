import React, { useEffect, useState } from 'react'
import { InputStyle } from 'src/components/atoms/Input'
import { SearchParams } from './types'
import styled from 'styled-components'
import PA from 'react-places-autocomplete'
import Typography from 'src/components/atoms/Typography'
import Theme from 'src/Theme'
import { REACT_ENV } from 'src/utils/config'
import { Tooltip } from '@mui/material'

const InputIcon = styled.div`
  cursor: pointer;
  position: absolute;
  width: 100%;
  z-index: 1;
`

const SearchWrapper = styled.div`
  display: flex;
  margin: 0 0 10px 0;
  width: 100%;

  & input {
    border-radius: 0.5rem;
    border: 1px solid ${({ theme }) => theme.palette.lightGrey};
    color: #000;
    font-family: ${Theme.font.primary};
    font-size: 1rem;
    font-weight: 400;
    padding: 1.5rem 2rem 1.5rem 3rem;
  }

  & .search-icon {
    height: 2.5rem;
    left: .5rem;
    position: absolute;
    top: 1rem;
    width: 2.5rem;
    z-index: 1;
  }
`

const SuggestionWrapper = styled.div`
  background-color: white;
`

const SuggestionStyle = styled.div`
  align-items: center;
  background-color: white;
  border-bottom: 1px black dotted;
  cursor: pointer;
  display: flex;
  font-family: ${Theme.font.primary};
  height: auto;
  padding: 0.4rem;

  & span {
    font-family: 'Monserrat'!important;
  }
`

const CancelIcon = styled.div`
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 1.5rem;
  z-index: 1;
`

const PlacesAutocomplete = styled(PA)`
  position: relative;
`

const Search: React.FC<SearchParams> = ({
  location,
  placeholder,
  onKeyUp,
  onChange,
  onSelect,
}: SearchParams) => {
  const [value, setValue] = useState<string>(location ?? '')

  useEffect(() => {
    location && setValue(location)
    location && onChange && onChange(location)
  }, [location])

  return (
    <SearchWrapper>
      <InputIcon>
        <img
          src={`${REACT_ENV.PUBLIC_URL}/images/search-input-icon.png`}
          className='search-icon'
          style={{ margin: '.1rem 0 0', width: '2.5rem', height: '2.5rem' }}
          alt="search-input-icon"
        />
        <PlacesAutocomplete value={value} onChange={setValue} onSelect={onSelect}>
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => <div>
            <InputStyle
              {...getInputProps()}
              onKeyUp={(evt) => {
                if (evt.key !== 'Enter') return
                onKeyUp && onKeyUp(evt.currentTarget.value)
              }}
              placeholder={placeholder}
              data-test="map-location"
            />
            <SuggestionWrapper>
              {loading ? <div>loading....</div> : null}
              {suggestions.map((suggestion, index) => {
                const style = {
                  backgroundColor: suggestion.active ? '#EFEFEF' : Theme.palette.white,
                }
                return <SuggestionStyle
                        {...getSuggestionItemProps(suggestion, { style })}
                        key={suggestion.id + '_' + index}>
                          <img
                            src={`${REACT_ENV.PUBLIC_URL}/images/location.svg`}
                            alt='location img'
                            style={{ marginRight: '5px', width: '1.56rem', height: '1.56rem' }}
                          />
                          <Typography
                            variant='label'
                            fontSize='.8rem'
                            color='black'
                          >
                            {suggestion.description}
                          </Typography>
                      </SuggestionStyle>
              })}
            </SuggestionWrapper>
            {value && <Tooltip title={value} placement="top-start" arrow>
                <CancelIcon onClick={() => setValue('')}>
                <img
                  src={`${REACT_ENV.PUBLIC_URL}/images/cancel.svg`}
                  alt='cancel img'
                  style={{ width: '1.56rem', height: '1.56rem' }}
                />
              </CancelIcon>
              </Tooltip>
            }
          </div>}
        </PlacesAutocomplete>
      </InputIcon>
    </SearchWrapper>
  )
}

export default Search
