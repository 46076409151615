export default {
  font: {
    primary: 'Montserrat',
  },
  palette: {
    borderInput: '#F8F8F8',
    placeholderInput: '#767474',
    white: '#FFF',
    lightGrey: '#BEBEBE',
    darkGrey: '#F7F9FB',
    grey: '#E7E8E7',
    grey_light: '#F0F0F0',
    grey_light2: '#8B8B8B',
    grey_medium: '#8B94A3',
    grey_dark: '#CACACA',
    grey_tooltip: '#D1D1D1',
    grey_upload: '#A8A8A8',
    gray_ligth3: '#E4E5E7',
    dragOver: '#999',
    primary: '#8736D6',
    primary_light: '#EFE5FA',
    primaryDisabled: '#8736D666',
    primaryHover: '#954cd4',
    shadow: '#000',
    primaryTextColor: '#fff',
    black: '#222',
    black_disabled: '#00000080',
    textSecondary: '#666',
    modalBackground: '#C4BBCC',
    dateInRange: '#F2E7FE',
    amPmButtonBorder: '#DADCE0',
    calendar: '#0F2552',
    calendarDay: '#7E818C',
    close: '#B5B9C0',
    version: '#727A88',
    error: '#C62828',
    errorBorder: '#FF0000',
  },
}
