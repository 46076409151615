import { Locale } from 'date-fns'

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
const days = ['S', 'M', 'T', 'W', 'T', 'F', 'S']
const en: Locale = {
  localize: {
    month: (m:number) => months[m],
    day: (d:number) => days[d],
    dayPeriod: () => '',
    era: () => '',
    ordinalNumber: () => '',
    quarter: () => '',
  },
  formatLong: {
    dateTime: () => '',
    date: (...arg:any) => 'EEE',
    time: () => '',
  },
  options: {
    weekStartsOn: 0,
  },
}

export default en
