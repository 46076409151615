export const INIT_RFP_ACTION = 'INIT_RFP_ACTION'
export const INIT_RFP_ACTION_COMPLETED = 'INIT_RFP_ACTION_COMPLETED'
export const INIT_RFP_ACTION_FAILED = 'INIT_RFP_ACTION_FAILED'

export const SET_EMAIL = 'SET_EMAIL'

export const UPDATE_RFP_ACTION = 'UPDATE_RFP_ACTION'
export const UPDATE_RFP_ACTION_COMPLETED = 'UPDATE_RFP_ACTION_COMPLETED'
export const UPDATE_RFP_ACTION_FAILED = 'UPDATE_RFP_ACTION_FAILED'

export const DONWLOAD_RFP_ACTION = 'DONWLOAD_RFP_ACTION'

export const SET_BASIC_DATA_ACTION = 'SET_BASIC_DATA_ACTION'

export const NEXT_STEP_ACTION = 'NEXT_STEP_ACTION'

export const SET_SCOPE_OF_WORK_ACTION = 'SET_SCOPE_OF_WORK_ACTION'

export const SWAP_PRIORITIES_ACTION = 'SWAP_PRIORITIES_ACTION'

export const SET_AREAS_TO_BE_SCANNED_ACTION = 'SET_AREAS_TO_BE_SCANNED_ACTION'

export const SET_SCOPE_ACTION = 'SET_SCOPE_ACTION'

export const SET_FACILITY_TYPE_ACTION = 'SET_FACILITY_TYPE_ACTION'

export const SET_SCAN_ZONE_ACTION = 'SET_SCAN_ZONE_ACTION'

export const SET_LEVEL_ACTION = 'SET_LEVEL_ACTION'
export const SET_LEVEL_INFO_ACTION = 'SET_LEVEL_INFO_ACTION'
export const SET_CEILING_TYPE_ACTION = 'SET_CEILING_TYPE_ACTION'
export const SET_TILES_REMOVED_ACTION = 'SET_TILES_REMOVED_ACTION'
export const SET_SPECIAL_REQUIREMENT_ACTION = 'SET_SPECIAL_REQUIREMENT_ACTION'

export const SET_ACCESSIBILITY_ACTION = 'SET_ACCESSIBILITY_ACTION'

export const SET_ROOF_ACCESIBLE_ACTION = 'SET_ROOF_ACCESIBLE_ACTION'
export const SET_HOW_ACCESS_ACTION = 'SET_HOW_ACCESS_ACTION'
export const SET_IS_TERRACE_ACTION = 'SET_IS_TERRACE_ACTION'
export const SET_CAN_DRONE_FLY_ACTION = 'SET_CAN_DRONE_FLY_ACTION'
export const SET_SQTF_ACTION = 'SET_SQTF_ACTION'

export const SET_LINEAR_FEET_ACTION = 'SET_LINEAR_FEET_ACTION'
export const SET_FACADE_HEIGHT_ACTION = 'SET_FACADE_HEIGHT_ACTION'
export const SET_PICTURE_ACTION = 'SET_PICTURE_ACTION'

export const RESET_STORE = 'RESET_STORE'

export const SET_SCANNING_SERVICE_ACTION = 'SET_SCANNING_SERVICE_ACTION'
export const SET_FINAL_STEP_ACTION = 'SET_FINAL_STEP_ACTION'

export const SET_SITE_EXTERIOR_ACTION = 'SET_SITE_EXTERIOR_ACTION'

export const SET_STEPS_COMPLETED_ACTION = 'SET_STEPS_COMPLETED_ACTION'

export const SET_CURRENT_STEP_ACTION = 'SET_CURRENT_STEP_ACTION'

export const FINISH_QUOTE = 'FINISH_QUOTE'
export const EMPTY_STATE = 'EMPTY_STATE'
