import styled, { css } from 'styled-components'
import { devices } from 'src/utils'
import Theme from 'src/Theme'
import LinkMT from '@mui/material/Link'

export const Grid = styled.div<{
    align?: string,
    buttonFooter?: boolean
    gap?: string,
    justify?: string,
    template?: string,
    templateMobile?: string
    width?: string,
}>`
    ${({ justify }) => justify ? `justify-content: ${justify};` : ''}
    ${({ width }) => width ? `width: ${width};` : ''}
    align-items: ${({ align }) => align ?? 'top'};
    display: grid;
    gap: ${({ gap }) => gap ?? '1rem'};
    grid-template-columns: ${({ template }) => template ?? '50% 50%'};
    margin: ${({ align }) => align === 'center' ? '0' : '1rem 0'};
    
    @media ${devices.mobileS} and (max-width: 560px) {
        grid-template-columns: 100%;
        justify-items: ${({ buttonFooter }) => buttonFooter && 'center'};
        margin: ${({ buttonFooter }) => buttonFooter && '0.31rem auto'};
    };

    ${({ templateMobile }) => templateMobile
        ? css`@media ${devices.tablet} and (max-width: 1024px) {
         grid-template-columns: ${templateMobile}
    }`
        : ''};

      
}
`
export const GridItem = styled.div<{
    align?: string,
    direction?: string,
    justify?: string,
    buttonFooter?: boolean
}>`
    ${({ align }) => `align-items: ${align}; `}
    ${({ justify }) => justify ? `justify-content: ${justify};` : ''}
    display: flex;
    flex-direction: ${({ direction }) => direction ?? 'column'};
    gap: 1rem;

    @media ${devices.mobileS} and (max-width: 1024px) {
        flex-direction: ${({ buttonFooter }) => buttonFooter && 'row'};
        justify-content:space-between;
    };
`

export const Wrapper = styled.div`
  background-color: ${Theme.palette.borderInput};
  border-radius: .5rem;
  border: 1px solid ${Theme.palette.lightGrey};
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const FavButtons = styled.div<any>`
    align-self: center;
    display: flex;
    justify-content: space-between;
    margin-top:5px;
    
    @media ${devices.mobileS} and (max-width: 560px) {
        width:100%;
        &button {
            width: 5rem!important;
        }
    }
`
export const TitleMobile = styled.h2`
    align-self: center;
    color: ${({ theme }) => theme.palette.primary};
    font-family: ${({ theme }) => theme.font.primary};
    font-size: 1.375rem;
    font-weight: 700;
    letter-spacing: 0.1px;
    text-align:center;

    @media ${devices.mobileS} and (max-width: 760px) {
        top: 7rem;
    }

    @media ${devices.tablet} {
        display: none;
    }
`
export const TextMobile = styled.h4`
    align-self: center;
    color: ${({ theme }) => theme.palette.grey_light2};
    font-family: ${({ theme }) => theme.font.primary};
    font-size: 0.8125rem;
    font-weight: 500;
    text-align:center;

    @media ${devices.tablet} {
        display:none;
    }
`

export const ContainerMobile = styled.div`
    width: 100%;
    @media ${devices.mobileS} and (max-width: 35rem;) {
        position: fixed;
    }
`
export const BgMobile = styled.div`
@media ${devices.mobileS} and (max-width: 560px) {
    &::before,
    &::after {
        background-color: ${({ theme }) => theme.palette.white};
        content:"";
        height: 6rem;
        position: absolute;
        width: 51%;
        z-index: 1;
    }
    
    &:before {
        -moz-border-radius-topleft: 30px;
        -webkit-border-top-left-radius: 30px;
        border-top-left-radius: 30px;
        bottom: 0;
        left: 0;
    }
    
    &:after {
        -moz-border-radius-topright: 30px;
        -webkit-border-top-right-radius: 30px;
        border-top-right-radius: 30px;
        bottom: 0px;
        right: 0;
    }
}
`

export const Spinner = styled.div<{
    size?: number,
    color?: string
}>`
    border-left-color: ${Theme.palette.white}!important;
    border-radius: 50%;
    border: 0.25rem solid ${Theme.palette.primary};
    height: 2rem;
    width: 2rem;

    animation: spin 1s ease infinite;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`
export const SideBar = styled.div`
    align-content:center;
    align-items:center;
    background-color: ${Theme.palette.primary};
    bottom: 0;
    color: ${Theme.palette.white};
    display:flex;
    flex-direction:column;
    height: 100vh;
    justify-content: flex-end;
    overflow: hidden;
    position: absolute;
    width: 7.5rem;
    
    a {
        color: ${Theme.palette.white};
        cursor: pointer;
        text-align:center;
        text-decoration:none;
    }

    :root .safari_only {
        position: fixed;
        bottom: 2rem;
    }

    @media ${devices.mobileS} and (max-width: 64rem) {
        display: none
    }
`

export const ImgInformation = styled.img<{ margin?:string }>`
     cursor: pointer;
     height: 1.125rem;
     width: 1.125rem;
     ${({ margin }) => margin ? `margin: ${margin}` : ''};
`
export const GroupError = styled.div`
    align-items: center;
    display: flex;
    gap: .5rem;
`

export const Link = styled(LinkMT)`
    color: ${({ theme }) => theme.palette.primary} !important;
    font-family: ${Theme.font.primary};
    font-size: 0.75rem;
    font-weight: 600;

    &:active, :link {
        text-decoration: none;
    }
`
