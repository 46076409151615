import React from 'react'
import { ModalFooter } from './style'

interface IFooter {
    children: React.ReactNode,
}
export default function Footer ({
  children,
}: IFooter) {
  return (
    <ModalFooter>
        {children}
    </ModalFooter>
  )
};
