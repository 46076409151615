import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { MultipleSelectProps } from './types'
import Theme from 'src/Theme'
import { OTHERLABEL } from 'src/utils/config'
import { devices } from 'src/utils'

const SelectWrapper = styled.section<any>`
  display: flex;
  flex-direction: ${props => props.direction};
  flex-wrap: wrap;
  width: 100%;
`
const SelectItem = styled.div<any>`
  align-items: left;
  background-color: ${Theme.palette.borderInput};
  border-top: .1rem solid ${Theme.palette.lightGrey};
  display: flex;
  flex-direction: row;
  font-size: 25px;
  position: relative;
  width: 100%;
  padding: 0 0 0 1rem;
  
  @media ${devices.mobileS} and (max-width: 1024px) {
    padding: 0 0 0 .5rem;
    flex-direction:column;
  };
`

const SelectCheckbox = styled.div<any>`
  ${props => (props.key === OTHERLABEL ? 'width: 100%;' : '')}
  align-items: center;
  background-color: ${Theme.palette.borderInput};
  border-radius: 4px;
  display: flex;
  font-family: ${Theme.font.primary};
  font-size: .87rem;
  height: 2.2rem;
  height: 3rem;
  padding-left: 2rem;
  pointer-events: ${props => (props.pointEvent ? 'auto' : 'none')};
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;

    width: 1.25rem;
    height: 1.25rem;

    background: ${({ theme }) => theme.palette.white};
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.palette.lightGrey};
    cursor: pointer;
    left: 0;
    pointer-events: all;
  }

  &.selected::after {
    content: '';
    display: block;
    left: 0;
    position: absolute;
    text-align: center;

    width: 1.25rem;
    height: 1.25rem;

    background-color: ${({ theme }) => theme.palette.primary};
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1.25rem 18px;
    border-radius: 4px;
    border: 1px solid transparent;
  }
  @media ${devices.mobileS} and (max-width: 1024px) {
    height: 2.5rem;
  };
`

const SelectInput = styled.input<any>`
  align-self: center;
  
  background-color: ${Theme.palette.white};
  border-radius: .2rem;
  border: 1px solid ${({ error, theme }) => error ? theme.palette.errorBorder : theme.palette.lightGrey};
  
  font-family: ${({ theme }) => theme.font.primary};
  font-size: .87rem;
  line-height: 1rem;
  
  height: 1.25rem;

  @media (min-width: 1200px) {
    max-width: 39rem;
  }

  @media (max-width: 1200px) {
    max-width: 22rem;
  }

  min-height: 1.8rem;
  width: 100%;
  
  margin: 0 1rem 0 1rem;
  padding: 0 1rem;
  
  @media ${devices.mobileS} and (max-width: 1024px) {
      font-size: 0.75rem;
      margin: 0 0 0 .52rem;
      padding: 0 0.15rem;
      width:82%;
  };

  &:disabled {
    background: ${Theme.palette.grey};
    @media ${devices.mobileS} and (max-width: 35rem) {
      &::placeholder {
        color: ${({ theme }) => theme.palette.placeholderInput};
        font-weight: 600;
      }
    }
  }
`

const MultipleSelect: React.FC<MultipleSelectProps> = ({
  direction = 'column',
  disabled = true,
  isCeiling,
  onChange,
  onChangeOther,
  options,
  other,
  placeholder = '',
  required,
  sectionName,
  setDisabled = () => {},
  values,
}: MultipleSelectProps) => {
  const [value, setValue] = useState<string>('')
  function onChangeInput (evt: React.ChangeEvent<HTMLInputElement>) {
    setValue(evt.target.value)
    onChangeOther && onChangeOther(evt.target.value)
  }

  function handleClick (item: any) {
    onChange && onChange(item, 'item')
    if (item.value === OTHERLABEL) {
      setDisabled(!disabled)
      if (!disabled) {
        setValue('')
        onChangeOther && onChangeOther('')
      }
    }
  }

  function handleBlur () {
    onChangeOther && onChangeOther(value)
  }

  function handleSelected (values: any, item: any) {
    const itemFindIt = values.filter((val: any) => val.id === item.id)
    return itemFindIt.length ? 'selected' : ''
  }

  useEffect(() => {
    other && setDisabled(false)
    other && setValue(other)
    values.some((item: any) => item.value === OTHERLABEL) && setDisabled(false)
  }, [other, values])

  return (
    <SelectWrapper>
      {options.map((item) => (
        <SelectItem key={item.id} direction={direction} ceilingStyle={isCeiling}>
          <SelectCheckbox
            className={handleSelected(values, item)}
            key={item.value}
            onClick={() => handleClick(item)}
            pointEvent={item.value === OTHERLABEL}
            isCeiling={isCeiling}
            data-test={`${sectionName}-${item.id}`}
            >
            {item.value}
          </SelectCheckbox>
          {item.value === OTHERLABEL &&
            <SelectInput
              type='text'
              name='inputValue'
              placeholder={placeholder}
              value={value}
              onInput={onChangeInput}
              onBlur={handleBlur}
              direction={direction}
              disabled={disabled}
              isCeiling={isCeiling}
              error={required && !value && !disabled}
            />
          }
        </SelectItem>
      ))}
    </SelectWrapper>
  )
}

export default MultipleSelect
