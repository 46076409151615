import Theme from 'src/Theme'
import styled, { css } from 'styled-components'

interface ButtonProps {
    align?: 'flex-end' | 'flex-start' | 'center'
    bold?: boolean
    borderRadius?: string
    margin?: string
    padding?: string
    size?: 'small' | 'large' | string
    spinner?: boolean
    uppercase?: boolean
    width?: string
    minheight?:string
}

const Button = styled.button<ButtonProps>`
    ${({ spinner }) => spinner && css`
        align-items: center;
        display: flex;
        justify-content:center;
    `}
    appearance: none;
    background-color: ${({ theme }) => theme.palette.primary};
    border: 0;
    box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
    -webkit-box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
    color: ${({ theme }) => theme.palette.primaryTextColor};
    cursor: pointer;
    font-family: ${Theme.font.primary};
    font-weight: ${({ size = 'small', bold }) => size === 'small' && !bold ? '400' : bold ? '700' : '500'};
    min-height: ${({ size = 'small', minheight }) => size === 'small' && !minheight ? '2.18rem' : minheight ?? '3.12rem'};
    width: ${({ width = 'auto' }) => width};
    min-width: ${({ width }) => width ?? '10rem'};
    outline: none;
    text-transform: ${({ uppercase }) => uppercase ? 'uppercase' : 'capitalize'};

    ${({ align }) => align && css` align-self: ${align}; `}
    ${({ borderRadius = '0px' }) => `border-radius: ${borderRadius};`}
    ${({ margin }) => `margin: ${margin};`}
    ${({ padding }) => `padding: ${padding};`}
    ${({ size = 'small' }) => size === 'small' ? 'font-size: .87rem' : (size === 'large' ? 'font-size: 1.3rem' : `font-size: ${size}`)};

    &:disabled {
        background-color: ${({ theme }) => theme.palette.primaryDisabled};
        cursor: not-allowed;
    }

    &:not(:disabled):hover {
        background-color: ${({ theme }) => theme.palette.primaryHover};
    }
`

export default Button
