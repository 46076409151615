import { devices } from 'src/utils'
import styled from 'styled-components'

export const Wrapper = styled.section`
    align-items: center;
    background: ${({ theme }) => theme.palette.white};
    border-radius: 4px;
    border: 1px solid rgba(190, 190, 190, 0.53);
    display: flex;
    height: 3.12rem;
    justify-content: space-between;
    padding: 0 .5rem;
    position: relative;
    width: 95%;
    
    @media ${devices.mobileS} and (max-width: 560px){
        max-height: 70%;
        overflow-y: scroll;
        padding: 5px;
        scroll-behavior: smooth;
    }

    & .text-thin {
        font-weight: 400;

        @media ${devices.laptop} and (max-width: 1367px) {
            font-size: 1rem;
        }

        @media ${devices.tablet} and (max-wdith: 1023px;) {
            font-size: .5rem!important;
        }
    }

    & .text-to {
        @media ${devices.tablet} and (max-wdith: 1023px;) {
            font-size: .5rem!important;
        }
    }
`

export const DateButton = styled.button`
    -moz-box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
    -webkit-box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
    background: ${({ theme }) => theme.palette.primary};
    border-radius: .5rem;
    border: 0;
    box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
    color: ${({ theme }) => theme.palette.white};
    cursor: pointer;
    display: flex;
    font-family: ${({ theme }) => theme.font.primary};
    font-size: 90%;
    font-weight: 700;
    justify-content: center;
    letter-spacing: 1px;
    line-height: 1.2rem;
    padding: .5rem 0;
    width: 100%;

    @media ${devices.mobileS} and (max-width: 1024px){
        width:95%
    }

`

export const DatePickerModal = styled.div`
    align-items: center;
    background-color: rgba(0,0,0,.25);
    bottom:0;
    display: flex;
    height: 100vh;
    justify-content: center;
    left:0;  
    position:fixed;
    right:0;
    top:0;
    width: 100%;
    z-index: 3;

    @media ${devices.mobileS} and (max-width: 35rem){
        height: 100vh;
        top:4.43rem;
        z-index: 5;
    }
`

export const DatePickerContent = styled.div`
    display: flex;
    flex-direction: column;
    pointer-events: all;
    width: 40rem;
    z-index: 1;

    @media ${devices.mobileS} and (max-width: 35rem){ 
        height:100%;
        max-width: 95%;
        margin-bottom:1.62rem;
    }
`

export const DatePickerHeader = styled.div`
    -moz-border-radius-topleft: .5rem;
    -moz-border-radius-topright: .5rem;
    -webkit-border-top-left-radius: .5rem;
    -webkit-border-top-right-radius: .5rem;
    background-color: ${({ theme }) => theme.palette.white};
    display: flex;
    flex-direction: column;
    padding: 6px;
    width: 100%;

    & .to-separator {
        font-weight: 400;
        margin-bottom: .62rem;
        min-width: .62rem;
    }
    @media ${devices.mobileS} and (max-width: 35rem) {
        align-items: center;
    }
`

export const DatePickerRange = styled.div`
    background-color: ${({ theme }) => theme.palette.white};
    display: flex;
    width: 100%;

    @media ${devices.mobileS} and (max-width: 35rem){
            display:none
    }

    & .to-separator {
        font-weight: 400;
        margin-bottom: .62rem;
        min-width: .62rem;
    }
`

export const DatePickerBody = styled.div`
    align-items: center;
    background-color: ${({ theme }) => theme.palette.white};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 6px;
    width: 100%;

    @media ${devices.mobileS} and (max-width: 35rem){
        display:inline;
        height: 28rem;
        overflow-y: scroll;
        padding: 0 0.37rem;
        scroll-behavior: smooth;
        padding-top:0.62rem;
    }

    & .react-datepicker {
        border: 0;
        
        @media ${devices.mobileS} and (max-width: 35rem){
            flex-direction:column;
        }

        &__navigation {
            top: .5rem;
        }

        &__header {
            background-color: transparent;
            border-bottom-color: transparent;
            padding-bottom: 0;
            padding-top: 1rem;
            
            @media ${devices.mobileS} and (max-width: 35rem){
                padding-top: 0;
            }
        }

        &__current-month {
            font-size: 1.5rem;
            font-weight: 600;
            margin-bottom: .5rem;
            text-align:center;
        }
        &__day-name {
            align-items: center;
            color: ${({ theme }) => theme.palette.calendarDay};
            display: inline-flex;
            font-size: 1rem;
            font-weight: 600;
            height: 2rem;
            justify-content: center;
            width: 2rem;
            
            @media ${devices.mobileS} and (max-width: 560px){
                font-size: .75rem;
            }
        }

        &__month-container {
            height: 19rem;
            
            @media ${devices.mobileS} and (max-width: 35rem){
                clear: both;
                height: auto;
            }
        }

        &__month-container:last-child {

            @media ${devices.mobileS} and (max-width: 35rem){
                border: none;
                margin-top:0.62rem;
            }
        }

        &__week {
            display: flex;
        }

        &__day {
            align-items: center;
            border-radius: 50%;
            color: ${({ theme }) => theme.palette.calendar};
            display: inline-flex;
            font-size: 1rem;
            font-weight: 600;
            height: 2rem;
            justify-content: center;
            width: 2rem;

            &.disabledDay {
                background: none;
                border-radius: 0;
                pointer-events: none;
            }
            @media ${devices.mobileS} and (max-width: 35rem){
                font-size: .75rem;
            }
        }

        &__day--selected:not(.react-datepicker__day--outside-month:empty),
        &__day--in-range.react-datepicker__day--selected:not(.react-datepicker__day--outside-month:empty) {
            background-color: ${({ theme }) => theme.palette.primary};
            border-radius: 50%;
            color: white;
        }

        &__day--in-selecting-range.react-datepicker__day--range-start:not(.react-datepicker__day--outside-month:empty) {
            border-radius: 50% 0 0 50%;
        }

        &__day--in-range {
            background-color: ${({ theme }) => theme.palette.dateInRange};
            color: inherit;
        }

        &__day--in-range.react-datepicker__day--range-start:not(.react-datepicker__day--outside-month:empty) {
            background-color: ${({ theme }) => theme.palette.primary};
            border-radius: 50% 0 0 50%;
            color: white;
        }

        &__day--in-range.react-datepicker__day--range-end:not(.react-datepicker__day--outside-month:empty) {
            background-color: ${({ theme }) => theme.palette.primary};
            border-radius: 0 50% 50% 0;
            color: white;
        }

        &__day--in-range.react-datepicker__day--range-start.react-datepicker__day--outside-month:empty {
            border-radius: 50% 0 0 50%;
        }

        &__day--in-range.react-datepicker__day--range-end.react-datepicker__day--outside-month:empty {
            border-radius: 0 50% 50% 0;
        }

        &__day--in-selecting-range {
            background-color: ${({ theme }) => theme.palette.dateInRange};
            color: inherit;
        }

        &__day--in-selecting-range.react-datepicker__day--selecting-range-start {
            background-color: ${({ theme }) => theme.palette.dateInRange};
            border-radius: 50% 0 0 50%;
            width: 32px;
        }

        &__day--in-selecting-range:hover {
            border-radius: 0 50% 50% 0;
        }

        &__day--in-range,
        &__day--in-selecting-range {
            border-radius: 0;
            box-sizing: border-box;
            margin-left: 0;
            margin-right: 0;
            width: calc(2rem + 0.166rem * 2);
        }

        &__day--in-range:last-child,
        &__day--in-selecting-range:last-child {
            border-radius: 0px 50% 50% 0px;
        }

        &__day--in-range:first-child,
        &__day--in-selecting-range:first-child {
            border-radius: 50% 0 0 50%;
        }

        &__day--today {
            font-weight: 400;
            position: relative;
        }
        &__day--today:before {
            background-color: transparent;
            border-radius: 50%;
            border: 1px solid black;
            content: '';
            display: flex;
            height: 2rem;
            pointer-events: none;
            position: absolute;
            width: 2rem;
        }
        &__navigation-icon::before {
            top: 1rem;
        }

        @media ${devices.mobileS} and (max-width: 560px){
            flex-direction:column;
            margin: 0 1.25rem;
        }
    }
`

export const SubmitBtn = styled.div`
    -moz-border-radius-bottomleft: .5rem;
    -moz-border-radius-bottomright: .5rem;
    -webkit-border-bottom-left-radius: .5rem;
    -webkit-border-bottom-right-radius: .5rem;
    background-color: ${({ theme }) => theme.palette.white};
    display: flex;
    justify-content: center;
    padding: 1rem;
    width: 100%;
`

export const FakeField = styled.span`
    appearance: none;
    cursor: text;
    display: flex;
    flex-direction: column;
    padding: 2rem 1rem 0;
    position: relative;
    width: 50%;
    
    @media ${devices.mobileS} and (max-width: 35rem){
        padding:1rem 0 .5rem 0;
    }

    & label {
        font-family: ${({ theme }) => theme.font.primary}!important;
        font-size: 1.5rem;
        text-align: center;
        
        @media ${devices.mobileS} and (max-width: 560px){
            font-size: 1rem;
        }
    }

    & label.title {
        letter-spacing: 0.5px;
    }

    & label.content {
        letter-spacing: 0.15px;
        line-height: 1.5rem;
    }

    & .title,
    & .content {
        font-weight: 400;
    }
`

export const DayDisabled = styled.span`
    background: ${({ theme }) => theme.palette.grey};
    width: 100%;
`

export const ButtonContainer = styled.div`
    display:flex;
    justify-content:flex-end;
    
    @media ${devices.mobileS} and (max-width: 375px){ 
        position: absolute;
        right: 1rem;
        z-index: 1;
    }
}
`

export const CloseBtn = styled.span`
    align-items: center;
    background-color: ${({ theme }) => theme.palette.close};
    border-radius: 50%;
    color: white;
    cursor:pointer; 
    display: flex;
    font-size: 1rem;
    font-weight: 300;
    height: 1rem;
    justify-content: center;
    margin-right: 5px;
    width: 1rem;
`
