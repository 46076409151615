import React, { FC } from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/dist/style.css'
import Theme from 'src/Theme'

interface PhoneInputProps {
  value: string;
  onChange: (phone: any) => void
}

const PhoneField: FC<PhoneInputProps> = ({
  value,
  onChange,
}) => {
  const Font = Theme.font.primary
  const regions = ['europe', 'north-america']
  const preferredCountries = ['es', 'us']

  return (
    <PhoneInput
      regions={regions}
      disableAreaCodes={true}
      preferredCountries={preferredCountries}
      value={value}
      onChange={onChange}
      containerStyle={{ fontFamily: Font }}
      inputStyle={{ fontFamily: Font, fontSize: '14px', fontWeight: 400 }}
    />
  )
}

export default PhoneField
