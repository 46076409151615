import styled from 'styled-components'
import Theme from 'src/Theme'
import { devices } from 'src/utils'
import { IconButton } from '@mui/material'

export const Button = styled(IconButton)`
    height: 2rem;
    margin: .8rem 0 0 .5rem;
    max-height: 2.5rem;
    min-height: 1rem;
    min-width: 1rem;      
    position: absolute !important;
    right: 0.25rem;
    top: 0.75rem;
`

export const Wrapper = styled.section`
    align-items:start;
    display:flex;
    flex-direction:column;
`
export const InputContainer = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
`

export const PillContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

export const LinkPill = styled.span`
  background-color: ${({ theme }) => theme.palette.grey};
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  display: flex;
  font-family: ${Theme.font.primary};
  justify-content: space-between;
  margin: 1rem 0 0;
  padding: 12px 8px;
  width: 100%;

  @media ${devices.mobileS} and (max-width: 560px){ 
    max-width: 15.62rem;
  }
`
export const TextPill = styled.span<{ maxwidth?:string }>`
  display: inline-block;
  max-width: ${({ maxwidth }) => maxwidth ?? '15rem'};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`
