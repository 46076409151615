/* eslint-disable no-prototype-builtins */
import React, { FC, useEffect } from 'react'
import { IdValue } from 'src/interfaces/store/quotation/interfaces'
import {
  Checkbox,
  CheckboxContainer,
  CheckboxInput,
  CheckboxItem,
} from './styles'

export interface MultipleSelectProps {
  options: IdValue[];
  values: IdValue[];
  maxlength?: number;
  handleChecked: (value: IdValue) => void;
  handleInput: (value: IdValue) => void;
  required?: boolean;
  sectionName?:string;
}

const MultipleCheckbox: FC<MultipleSelectProps> = ({
  options,
  values,
  sectionName,
  handleChecked,
  handleInput,
  ...rest
}: MultipleSelectProps) => {
  function onChangeInput (
    item: IdValue,
    evt: React.ChangeEvent<HTMLInputElement>,
  ) {
    item.input = evt.target.value
    handleInput && handleInput(item)
  }

  function handleClick (item: IdValue) {
    handleChecked && handleChecked(item)
  }

  function handleSelected (values: IdValue[], item: IdValue) {
    const itemFindIt = values.filter((val: IdValue) => val.id === item.id)
    return itemFindIt.length ? 'selected' : ''
  }

  useEffect(() => {}, [values])

  return (
    <CheckboxContainer direction="column">
      {options.map((option: IdValue, index) => (
        <CheckboxItem
          key={option.id}
          direction={
            option.hasOwnProperty('input') && option.value.length > 20 ? 'column' : 'row'
          }>
            <Checkbox
              key={option.value}
              className={handleSelected(values, option)}
              pointEvent={option.hasOwnProperty('input')}
              onClick={() => handleClick(option)}
              data-test={`${sectionName}-${option.id}`}
            >
              {option.value}
            </Checkbox>
          {option.hasOwnProperty('input') && (
            <CheckboxInput
              key={option.id}
              type="text"
              name={option.value}
              value={option.input}
              onInput={(evt: React.ChangeEvent<HTMLInputElement>) =>
                onChangeInput(option, evt)
              }
              placeholder={option.placeholder}
              disabled={option.disabled}
              error={rest.required && !option.input && !option.disabled}
            />
          )}
        </CheckboxItem>
      ))}
    </CheckboxContainer>
  )
}

export default MultipleCheckbox
