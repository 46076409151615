import Theme from 'src/Theme'
import { devices } from 'src/utils'
import styled from 'styled-components'

export const CheckboxContainer = styled.div<{
  direction: string,
}>`
  display: flex;
  flex-direction: ${(props) => props.direction};
  flex-wrap: wrap;
  width: 100%;
`

export const CheckboxItem = styled.div<{
  direction: string
}>`
  align-items: left;
  background-color: ${Theme.palette.borderInput};
  border-radius: 4px;
  border-top: .1rem solid ${({ theme }) => theme.palette.lightGrey};
  display: flex;
  flex-direction: ${(props) => props.direction};
  font-size: 25px;
  justify-content: space-between;
  padding: 0 0 0 1rem;
  position: relative;
  width: 100%;
  
  @media ${devices.mobileS} and (max-width: 1024px){
    padding: 0 0 0 0.31rem;
    flex-direction:column;
  }
`

export const Checkbox = styled.div<{
  key: any,
  pointEvent?: boolean,
  name?: string
}>`
  align-items: center;
  border-radius: 4px;
  display: flex;
  font-family: ${Theme.font.primary};
  font-size: .87rem;
  height: 3rem;
  padding-left: 2.5rem;
  pointer-events: ${(props) => (props.pointEvent ? 'auto' : 'none')};
  position: relative;
  
  @media ${devices.mobileS} and (max-width: 560px){
    padding-left: 2rem;
  }

  &::before {
    background: ${({ theme }) => theme.palette.white};
    border-radius: 4px;
    border: ${({ theme }) => `1px solid ${theme.palette.lightGrey}`};
    content: "";
    cursor: pointer;
    display: block;
    height: 1.25rem;
    left: 0;
    pointer-events: all;
    position: absolute;
    width: 1.25rem;
  }

  &.selected::after {
    background-color: ${({ theme }) => theme.palette.primary};
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1.25rem 18px;
    border-radius: 4px;
    border: 1px solid transparent;
    content: "";
    display: block;
    height: 1.25rem;
    left: 0;
    position: absolute;
    text-align: center;
    width: 1.25rem;
  }
  @media ${devices.mobileS} and (max-width: 1024px) {
    height: 2.5rem;
  };
`

export const CheckboxInput = styled.input<any>`
  align-self: center;
  background-color: ${({ theme }) => theme.palette.white};
  border-radius: .2rem;
  border: 1px solid ${({ error, theme }) => error ? theme.palette.errorBorder : theme.palette.lightGrey};
  color: ${({ theme }) => theme.palette.black};
  display: flex;
  font-family: ${({ theme }) => theme.font.primary};
  font-size: .87rem;
  height: 1.25rem;
  line-height: 1rem;
  margin: 0 1rem 0 1rem;
  min-height: 1.8rem;
  padding: 0 1rem;

  @media (min-width: 1366px) {
    width: ${({ name }) => name.includes('ceiling') ? '60%' : '90%'};
  }

  @media (max-width: 1366px) {
    width: ${({ name }) => name.includes('ceiling') ? '60%' : '75%'};
  }

  @media (max-width: 1024px) {
    width: ${({ name }) => name.includes('ceiling') ? '45%' : '75%'};
  }
  
  @media ${devices.mobileS} and (max-width: 1024px){
      font-size: 0.75rem;
      margin: 0 0 .5rem .52rem;
      padding: 0 0.15rem;
      width: 82%;
  }

  max-width: 38rem;

  &:disabled {
    background: ${({ theme }) => theme.palette.grey};
    @media ${devices.mobileS} and (max-width: 35rem) {
      &::placeholder {
        color: ${({ theme }) => theme.palette.placeholderInput};
        font-weight: 600;
      }
    }
  }
`
