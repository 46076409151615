import React, { useState } from 'react'
import styled from 'styled-components'
import Typography from 'src/components/atoms/Typography'

const CheckboxStyle = styled.div<CheckboxProps>`
  background-color: white;
  border-radius: 0.25rem;
  border: ${({ theme }) => `0.0625rem solid ${theme.palette.primary}`};
  cursor: pointer;
  display: block;
  height: 1.25rem;
  min-width: 1.25rem;
  pointer-events: all;
  position: relative;
  width: 1.25rem;


  &.checked::before {
    background-image: url(/images/checkbox-check.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1.25rem 1.125rem;
    border-radius: 0.25rem;
    border: 0.0625rem solid transparent;
    content: '';
    display: block;
    height: 1.25rem;
    left: -0.125rem;
    position: absolute;
    text-align: center;
    top: -0.125rem;
    width: 1.25rem;
  }
`
const CheckboxWrapper = styled.div`
    align-items: center;
    display: flex;
    gap: 0.625rem;
    justify-content:center;
    width: 100%;
`

export interface CheckboxProps {
  checked?: boolean
  label?: string
  onChange?: (value: boolean) => void
}

const Checkbox: React.FC<CheckboxProps> = ({
  checked,
  label,
  onChange,
}: CheckboxProps) => {
  const [value, setValue] = useState(checked)
  const onClickCheck = () => {
    setValue((val) => {
      onChange && onChange(!val)
      return !val
    })
  }

  return (
    <CheckboxWrapper>
      <CheckboxStyle onClick={onClickCheck} checked={value} className={checked ? 'checked' : ''} />
      <Typography variant='label'>{label}</Typography>
    </CheckboxWrapper>
  )
}
export default Checkbox
