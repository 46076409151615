import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import CardItem from 'src/components/molecules/CardItem'
import { FacilityTypeProps } from './types'
import { CardItemProps } from 'src/components/molecules/CardItem/types'
import { isExist } from 'src/utils/validationsHelper'
import { devices } from 'src/utils'

const FacilityTypeContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  width: 100%;

  @media ${devices.mobileS} and (max-width: 35rem){
    flex-direction:column;
    align-items:center
  }
`

const FacilityTypeSelector: React.FC<FacilityTypeProps> = ({
  values,
  options,
  onChange,
  disabled = true,
  setDisabled = () => { },
  onChangeOther,
  placeholder,
  other,
  required,
}: FacilityTypeProps) => {
  const [cardOptions, setCardOptions] = useState<CardItemProps[] | null>(null)
  const [selectedCard, setSelectedCard] = useState<CardItemProps[]>(values)

  const onClickOption = (option: CardItemProps) => {
    if (isExist(selectedCard, option.id).length) {
      const removeSelected = selectedCard.filter((opt) => opt.id !== option.id)
      setSelectedCard(removeSelected)
    } else {
      setSelectedCard([
        ...selectedCard,
        option,
      ])
    }
    onChange && onChange({
      title: option.title,
      description: option.description,
      image: option.image,
      id: option.id,
    })
  }

  useEffect(() => {
    if (options) {
      setCardOptions(
        options.map((opt) => ({
          ...opt,
        })),
      )
    }
  }, [])

  return (
    <FacilityTypeContainer>
      {cardOptions?.map((option, index) => (
        <CardItem
          width='15%'
          key={`scope_${index}`}
          {...option}
          isSelected={values.filter((val) => val.id === option.id).length}
          onClick={(val?: string | undefined) => {
            if (!val) options[11].other = val
            onClickOption(option)
          }}
          placeholder={placeholder}
          disabled={disabled}
          setDisabled={setDisabled}
          onChangeOther={onChangeOther}
          other={other}
          required={required}
          id={`type-building-${index}`}
        />
      ))}
    </FacilityTypeContainer>
  )
}

export default FacilityTypeSelector
