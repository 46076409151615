import React, { useEffect } from 'react'
import styled from 'styled-components'
import { StepperProps } from './types'
import { useDispatch, useSelector } from 'react-redux'
import { selectSteps, selectStepsCompleted } from 'src/state/quotation/selectors'
import { useNavigate } from 'react-router-dom'
import { setStepsCompletedAction } from 'src/state/quotation/actions'
import { devices } from 'src/utils'
import Img from 'src/components/atoms/Image'
import { isMobile, REACT_ENV } from 'src/utils/config'
import Theme from 'src/Theme'
import Typography from 'src/components/atoms/Typography'

const FinalStep = 6

const Title = styled.span`
  color: ${({ theme }) => theme.palette.black};
  font-family: ${Theme.font.primary};
  font-weight: 400;
`

const StyledContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  width: 100%;
`

const StyledSteps = styled.div<{
  currentStep: number,
  isMobile:boolean,
}>`
  ${({ theme, currentStep, isMobile }) => isMobile && currentStep === FinalStep ? '' : 'box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);'}
  background-color: ${({ theme, currentStep, isMobile }) => isMobile && currentStep === 6 ? '' : theme.palette.white};
  border-radius: 0 0 0.625rem 0.625rem;
  display: flex;
  height: 3.75rem;
  margin-top: 3.125rem;
  position: fixed;
  z-index:3;

  @media ${devices.mobileS} and (max-width: 47.5rem) {
    border-radius: 0 0 1.875rem 1.875rem;
    display: flex;
  }
`

const StyledStep = styled.div`
  cursor: pointer;
  display: flex;

  flex-direction:column;
  font-weight: 400;
  justify-content: center;
  padding-left: 0.625rem;
  position: relative;
  
  @media ${devices.mobileS} and (max-width: 35rem) {
    align-items:center;
    flex-direction:row;
    justify-content:center;
    padding: 0.62rem 0.62rem;
  }

  &::before {
    content: attr(data-id);
    display: flex;
    font-family: ${({ theme }) => theme.font.primary};
    font-weight: 400;
    height: 1.9375rem;
    width: 1.9375rem;

    position: absolute;

    border-radius: 50%;

    background-color: ${({ theme }) => theme.palette.primaryDisabled};
    color: ${({ theme }) => theme.palette.white};

    align-items: center;
    justify-content: center;
    
    @media ${devices.mobileS} and (max-width: 35rem) {
      position: relative;
    }
  }

  &.selected::before,
  &.active::before {
    background-color: ${({ theme }) => theme.palette.primary};
  }

  &.selected {
    & span {
      @media ${devices.mobileS} and (max-width: 35rem) {
        display: flex;
        padding-left: 5px;
        padding-right: 0;
      }
    }
  }

  &.active {
    & span {
      color: black;
    }
  }

  & span {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
    padding-left: 2.1875rem;
    padding-right: 0.5rem;
    
    @media ${devices.mobileS} and (max-width: 35rem) {
      display: none;
    }
  }
`

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const StepContainer = styled.div`
  border-top: 8px solid ${({ theme }) => theme.palette.primaryDisabled};
  color: ${({ theme }) => theme.palette.black_disabled};
  display: flex;

  &.active {
    border-top: 8px solid ${({ theme }) => theme.palette.primary};
  }

  &.selected {
    border-top: 8px solid ${({ theme }) => theme.palette.primary};
    color: ${({ theme }) => theme.palette.black};
  }
  
  &.selectedRed {
    background-color: ${({ theme }) => theme.palette.error};
  }
`
const ContainerFinalStep = styled.div`
  background-color: ${({ theme }) => theme.palette.primary};
  display: flex;
  height: 3.75rem;
  justify-content: center;
  padding: 0.625rem;
  width: 4.375rem;

  @media ${devices.mobileS} and (max-width: 35rem){
    align-items: center;
    border-radius: 0 0 1.875rem 1.875rem;
    display: flex;
    height: 70%;
    justify-content: center;
    min-width: 23.5rem;
    padding: 0 1rem;
    width: 100%;
  }

`
const TextAllSet = styled.span`
  color: ${({ theme }) => theme.palette.white};
  margin: 0 1rem;
`

const Stepper: React.FC<StepperProps> = ({
  children,
  currentStep,
}: StepperProps) => {
  const dispatch = useDispatch()
  const steps = useSelector(selectSteps)
  const stepsCompleted = useSelector(selectStepsCompleted)
  const router = useNavigate()

  function goToStep (step: number) {
    const stepExist = stepsCompleted.find((stepC: any) => stepC === step)
    if (stepExist) {
      router(`/quote/${step}`)
    }
  }

  function handleSelected (step: number) {
    const stepExist = stepsCompleted.find((stepC: any) => stepC === step)
    return (step === currentStep ? 'selected' : (stepExist < currentStep ? 'active' : ''))
  }

  useEffect(() => {
    dispatch(setStepsCompletedAction({ id: currentStep }))
  }, [])

  return (
    <StyledContainer>
      <StyledSteps isMobile={isMobile} currentStep={currentStep} >
        {steps.map((step, idx) => (
          <React.Fragment key={idx + 1}>
            {((currentStep !== FinalStep && isMobile) || (!isMobile)) &&
            <StepContainer
              key={idx}
              className={handleSelected(idx + 1)}
              style={{ borderRadius: idx === 0 ? '0 0 0 10px' : '0 0 0 0' && idx === 3 ? '0 0 10px 0' : '0 0 0 0' }}>
              <StyledStep
                key={idx}
                data-id={`${idx + 1}`}
                className={handleSelected(idx + 1)}
                onClick={() => goToStep(idx + 1)}
              >
                {Object.values(step).map((info, index) => (
                  <Title key={index} title={info}>{info}</Title>
                ))}
              </StyledStep>
            </StepContainer>
            }
            {(currentStep === FinalStep && idx === 3) && (
              <ContainerFinalStep>
                <Img src={`${REACT_ENV.PUBLIC_URL}/images/check.svg`} isMobile/>
                {isMobile && <TextAllSet>
                  <Typography variant='span' color={Theme.palette.white}>
                    All set!
                  </Typography>
                </TextAllSet>}
              </ContainerFinalStep>
            )}
          </React.Fragment>
        ))}
      </StyledSteps>
      <StyledContent>{children}</StyledContent>
    </StyledContainer>
  )
}

export default Stepper
