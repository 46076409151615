import {
  Accessibility,
  AreasScanned,
  BasicData,
  FinalStep,
  IdValue,
  LevelInfo,
  QuotationState,
  ScanItem,
  ScopeOfWork,
  SiteExterior,
  stepCompleted,
  SwapPriorities,
} from 'src/interfaces/store/quotation/interfaces'
import { ReduxAction } from 'src/interfaces/store/redux.helper'
import { StoreStatus } from 'src/interfaces/store/types'
import { createReducer, mapToState } from 'src/utils/storeHelper'
import * as types from './types'
import { merge, mergeWith, isArray } from 'lodash'
import { ScopeSelectorOption } from 'src/components/organisms/ScopeSelector/types'
import { FileItem } from 'src/components/molecules/InputFIle/types'

function buildLegend (array: ScanItem[]): string {
  let legend = ''
  array.forEach((item) => {
    legend += `${item.shortName}/`
  })
  legend = legend.substring(0, legend.length - 1)
  return legend
}

const defaultStepTexts = [
  {
    0: 'Project Information',
    1: '',
  },
  {
    0: 'Type of Project',
    1: '',
  },
  {
    0: 'Scope Details',
    1: '',
  },
  {
    0: 'Accessibility',
    1: '',
  },
]

const baseState: QuotationState = {
  email: '',
  status: StoreStatus.NOT_STARTED,
  currentStep: 1,
  basicData: {},
  ScopeOfWork: {
    typeOfBuildings: [],
  },
  AreasScanned: {
    BuildingAreas: [],
    otherBA: '',
    ceilingDetails: '',
    scanningRequiredDeliverables: [],
    disciplinesOfInterest: [],
    otherInteres: '',
    scanningDeliverablesList: [],
    additionalDetails: '',
    otherBimDeliverables: '',
    otherScanningRequiredDeliverables: '',
  },
  laserScanIntent: {
    scanningPriority: [
      {
        id: '1',
        shortName: 'MEPF',
        value: 'MEPF (Mechanical, Electrical, Plumbing, and Fire)',
      },
      {
        id: '2',
        shortName: 'Arch',
        value: 'Arch',
      },
      {
        id: '3',
        shortName: 'Structure',
        value: 'Structure',
      },
    ],
  },
  levels: {
    levelInfo: {},
  },
  accessibility: {
    tentativeDate: {
      startDate: '',
      endDate: '',
    },
  },
  roof: {},
  exterior: {},
  finalStep: {},
  siteExterior: {},
  steps: {
    0: defaultStepTexts[0],
    1: defaultStepTexts[1],
    2: defaultStepTexts[2],
    3: defaultStepTexts[3],
  },
  stepsCompleted: [],
}

const setEmail = (
  state: QuotationState,
  action: ReduxAction<BasicData>,
): QuotationState => {
  return mergeWith(state, {
    email: action.payload,
  }, function (objValue, srcValue) {
    if (isArray(objValue)) {
      return srcValue
    }
  })
}

const setBasicData = (
  state: QuotationState,
  action: ReduxAction<BasicData>,
): QuotationState => {
  return mergeWith(state, {
    basicData: action.payload,
  }, function (objValue, srcValue) {
    if (isArray(objValue)) {
      return srcValue
    }
  })
}

const nextStep = (state: QuotationState) => {
  return merge({}, state, {
    currentStep: state.currentStep + 1,
  })
}

const resetStore = (state: QuotationState) => {
  return { ...baseState, basicData: { id: state.basicData.id, finalStep: false } }
}

const setScopeOfWork = (
  state: QuotationState,
  action: ReduxAction<ScopeOfWork>,
): QuotationState => {
  return mergeWith(state, {
    ScopeOfWork: action.payload,
  }, function (objValue, srcValue) {
    if (isArray(objValue)) {
      return srcValue
    }
  })
}

const setAreasScanned = (
  state: QuotationState,
  action: ReduxAction<AreasScanned>,
): QuotationState => {
  return mergeWith(state, {
    AreasScanned: action.payload,
  }, function (objValue, srcValue) {
    if (isArray(objValue)) {
      return srcValue
    }
  })
}

const stepsCompleted = (
  state: QuotationState,
  action: ReduxAction<stepCompleted>,
) => {
  const existStep = state.stepsCompleted.some((x:any) => x === action.payload?.id)
  const stepsCompleted = existStep ? state.stepsCompleted : [...state.stepsCompleted, action.payload?.id]
  localStorage.setItem(
    'stepsCompleted',
    JSON.stringify(stepsCompleted),
  )
  return merge({}, state, {
    stepsCompleted: stepsCompleted,
  })
}

const swapPriorities = (
  state: QuotationState,
  action: ReduxAction<SwapPriorities>,
): QuotationState => {
  const originalArr = Array.from(state.laserScanIntent.scanningPriority)
  const lastItem = originalArr[action.payload.endIndex]
  originalArr[action.payload.endIndex] = originalArr[action.payload.startIndex]
  originalArr[action.payload.startIndex] = lastItem

  const legend = buildLegend(originalArr)

  return merge({}, state, {
    steps: {
      [state.currentStep - 1]: {
        2: legend,
      },
    },
    laserScanIntent: {
      scanningPriority: originalArr,
    },
  })
}

const setScope = (
  state: QuotationState,
  action: ReduxAction<ScopeSelectorOption>,
): QuotationState => {
  let newScopes = state.laserScanIntent.generalScope
    ? Array.from(state.laserScanIntent.generalScope)
    : undefined
  const selectedScope = newScopes?.findIndex(
    (val) => val && val.id === action.payload.id,
  )
  if (selectedScope !== undefined && selectedScope >= 0) {
    newScopes?.splice(selectedScope, 1)
  } else {
    newScopes = newScopes || []
    newScopes?.push({
      id: action.payload.id,
      value: action.payload.title,
    })
  }
  delete state.laserScanIntent.generalScope
  return merge({}, state, {
    laserScanIntent: {
      generalScope: newScopes!.length > 0 ? newScopes : null,
    },
    steps: {
      1: {
        1: defaultStepTexts[1][1],
      },
    },
  })
}

const setFacilityType = (
  state: QuotationState,
  action: ReduxAction<IdValue>,
): QuotationState => {
  return merge({}, state, {
    facilityType: action.payload,
    steps: {
      [state.currentStep - 1]: {
        1: action.payload.value,
      },
    },
  })
}

const setScanZone = (
  state: QuotationState,
  action: ReduxAction<IdValue>,
): QuotationState => {
  return merge({}, state, {
    scanZone: action.payload,
    steps: {
      [state.currentStep - 1]: {
        1: action.payload.value,
      },
    },
  })
}

const setLevel = (
  state: QuotationState,
  action: ReduxAction<IdValue | null>,
) => {
  return merge({}, state, {
    levels: {
      levelValue: action.payload,
    },
  })
}

const setLevelInfo = (
  state: QuotationState,
  action: ReduxAction<LevelInfo>,
) => {
  const stateMergeWith = mergeWith(state, {
    levels: {
      levelInfo: {
        [+action.payload.levelNumber!]: {
          ...state.levels.levelInfo[+action.payload.levelNumber!],
          ...action.payload,
        },
      },
    },
  }, function (objValue, srcValue) {
    if (isArray(objValue)) {
      return srcValue
    }
  })
  return merge({}, state, stateMergeWith)
}

const setCeilingType = (
  state: QuotationState,
  action: ReduxAction<LevelInfo>,
) => {
  return mergeWith(state, {
    levels: {
      levelInfo: {
        [+action.payload.levelNumber!]: {
          ceilingType: action.payload.ceilingType,
        },
      },
    },
  }, function (objValue, srcValue) {
    if (isArray(objValue)) {
      return srcValue
    }
  })
}

const setTilesRemoved = (
  state: QuotationState,
  action: ReduxAction<IdValue | null>,
) => {
  return merge({}, state, {
    levels: {
      tilesRemoved: action.payload,
    },
  })
}

const setSpecialRequirement = (
  state: QuotationState,
  action: ReduxAction<string>,
) => {
  return merge({}, state, {
    accessibility: {
      specialRequirement: action.payload,
    },
  })
}

const setIsRoofAccesible = (
  state: QuotationState,
  action: ReduxAction<IdValue | null>,
) => {
  return merge({}, state, {
    roof: {
      isRoofAccesible: action.payload,
    },
  })
}

const setHowCanAccess = (
  state: QuotationState,
  action: ReduxAction<string | null>,
) => {
  return merge({}, state, {
    roof: {
      howCanAccess: action.payload,
    },
  })
}

const setIsTerrace = (
  state: QuotationState,
  action: ReduxAction<IdValue | null>,
) => {
  return merge({}, state, {
    roof: {
      isTerrace: action.payload,
    },
  })
}

const setCanDroneFly = (
  state: QuotationState,
  action: ReduxAction<IdValue | null>,
) => {
  return merge({}, state, {
    roof: {
      canDroneFly: action.payload,
    },
  })
}

const setSQTF = (state: QuotationState, action: ReduxAction<string | null>) => {
  return merge({}, state, {
    roof: {
      sqtf: action.payload,
    },
  })
}

const setLinearFeet = (
  state: QuotationState,
  action: ReduxAction<string | null>,
) => {
  return merge({}, state, {
    exterior: {
      linearFeet: action.payload,
    },
  })
}

const setFacadeHeight = (
  state: QuotationState,
  action: ReduxAction<string | null>,
) => {
  return merge({}, state, {
    exterior: {
      facadeHeight: action.payload,
    },
  })
}

const setPicture = (
  state: QuotationState,
  action: ReduxAction<FileItem | null>,
) => {
  return merge({}, state, {
    exterior: {
      picture: action.payload,
    },
  })
}

const setAccesibility = (
  state: QuotationState,
  action: ReduxAction<Accessibility | null>,
) => {
  return merge({}, state, {
    accessibility: action.payload,
  })
}

const setFinalStep = (
  state: QuotationState,
  action: ReduxAction<FinalStep | null>,
) => {
  return merge({}, state, {
    finalStep: action.payload,
  })
}

const setSiteExterior = (
  state: QuotationState,
  action: ReduxAction<SiteExterior | null>,
) => {
  return merge({}, state, {
    siteExterior: action.payload,
  })
}

const initRFP = (
  state: QuotationState,
  action: ReduxAction<any>,
) => {
  return merge({}, state, {
    status: StoreStatus.START_QUOTE,
    basicData: {
      id: action.payload.id,
    },
    steps: {
      0: {
        0: 'Project Information',
      },
    },
  })
}

const updateRFP = (
  state: QuotationState,
  action: ReduxAction<any>,
) => {
  return merge({}, state, {
    status: StoreStatus.QUOTE_IN_PROGRESS,
  })
}

const setCurrentStep = (
  state: QuotationState,
  action: ReduxAction<any>,
) => {
  return merge({}, state, {
    currentStep: action.payload.currentStep,
  })
}

export const quotationReducer = createReducer(
  { ...baseState },
  {
    [types.SET_EMAIL]: setEmail,
    [types.DONWLOAD_RFP_ACTION]: mapToState({
      status: StoreStatus.LOADING,
    }),
    [types.UPDATE_RFP_ACTION]: mapToState({
      status: StoreStatus.LOADING,
    }),
    [types.UPDATE_RFP_ACTION_COMPLETED]: updateRFP,
    [types.INIT_RFP_ACTION_COMPLETED]: initRFP,
    [types.SET_BASIC_DATA_ACTION]: setBasicData,
    [types.NEXT_STEP_ACTION]: nextStep,
    [types.SET_SCOPE_OF_WORK_ACTION]: setScopeOfWork,
    [types.SWAP_PRIORITIES_ACTION]: swapPriorities,
    [types.SET_SCOPE_ACTION]: setScope,
    [types.SET_AREAS_TO_BE_SCANNED_ACTION]: setAreasScanned,
    [types.SET_FACILITY_TYPE_ACTION]: setFacilityType,
    [types.SET_SCAN_ZONE_ACTION]: setScanZone,
    [types.SET_LEVEL_ACTION]: setLevel,
    [types.SET_CEILING_TYPE_ACTION]: setCeilingType,
    [types.SET_TILES_REMOVED_ACTION]: setTilesRemoved,
    [types.SET_SPECIAL_REQUIREMENT_ACTION]: setSpecialRequirement,
    [types.SET_ROOF_ACCESIBLE_ACTION]: setIsRoofAccesible,
    [types.SET_HOW_ACCESS_ACTION]: setHowCanAccess,
    [types.SET_IS_TERRACE_ACTION]: setIsTerrace,
    [types.SET_CAN_DRONE_FLY_ACTION]: setCanDroneFly,
    [types.SET_SQTF_ACTION]: setSQTF,
    [types.SET_LEVEL_INFO_ACTION]: setLevelInfo,
    [types.SET_ACCESSIBILITY_ACTION]: setAccesibility,
    [types.SET_LINEAR_FEET_ACTION]: setLinearFeet,
    [types.SET_FACADE_HEIGHT_ACTION]: setFacadeHeight,
    [types.SET_PICTURE_ACTION]: setPicture,
    [types.RESET_STORE]: resetStore,
    [types.SET_FINAL_STEP_ACTION]: setFinalStep,
    [types.SET_SITE_EXTERIOR_ACTION]: setSiteExterior,
    [types.SET_STEPS_COMPLETED_ACTION]: stepsCompleted,
    [types.SET_CURRENT_STEP_ACTION]: setCurrentStep,
    [types.FINISH_QUOTE]: mapToState({
      status: StoreStatus.END_QUOTATION,
    }),
  },
)
