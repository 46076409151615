import { createDeepEqualSelector } from 'src/utils/storeHelper'
import { AppState } from 'src/state/index'
import {
  BasicData,
  Email,
  Exterior,
  IdValue,
  LevelInfo,
  SiteExterior,
} from 'src/interfaces/store/quotation/interfaces'
import { Step } from 'src/components/organisms/Stepper/types'
import { ScopeSelectorOption } from 'src/components/organisms/ScopeSelector/types'
import { StoreStatus } from 'src/interfaces/store/types'

export const selectEmail = createDeepEqualSelector(
  (state: AppState) => state.quotation.email,
  (email: Email) => email,
)

export const selectBasicData = createDeepEqualSelector(
  (state: AppState) => state.quotation.basicData,
  (basicData: BasicData) => basicData,
)

export const selectCurrentStep = createDeepEqualSelector(
  (state: AppState) => state.quotation.currentStep,
  (currentStep) => Number(currentStep),
)

export const selectSteps = createDeepEqualSelector(
  (state: AppState) => state.quotation.steps,
  (steps) => Object.values(steps) as Array<Step>,
)

export const selectScopeOfWork = createDeepEqualSelector(
  (state: AppState) => state.quotation.ScopeOfWork,
  (scopeOfWork) => scopeOfWork,
)

export const selectAreasScanned = createDeepEqualSelector(
  (state: AppState) => state.quotation.AreasScanned,
  (areasScanned) => areasScanned,
)

export const selectScanPriority = createDeepEqualSelector(
  (state: AppState) => state.quotation.laserScanIntent.scanningPriority,
  (scanPriority) => scanPriority,
)

export const selectScope = createDeepEqualSelector(
  (state: AppState) => state.quotation.laserScanIntent.generalScope,
  (generalScope) =>
    generalScope ? (generalScope as ScopeSelectorOption[]) : null,
)

export const selectLevel = createDeepEqualSelector(
  (state: AppState) => state.quotation.levels.levelValue,
  (levelValue) => (levelValue ? (levelValue as IdValue) : null),
)

export const selectAccessibility = createDeepEqualSelector(
  (state: AppState) => state.quotation.accessibility,
  (accessibility) => accessibility,
)

export const selectCeilingType = createDeepEqualSelector(
  (state: AppState) => state.quotation.levels.ceilingType,
  (ceilingType) => (ceilingType ? (ceilingType as IdValue) : null),
)

export const selectTilesRemoved = createDeepEqualSelector(
  (state: AppState) => state.quotation.levels.tilesRemoved,
  (tilesRemoved) => (tilesRemoved ? (tilesRemoved as IdValue) : null),
)

export const selectSpecialRequirement = createDeepEqualSelector(
  (state: AppState) => state.quotation.accessibility.specialRequirement,
  (specialRequirement) =>
    specialRequirement ? (specialRequirement as string) : null,
)

export const selectRoofAccesible = createDeepEqualSelector(
  (state: AppState) => state.quotation.roof.isRoofAccesible,
  (roofAccesible) => (roofAccesible ? (roofAccesible as IdValue) : null),
)

export const selectHowCanAccess = createDeepEqualSelector(
  (state: AppState) => state.quotation.roof.howCanAccess,
  (howCanACcess) => (howCanACcess ? (howCanACcess as string) : null),
)

export const selectIsTerrace = createDeepEqualSelector(
  (state: AppState) => state.quotation.roof.isTerrace,
  (isTerrace) => (isTerrace ? (isTerrace as IdValue) : null),
)

export const selectCanDroneFly = createDeepEqualSelector(
  (state: AppState) => state.quotation.roof.canDroneFly,
  (canDroneFly) => (canDroneFly ? (canDroneFly as IdValue) : null),
)

export const selectSQTF = createDeepEqualSelector(
  (state: AppState) => state.quotation.roof.sqtf,
  (sqtf) => (sqtf ? (sqtf as string) : null),
)

export const selectCurrentLevelInfo = (levelNumber: number) =>
  createDeepEqualSelector(
    (state: AppState) => state.quotation.levels.levelInfo[levelNumber],
    (levelInfo) => (levelInfo ? (levelInfo as LevelInfo) : null),
  )

export const selectLevels = createDeepEqualSelector(
  (state: AppState) => state.quotation.levels.levelInfo,
  (levelInfo) => (levelInfo ? (Object.values(levelInfo) as LevelInfo[]) : null),
)

export const selectStepsCompleted = createDeepEqualSelector(
  (state: AppState) => state.quotation.stepsCompleted,
  (stepsCompleted) => (localStorage.stepsCompleted ? JSON.parse(localStorage.stepsCompleted) : stepsCompleted),
)

export const isAllLevelsCompleted = createDeepEqualSelector(
  (state: AppState) => state.quotation.levels.levelInfo,
  (levelInfo) => {
    let validator = true
    Object.values(levelInfo as LevelInfo[]).forEach((item) => {
      if (item.ceilingScanRequired) {
        validator =
          validator &&
          (item.levelNumber !== undefined &&
          !!item.sqtf &&
          !!item.attachments &&
          !!item.ceilingHeight)
      } else {
        validator =
          validator &&
          (item.levelNumber !== undefined &&
          !!item.sqtf)
      }
    })
    return validator
  },
)

export const selectExterior = createDeepEqualSelector(
  (state: AppState) => state.quotation.exterior,
  (exterior) => exterior as Exterior,
)

export const selectSiteExterior = createDeepEqualSelector(
  (state: AppState) => state.quotation.siteExterior,
  (siteExterior) => siteExterior as SiteExterior,
)

export const selectQuotationStatus = createDeepEqualSelector(
  (state: AppState) => state.quotation.status,
  (quotationStatus) => quotationStatus as StoreStatus,
)

export const selectFinalStep = createDeepEqualSelector(
  (state: AppState) => state.quotation.finalStep,
  (finalStep) => finalStep,
)
