/* eslint-disable no-useless-escape */
import React, { useEffect } from 'react'
import Input from 'src/components/atoms/Input'
import { Button, InputContainer, LinkPill, PillContainer, TextPill, Wrapper } from './style'
import CancelIcon from '@mui/icons-material/Cancel'
import { grey } from '@mui/material/colors'
import { SAVE_LINK } from 'src/utils/config'
import { Tooltip, TooltipProps, tooltipClasses } from '@mui/material'
import styled from 'styled-components'
import Theme from 'src/Theme'

interface Props {
  FileLinks: {
    value: string
  }[],
  onChangeLinks: (link: string, type: string) => void
  inputValue: string
  setInputValue: (value: string) => void
  setRequired: (value: boolean) => void
}

const MsjError = styled.div`
  color: red;
  margin: .5rem 0 0;
`

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
})

const FileLink = ({
  FileLinks,
  onChangeLinks,
  inputValue,
  setInputValue,
  setRequired,
}: Props) => {
  const [links, setLinks] = React.useState<string[]>([])
  const [error, setError] = React.useState<string>('')

  const deleteLink = (link: string) => {
    const updLinks = links.filter((item) => item !== link)
    onChangeLinks(link, 'delete')
    setLinks(updLinks)
  }
  const addLink = () => {
    if (!inputValue) return
    const isExist = links.some((link: string) => link === inputValue)
    if (validateUrl(inputValue) && !isExist) {
      setLinks([
        ...links,
        inputValue,
      ])
      onChangeLinks(inputValue, 'add')
      setRequired(false)
      setError('')
      setInputValue('')
      return
    }

    setError('Invalid format')
  }

  const validateUrl = (urlInput: string) => {
    try {
      const regxUrl = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm
      return regxUrl.test(urlInput)
    } catch {
      return false
    }
  }

  const onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(evt.target.value || '')
    setError('')
    setRequired(false)
  }

  useEffect(() => {
    if (FileLinks.length) {
      setLinks(FileLinks.map((link) => link.value))
    }
  }, [FileLinks])
  return (
    <Wrapper>
      <InputContainer>
        <Input
          type='text'
          name='file-link'
          fontSize='1rem'
          fontSizeMob='.8rem'
          placeholder='Add file link(s) i.e. Dropbox, Drive, etc.'
          maxWidth='44.375rem'
          minHeight='7.5rem'
          withBorder
          width='100%'
          value={inputValue}
          onChange={onChange}
          error={error}
          padding="0.5rem 5.5rem 0.5rem 0.5rem"
          onKeyUp={() => addLink()}
        />
        <Button
          onClick={() => addLink()}
          size='small'
          style={{ backgroundColor: Theme.palette.primary, borderRadius: '5px', color: Theme.palette.white, marginTop: '0.125rem' }}
        >
          {SAVE_LINK}
        </Button>
      </InputContainer>
      {error && <MsjError>{error}</MsjError>}
      <PillContainer>
        {links.map((link) =>
          <LinkPill key={link}>
            <CustomWidthTooltip arrow title={link}>
              <TextPill maxwidth="40rem">{link}</TextPill>
            </CustomWidthTooltip>
            <CancelIcon
              sx={{ color: grey[600], cursor: 'pointer' }}
              onClick={() => deleteLink(link)} />
          </LinkPill>,
        )}
      </PillContainer>
    </Wrapper>
  )
}

export default FileLink
