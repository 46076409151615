import React, { ChangeEvent, HTMLInputTypeAttribute } from 'react'
import styled, { css } from 'styled-components'
import Theme from 'src/Theme'
import { isMobile } from 'src/utils/config'

interface InputStyleProps {
  backgroundColor?: string
  fontSize?: string
  inline?: boolean
  login?: boolean
  maxlength?: string
  width?: string
  withBorder?: boolean
  padding?:string
}

export const InputStyle = styled.input<InputStyleProps>`
  background-color: ${({ theme }) => theme.palette.white};
  border-radius: .2rem;
  border: 0;
  border: 1px solid #DFE2E7;
  box-shadow: none;
  color: ${({ theme }) => theme.palette.black};
  display: flex;
  font-family: ${Theme.font.primary};
  font-size: ${({ fontSize = '14px' }) => fontSize};
  font-weight: 400;
  line-height: 1.625rem;
  margin-left: ${({ inline }) => inline ? '15px' : '0'};
  margin-top: ${({ inline }) => inline ? '0' : '10px'};
  outline: none;
  width: ${({ width }) => width || '100%'};

  ${({ withBorder, theme }) => withBorder && css`border: 1px solid ${Theme.palette.lightGrey}`};

  background-color: ${Theme.palette.darkGrey};
   ${({ login = false, padding = '0.5rem 0.5rem' }) =>
    login
      ? css`
        height: 2.25rem;
        padding: .5rem .5rem;
      `
      : css`
          border-radius: 4px;
          font-weight: 500;
          gap: 5px;
          height: 2.5rem;
          max-height: 2.5rem;
          padding: ${padding};
        `};

  &:disabled {
    background: ${({ theme }) => theme.palette.grey};
  }
`
export interface InputProps {
  backgroundColor?:string
  disabled?: boolean
  error?: any
  fontSize?: string
  fontSizeMob?: string
  id?: string
  inline?: boolean
  label?: string
  login?: boolean
  maxlength?: number
  maxWidth?: string
  minHeight?:string
  name?: string
  onBlur?: () => void
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onKeyUp?: () => void
  pattern?: string
  placeholder?: string
  type?: HTMLInputTypeAttribute
  value?: any
  width?: string
  withBorder?: boolean
  padding?:string
}

const Input: React.FC<InputProps> = ({
  name,
  id,
  label,
  placeholder = ' ',
  backgroundColor,
  padding,
  onKeyUp,
  ...rest
}: InputProps) => {
  return (
    <>
      <InputStyle
        backgroundColor={backgroundColor}
        disabled={rest.disabled}
        fontSize={isMobile ? rest.fontSizeMob : rest.fontSize}
        id={id}
        inline={rest.inline}
        login={rest.login}
        maxLength={rest.maxlength}
        name={name}
        onBlur={rest.onBlur}
        onChange={rest.onChange}
        pattern={rest.pattern}
        placeholder={placeholder}
        type={rest.type}
        value={rest.value}
        width={rest.width}
        withBorder={!!rest.withBorder}
        padding={padding}
        onKeyUp={(evt) => {
          if (evt.key !== 'Enter') return
          onKeyUp && onKeyUp()
        }}
        data-test={name}
      />
    </>
  )
}

export default Input
