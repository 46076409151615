import React, { useEffect, useState } from 'react'
import { format, Locale } from 'date-fns'
import { DatePickerProps } from './types'
import 'react-datepicker/dist/react-datepicker.css'
import Typography from 'src/components/atoms/Typography'
import enLocale from 'src/utils/localeHelper'
import ReactDatePicker from 'react-datepicker'
import { DateFormat } from 'src/utils/formatHelper'
import { useSelector } from 'react-redux'
import { selectFinalStep } from 'src/state/quotation/selectors'
import Button from 'src/components/atoms/Button'
import { REACT_ENV } from 'src/utils/config'
import { CloseBtn, DatePickerActions, DatePickerBody, DatePickerContent, DatePickerHeader, DatePickerModal, Wrapper } from './styles'
import { DayDisabled } from '../DateRangePicker/styles'

const today = new Date()
today.setHours(0, 0, 0, 0)

const DatePicker: React.FC<DatePickerProps> = ({
  handleDate,
  onChange,
  value,
  disabled,
  onSubmit,
}: DatePickerProps) => {
  const { showDate, setShowDate } = handleDate ?? { showDate: false, setShowDate: () => {} }
  const [showDatePicker, setShowDatepicker] = useState<boolean>(showDate ?? false)

  const [meetingDate, setMeetingDate] = useState<any>(null)
  const finalStep = useSelector(selectFinalStep)

  const openDatePicker = () => {
    setShowDatepicker(true)
  }

  const closeDatePicker = () => {
    setShowDatepicker(false)
    setShowDate(false)
  }

  const handleChangeDate = (date: Date) => {
    const checkDate = new Date(date)
    if (checkDate.getTime() <= today.getTime()) {
      return
    }
    setMeetingDate(date)
    onChange && onChange(date)
  }

  const renderDayContents = (day: any, date: any) => {
    const validDate = new Date(date ?? '')
    if (validDate.getTime() <= today.getTime()) {
      return <DayDisabled title={date} className="disabled">{day}</DayDisabled>
    }
    return day
  }

  const submitDate = () => {
    onSubmit && onSubmit()
  }

  useEffect(() => {
    finalStep?.meetingDate && setMeetingDate(finalStep?.meetingDate)
  }, [])
  return (
    <>
      <Wrapper
        className={!disabled ? '' : 'disabled'}
        onClick={openDatePicker}>
        <img
          src={`${REACT_ENV.PUBLIC_URL}/images/calendar-icon.png`}
          width='35'
          height='35'
        />
        <Typography
          className='text-thin'
          variant='label'
          fontSize='1rem'
          color='textSecondary'
        >
          {value ? `${format(value, DateFormat)}` : 'DD/MM/YYYY'}
        </Typography>
      </Wrapper>
      {showDatePicker && (
        <DatePickerModal>
          <DatePickerContent>
            <CloseBtn onClick={closeDatePicker}>&times;</CloseBtn>
            <DatePickerHeader>
                <Typography
                  align='center'
                  variant='h2'
                  fontSize='1.5rem'
                  color='primary'
                >
                  When would you need <br /> this quote by?
                </Typography>
            </DatePickerHeader>
            <DatePickerBody>
              <ReactDatePicker
                locale={enLocale as Locale}
                disabledKeyboardNavigation
                selected={meetingDate}
                onChange={(date) =>
                  handleChangeDate(date as Date)
                }
                inline
                renderDayContents={renderDayContents}
                shouldCloseOnSelect={false}
              />
            </DatePickerBody>
            <DatePickerActions>
              <Button
                align='center'
                bold={true}
                borderRadius='.5rem'
                disabled={!meetingDate}
                onClick={submitDate}
                size='small'
                style={{ margin: '0 1rem 0 0' }}
                uppercase
                width="10rem"
                data-test="btn-submit-date-calendar"
                >
                Submit Date
              </Button>
            </DatePickerActions>
          </DatePickerContent>
        </DatePickerModal>
      )}
    </>
  )
}

export default DatePicker
