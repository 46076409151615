import React, { useState } from 'react'
import Header from './Header'
import Body from './Body'
import Footer from './Footer'
import { BoxModal, ContentModal, ModalClose, ModalToolBar } from './style'
import { ImgInformation } from 'src/shared/styles'
import { REACT_ENV } from 'src/utils/config'
import { TitleModal } from 'src/components/organisms/Modals/SliderModal/styles'

const Dialog = ({
  children,
  open,
  closeIcon,
  onClose,
  title = '',
}: any) => {
  const [isOpenModal, setStateOpen] = useState<boolean>(open)

  function handleClose () {
    if (onClose) onClose()
    else handleCloseDefault()
  }

  function handleCloseDefault () {
    setStateOpen(false)
  }

  return (
    <>
    {isOpenModal && (
        <>
        <BoxModal onClick={() => handleClose()} />
        <ContentModal>
          <ModalToolBar>
            <ImgInformation
              margin="0.75rem 1rem"
              src={`${REACT_ENV.PUBLIC_URL}/images/information.png`}
            />
            {title &&
            <TitleModal>
              {title}
            </TitleModal>}
            {closeIcon &&
            <ModalClose
                aria-label="close-dialog"
                onClick={() => handleClose()}
            >
                <img src={`${REACT_ENV.PUBLIC_URL}/images/icon-close.png`} width="20px"/>
            </ModalClose>
            }
          </ModalToolBar>
            {children}
        </ContentModal>
        </>
    )}
    </>
  )
}

Dialog.Header = Header
Dialog.Body = Body
Dialog.Footer = Footer

export default Dialog
