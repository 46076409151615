import { CardItemProps } from 'src/components/molecules/CardItem/types'
import { IdValue } from 'src/interfaces/store/quotation/interfaces'
import { isMobile, OTHERLABEL, OTHERSLABEL } from 'src/utils/config'

export const selectOptions: IdValue[] = [
  {
    id: '1',
    value: 'Create as-built drawings/3D model',
  },
  {
    id: '2',
    value: 'Space Planning/Area survey for real estate',
  },
  {
    id: '3',
    value: 'Clash detection of new design vs. existing structures',
  },
  {
    id: '4',
    value: 'Scanning for prefabrication and shop drawings',
  },
  {
    id: '5',
    value: 'Construction verification/progress tracking',
  },
  {
    id: '6',
    value: 'Floor flatness validation',
  },
  {
    id: '7',
    value: 'Underground utilities location',
  },
  {
    id: '8',
    value: 'Urban planning',
  },
  {
    id: '9',
    value: 'Facilities Management',
  },
  {
    id: '10',
    value: 'Marketing/Virtual tours',
  },
  {
    id: '11',
    value: OTHERLABEL,
  },
]

export const ScanningAreasOptions: IdValue[] = [
  {
    id: '1',
    value: 'Colored Point Cloud',
  },
  {
    id: '2',
    value: 'Grey-scale Point Cloud',
  },
  {
    id: '3',
    value: '360 Views',
  },
  {
    id: '4',
    value: 'Photogrammetry',
  },
  {
    id: '5',
    value: 'Survey Control',
  },
  {
    id: '7',
    value: 'Equipment and System Tags Data Capture',
  },
  {
    id: '8',
    value: OTHERLABEL,
  },
]

export const buildingAreasOptions: IdValue[] = [
  {
    id: '1',
    value: 'Interior',
  },
  {
    id: '2',
    value: 'Façade and immediate surroundings',
  },
  {
    id: '3',
    value: 'Roof',
  },
  {
    id: '4',
    value: 'Above ceiling space',
    placeholder: 'Specify ceiling type and height, if available',
    input: '',
    disabled: true,
  },
  {
    id: '5',
    value: 'Below raised floor',
  },
  {
    id: '6',
    value: 'Crawl space',
  },
  {
    id: '7',
    value: 'Site: topo, streets, parking spaces, curbs, manholes',
  },
  {
    id: '8',
    value: 'Underground utilities',
  },
  {
    id: '9',
    value: OTHERLABEL,
    placeholder: 'To continue you must write an option',
    input: '',
    disabled: true,
  },
]

export const DisciplinesInterestOptions: IdValue[] = [
  {
    id: '1',
    value: 'Architecture',
  },
  {
    id: '2',
    value: 'Structure',
  },
  {
    id: '3',
    value: 'Mechanical',
  },
  {
    id: '4',
    value: 'Electrical',
  },
  {
    id: '5',
    value: 'Plumbing',
  },
  {
    id: '6',
    value: 'Fire Protection',
  },
  {
    id: '7',
    value: 'Site and Topography',
  },
  {
    id: '8',
    value: OTHERLABEL,
  },
]

export const bimDeliverablesOptions : IdValue[] = [
  {
    id: '1',
    value: 'Scan to BIM model',
  },
  {
    id: '2',
    value: 'As-built Drawings',
  },
  {
    id: '3',
    value: 'System and Equipment Tagging in models',
  },
  {
    id: '4',
    value: OTHERLABEL,
  },
]

export const buildingConditionsOptions: IdValue[] = [
  {
    id: '1',
    value: 'Ocuppied',
  },
  {
    id: '2',
    value: 'Unoccupied',
  },
  {
    id: '3',
    value: 'Not in use (temporarily closed)/Abandoned/Empty',
  },
  {
    id: '4',
    value: 'Furniture is present',
  },
  {
    id: '5',
    value: 'Racking is present',
  },
  {
    id: '6',
    value: 'Full power',
  },
  {
    id: '7',
    value: 'No power',
  },
  {
    id: '8',
    value: 'Construction in progress',
  },
  {
    id: '9',
    value: 'Demolition in progress',
  },
  {
    id: '10',
    value: OTHERLABEL,
  },
]

export const yesNoOptions: IdValue[] = [
  {
    id: '1',
    value: 'Yes',
  },
  {
    id: '2',
    value: 'No',
  },
]

export const scanningDeliverablesSlider = [
  {
    id: '1',
    title: 'Laser Scanning Point Clouds',
    img: 'scanning/laser-scanning-point-clouds.jpg',
    description: `A point cloud is the output of the 3D scanning process, which consists of huge amounts of data points referenced in three dimensions (X, Y and Z) with a very high level of accuracy.
    Point Clouds can be greyscaled or colored, the right one for a project will depend on several factors such as scanning purpose, required model level of detail, and more. Nevertheless, color capture can only be completed in areas with adequate lighting.
    `,
  },
  {
    id: '2',
    title: '360 Views',
    img: 'scanning/views-360.jpg',
    description: `360-degree views are panoramic imagery captured during the scanning process. These images can complement the point clouds data with deep information such as materials and colors. The pictures can be hosted in a web-based environment, which allows users to take measurements, inspect coordinates and add markups. It also provides a single source of truth for all project stakeholders.
    `,
  },
  {
    id: '3',
    title: 'Photogrammetry',
    img: 'scanning/photogrammetry.jpg',
    description: `Photogrammetry is the process of stitching together photographs of a facility taken from different angles. Computer software is utilized to overlap picture to picture in order to align the photos appropriately. The result is a 3D point cloud that can create a polygonal mesh, just like 3D scanning. Though less accurate, the process is faster than conventional laser scanning.
    `,
  },
  {
    id: '4',
    title: 'Survey Control',
    img: 'scanning/survey-control.jpg',
    description: `Survey Control establishes horizontal and vertical relationships of known points of a project to serve as a base for a coordinate system. Complementing laser scanning with Survey Control is recommended for large projects to increase accuracy. This is particularly useful for site projects where several buildings have to be scanned and tied together in a shared coordinate system.
    `,
  },
  {
    id: '5',
    title: 'Equipment & System Tags Data Collection',
    img: 'scanning/equipment-y-system-tags-data-collection.jpg',
    description: `Having asset and system information such as serial numbers, manufacturer's name and contact, equipment models and more, is key for facilities maintenance and operations. During the survey, technicians can take pictures of equipment and system information that can be later documented and loaded into as-built BIM models.
    `,
  },
  {
    id: '6',
    title: '360 Tour',
    img: 'scanning/tour-360.jpg',
    description: `A virtual tour consists of several high-resolution photographs, called “spins”, brought together in a complete tour package. This high-quality user experience shows multiple locations throughout a site, switching views via thumbnails, enabling zoom in or out to enhance the scene they’re viewing. These can be very beneficial when engineering a project that is a distance away to avoid return trips to the site.
    `,
  },
  {
    id: '7',
    title: 'Ground Penetrating Radar',
    img: 'scanning/ground-penetrating-radar.jpg',
    description: `GPR is a geophysical and non-destructive method of surveying that uses radar pulses to image the subsurface. While scanning, underground utilities are traced and marked on the surface using markers, paint, flags, or other appropriate means. Deliverables can include 2D and 3D drawings of the findings (DWG and PDF formats) illustrating the service location and findings report with a summary of the job performed.
    `,
  },
]

export const bimDeliverablesSlider = [
  {
    id: '1',
    title: 'Scan to BIM Model',
    img: 'bim/scan-to-bim-model.jpg',
    description: `By tracing the point cloud data, ENG produces 3D as-built models that represent the exact conditions of the captured project. All scanned disciplines and building components can be modeled and at any required level of detail and accuracy. Also, custom and unique content can be created to represent unique project features.
    `,
  },
  {
    id: '2',
    title: 'As-Built Drawings',
    img: 'bim/as-built-drawings.jpg',
    description: `The most common deliverable for as-built documentation is the 2D drawings. The as-built drawings are created from the 3D model, which ensures accuracy. Depending on the project needs, the drawings can include floor plans, ceiling plans, structural plans, elevations, sections, 3D views, composite MEP plans, and more.
    `,
  },
  {
    id: '3',
    title: 'System and Equipment Tagging in models',
    img: 'bim/system-and-equipment-tagging-in-models.jpg',
    description: `This deliverable is known as Digital Twin. After capturing the systems and equipment tags information onsite, the data is loaded into the 3D model, which enables owners and operators immediate access to all building information remotely, easlily locating critical assets, replace equipment parts, schedule maintenance, and more.
    `,
  },
]

export const lodAdditionalDetails = [
  {
    id: '1',
    title: 'ARC/ST',
    subTitle: 'Architecture and Structure',
    img: [{ name: 'LOD 200', url: 'lod/stairs-lod200.png' }, { name: 'LOD 300', url: 'lod/stairs-lod300.png' }, { name: 'LOD 350', url: 'lod/stairs-lod350.png' }],
    description: 'Would you need to capture cornices <br/> and moldings or a high level of detail for railings and curtain walls? Open this catalog to learn more <br/> about <strong>ARC/STR LOD</strong>.',
  },
  {
    id: '2',
    title: 'MEPFP',
    subTitle: 'Mechanical, Electrical, Plumbing & Fire Protection',
    img: [{ name: 'LOD 200', url: 'lod/piping-lod200.png' }, { name: 'LOD 300', url: 'lod/piping-lod300.png' }, { name: 'LOD 350', url: 'lod/piping-lod350.png' }],
    description: 'Would you need to capture hangers and <br/> supports? What about pipe accessories? Open <br/> this catalog to learn more about <strong>MEPFP LOD</strong>.',
  },
]

const spaceBr = isMobile ? '' : '<br>'
export const facilityOptions: CardItemProps[] = [
  {
    image: '/images/facility/institutional.jpg',
    title: 'INSTITUTIONAL',
    description: `University, campus, schools, libraries, museums, ${spaceBr} banks, etc.`,
    id: '1',
  },
  {
    image: '/images/facility/retail.jpg',
    title: 'RETAIL',
    description: `Shopping, malls, galleries, commercial ${spaceBr} buildings, etc.`,
    id: '2',
  },
  {
    image: '/images/facility/residential.jpg',
    title: 'RESIDENTIAL & HOSPITALITY',
    description: `Single/multi-family houses, apartments, condominiums, townhouses, ${spaceBr} hotels, etc.`,
    id: '3',
  },
  {
    image: '/images/facility/office_space.jpg',
    title: 'OFFICE SPACE',
    description: `Traditional office spaces,${spaceBr} open offices, ${spaceBr} co-workings, etc.`,
    id: '4',
  },
  {
    image: '/images/facility/process_plants.jpg',
    title: 'PROCESS PLANTS & INDUSTRIAL',
    description: `Water treatment ${spaceBr} plants, oil and ${spaceBr} gas facilities, etc.`,
    id: '5',
  },
  {
    image: '/images/facility/parking.jpg',
    title: 'PARKING',
    description: `Parking lots,${spaceBr} garages, etc. `,
    id: '6',
  },
  {
    image: '/images/facility/transportation.jpg',
    title: 'TRANSPORTATION',
    description: `Bus/metro stations,${spaceBr} airport terminals,${spaceBr} BHS areas, runways,${spaceBr} control towers, etc.`,
    id: '7',
  },
  {
    image: '/images/facility/entertainment.jpg',
    title: 'ENTERTAINMENT',
    description: `Theaters, cinemas,${spaceBr} concert halls, etc.`,
    id: '8',
  },
  {
    image: '/images/facility/patrimonial.jpg',
    title: 'PATRIMONIAL',
    description: `Churches, historic ${spaceBr} buildings, etc.`,
    id: '9',
  },
  {
    image: '/images/facility/healthcare.jpg',
    title: 'HEALTHCARE',
    description: `Hospitals, clinics,${spaceBr} nursing homes, etc.`,
    id: '10',
  },
  {
    image: '/images/facility/warehouse.jpg',
    title: 'WAREHOUSE',
    description: `Datacenters, storage ${spaceBr} facilities, distribution warehouses, manufacturing ${spaceBr} plants, etc.`,
    id: '11',
  },
  {
    image: '/images/facility/others-img.png',
    title: OTHERSLABEL.toUpperCase(),
    description: '',
    id: '12',
  },
]
