import {
  BasicData,
  Credentials,
  Exterior,
  FacilityType,
  FinalStep,
  IdValue,
  LaserScanIntent,
  Levels,
  Roof,
  SiteExterior,
} from 'src/interfaces/store/quotation/interfaces'
import HttpService from './HttpService'

export default class QuotationService extends HttpService {
  initRFP (cred: Credentials) {
    return this.$axios.post('/quote', cred)
  }

  getKeys (id: string) {
    if (!id) return
    return this.$axios.get(id)
  }

  updateRFP (
    stepType: string,
    stepInfo:
      | BasicData
      | LaserScanIntent
      | FacilityType
      | IdValue
      | Levels
      | Roof
      | Exterior
      | SiteExterior
      | FinalStep,
    rfpId: string,
    title: string,
    comment: string,
  ) {
    return this.$axios.patch(`/quote/${rfpId}`, [
      {
        detailType: stepType,
        title,
        comments: comment,
        value: {
          ...stepInfo,
        },
      },
    ])
  }

  getRFP (rfpId: string | null) {
    return this.$axios.get(`/quote/${rfpId}`)
  }

  donwloadRFP (rfpId: string | undefined) {
    return this.$axios.get(`/quote/${rfpId}/csv`)
  }

  uploadFile (id: string | undefined, attachments: any) {
    const formData = new FormData()
    for (let i = 0; i < attachments!.length; i += 1) {
      formData.append('files', attachments!.item(i)!, attachments!.item(i)!.name)
    }
    return this.$axios.post(`/Quote/${id}/upload`, formData, {
      headers: {
        accept: 'application/json',
      },
    })
  }

  deleteFile (id:string) {
    return this.$axios.delete(`/Quote/files/${id}`, {
      headers: {
        accept: 'application/json',
      },
    })
  }
}
