import { devices } from 'src/utils'
import styled, { css } from 'styled-components'

const SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
}

const VARIANT = {
  default: 'default',
  dark: 'dark',
}

const BoxModal = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 9999;
`

const ContentModal = styled.div`
  background: #fff;
  border-radius: 1rem;
  left: 50%;
  max-width: 55rem;
  padding: .5rem;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  z-index: 99999;
  box-shadow: 0px 8.4px 16.8px rgba(86, 94, 107, 0.1),
    0px 4.2px 8.4px rgba(86, 94, 107, 0.1),
    0px 2.8px 2.8px rgba(86, 94, 107, 0.1),
    0px 1.4px 1.4px rgba(86, 94, 107, 0.1);
  
`

const ModalToolBar = styled.div`
  display: flex;
  justify-content: space-between;
  self-align: center;
`

const ModalHeader = styled.div`
  border-radius: 0.5rem 0.5rem 0px 0px;
  cursor: grab;
  display: flex;
  font-family: ${({ theme }) => theme.font.primary};
  font-weight: 700;
  justify-content: space-between;
  padding: 0.5rem;
`

const Text = styled.span`
  font-size: 1rem;
`

const ModalClose = styled.a`
  cursor: pointer;
  margin-right: .3rem;
  right: 0;
  top: 0;

  & img {
    margin: 0.75rem 1rem;
  }
`

const ModalBody = styled.div<{
  flexDirection?: string,
  justifyContent?: 'flex-start' | 'center' | 'flex-end',
}>`
  align-items: center;
  display: flex;
  font-family: ${({ theme }) => theme.font.primary};
  margin: 0;
  padding: 0rem 1rem;
  text-align: center;
  ${({ justifyContent }) => justifyContent ? css`justify-content: ${justifyContent};` : ''}
  ${({ flexDirection }) => flexDirection ? css`flex-direction: ${flexDirection};` : ''}
  @media ${devices.mobileS} and (max-width: 560px) {
    margin: 0;
    padding: 0;
  }
`

const ModalFooter = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: space-around;
  padding: 0rem 1rem 1rem;
`

export {
  BoxModal,
  ContentModal,
  ModalToolBar,
  ModalHeader,
  Text,
  ModalClose,
  ModalBody,
  ModalFooter,
  SIZES,
  VARIANT,
}
