import React from 'react'
import { ModalBody } from './style'

interface IBody {
    children: React.ReactNode,
    flexDirection?: string,
    justifyContent?: 'flex-start' | 'center' | 'flex-end',
}
export default function Body ({
  children,
  ...props
}: IBody) {
  return (
    <ModalBody {...props}>
        {children}
    </ModalBody>
  )
};
