import { devices } from 'src/utils'
import styled, { css } from 'styled-components'

interface SeparatorProps {
    size?: string;
    mobile?: boolean;
    line?: boolean;
}

const Separator = styled.span<SeparatorProps>`
    margin: 0;
    padding: 0;
    width: 100%;
    ${({ size = '30px' }) => css`height: ${size}`};
    ${({ line, theme }) => line
    ? css`
    @media ${devices.mobileS} and (max-width: 35rem) {
        border-bottom: 2px solid ${theme.palette.grey_light};
    }
    `
    : ''}

    ${({ mobile }) => mobile
    ? css`
        display: none;
        @media ${devices.mobileS} and (max-width: 35rem) {
            display: flex;
        }
    `
    : ''}
`

export default Separator
