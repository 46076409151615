import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { resetStore, setBasicDataAction } from 'src/state/quotation/actions'
import { selectBasicData } from 'src/state/quotation/selectors'
import BasicTemplate from 'src/components/templates/BasicTemplate'
import Button from 'src/components/atoms/Button'
import Panel from 'src/components/atoms/Panel'
import QuotationService from 'src/services/QuotationService'
import Separator from 'src/components/atoms/Separator'
import Stepper from 'src/components/organisms/Stepper'
import styled from 'styled-components'
import Typography from 'src/components/atoms/Typography'
import { isMobile, REACT_ENV } from 'src/utils/config'
import { Link, SideBar, TitleMobile } from 'src/shared/styles'
import Img from 'src/components/atoms/Image'
import Theme from 'src/Theme'
import { devices } from 'src/utils'
import packageJson from '../../package.json'

const ContainerButton = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: .5rem;
  justify-content: center;

  @media ${devices.mobileS} and (max-width: 560px){
    background-color: ${({ theme }) => theme.palette.white};
    border-radius: 1.875rem 1.875rem 0 0;
    height: 6rem;
    padding: 1rem 0;
    width: 100%;
  }
`

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-evenly;
  
  @media ${devices.mobileS} and (max-width: 560px){
    width:100%;
  }
`

const Mail = styled.a`
  color: ${Theme.palette.primary};
  font-family: ${Theme.font.primary};
  margin: 0 .5rem 0 0;
`
const Phone = styled.span`
  color: ${Theme.palette.primary};
  font-family: ${Theme.font.primary};
  margin: 0 0 0 .3rem;
`

const cssButtons = !isMobile ? { size: 'large', width: '16rem', borderRadius: '1rem' } : { size: 'small', width: '10rem', borderRadius: '.5rem' }

const ThankyouPage: React.FC = () => {
  const router = useNavigate()
  const dispatch = useDispatch()
  const { id, finalStep } = useSelector(selectBasicData)
  const email = String(sessionStorage.getItem('email'))

  const generateQuote = () => {
    new QuotationService({
      baseURL: REACT_ENV.REACT_APP_BASE_URL,
    }).initRFP({
      email,
    }).then((resp) => {
      sessionStorage.setItem('idLanding', resp.data.id)
      dispatch(setBasicDataAction({
        id: resp.data.id,
      }))
      dispatch(resetStore())
      router('/quote/1')
    })
  }

  const goToQuote = () => {
    localStorage.removeItem('stepsCompleted')
    sessionStorage.removeItem('REACT_APP_LOCATION')
    generateQuote()
  }

  const backToHome = () => {
    sessionStorage.removeItem('REACT_APP_LOCATION')
  }

  useEffect(() => {
    if (finalStep) return
    new QuotationService({
      baseURL: REACT_ENV.REACT_APP_BASE_URL,
    }).donwloadRFP(sessionStorage.getItem('idLanding') ?? id)
    dispatch(setBasicDataAction({
      finalStep: true,
    }))
  }, [])

  return (
    <BasicTemplate onHeader={'Thank you!'}>
      <Stepper currentStep={6} >
        <SideBar>
          <Img
            src={`${REACT_ENV.PUBLIC_URL}/images/vertical-sidebar.png`}
            width='100%'
          />
          <Typography
            color={Theme.palette.white}
            fontSize='1rem'
            variant='versionLabel'
          >
            v{packageJson.version}
          </Typography>
        </SideBar>
        <Container>
          <TitleMobile>
            Thank you!
          </TitleMobile>
          <Panel
            color={Theme.palette.white}
            align='center'
            direction='column'
            margin='9.625rem 10rem 1rem'
            padding='2rem'
            width='50rem'
            borderColor={Theme.palette.lightGrey}
            borderRadius={10}
            thankYouPage
          >
            <Separator size='1rem' />
            <Typography variant='h1' fontSize="1.5rem" align="center">
              Your scan is on its way!
              <span style={{ padding: '0 0 0 1rem' }}>
                <Img
                  width='20px'
                  src={`${REACT_ENV.PUBLIC_URL}/images/flag.svg`}
                  alt=""
                />
              </span>
            </Typography>
            <Separator line size='1rem' />
            <Typography variant='h3' justifyContent='center' align="center" fontSize='1rem' style={{ margin: '1rem 0 0 0' }}>
              We received your request and will connect you soon with the <br />
              nearest, best pre-qualified laser scanning provider.
            </Typography>
            <Separator line />
            <Typography variant="p" align='center' fontSize='1rem'>
              A member of our team will contact you shortly. <br />
              For any questions you can email us at <Mail href="mailto:sirvey@engbim.com">sirvey@engbim.com</Mail>
              or call us at <br />
              <Phone>(562) 955 2491</Phone> or
              <Phone>(332) 245 3815</Phone>.
            </Typography>
            <Separator line size='1rem' />
            <Typography variant='p' color={Theme.palette.primary}>
              The SirVey Team!
            </Typography>
          </Panel>
          <ContainerButton>
            <Button
              {...cssButtons}
              align='center'
              onClick={goToQuote}
              uppercase
              bold
            >
              New Request
            </Button>
            <Link href={REACT_ENV.REACT_APP_LANDING} onClick={backToHome}>
              Back to Home
            </Link>
          </ContainerButton>
        </Container>
      </Stepper>
    </BasicTemplate>
  )
}

export default ThankyouPage
