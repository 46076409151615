import React from 'react'
import styled from 'styled-components'
import Typography from 'src/components/atoms/Typography'
import { Attachment, InputFileProps } from './types'
import { devices } from 'src/utils'
import { allowedExtensions } from 'src/utils/formatHelper'
import Theme from 'src/Theme'
import { isMobile, REACT_ENV } from 'src/utils/config'

const Wrapper = styled.section`
  align-items: center;
  background-color: ${Theme.palette.borderInput};
  border-radius: .5rem;
  border: 1px solid ${Theme.palette.lightGrey};
  display: flex;
  flex-wrap: wrap;
  gap: 1.25rem;
  justify-content: space-between;
  margin: 0 0 0.5rem 0;
  padding: 0.75rem 0.75rem;
  position: relative;
  width: 100%;

  @media ${devices.mobileS} and (max-width: 35rem) {
    background-color:${Theme.palette.primary} ;
  }
  
`
const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items:center;
`
const InputTitle = styled(Typography)`
  color:${Theme.palette.primary} !important;
  font-weight: 500;
  margin: 0 0 0 1rem;
  padding: .3rem 0 0 0;
  @media ${devices.mobileS} and (max-width: 35rem) {
    color:${Theme.palette.white} !important;
  }
`
const InputFileUpload = styled.input`
  height: 4rem;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  width: 100%;
`
const ImgPlusWhite = styled.img`
  margin:0 1rem 0 1rem;
`

const PillContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.625rem;
`
const FilePill = styled.span`
  background-color: ${({ theme }) => theme.palette.grey};
  padding: 0.75rem 0.5rem;

  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  max-width: 100%;
  font-family: ${Theme.font.primary};

  @media ${devices.mobileS} and (max-width: 560px){ 
    max-width: 15.625rem;
  }
`
const TextPill = styled.span`
  display: inline-block;
  max-width: 10rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`
const CloseButton = styled.span`
  display: inline-flex;
  height: 20px;
  width: 20px;

  margin-left: 11px;
  padding: 5px;

  align-items: center;
  font-size: 1.875rem;
  justify-content: center;
  line-height: 1.25rem;

  cursor: pointer;
`

const InputFile: React.FC<InputFileProps> = ({ onChange, value, onRemove, multiple, loadingFiles }: InputFileProps) => {
  const onChangeFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files }: any = event.target
    let filesExced = false
    let filesInvalid = false
    Array.from(files).map((file: any) => {
      if (file.size > 83886080) filesExced = true
      if (!allowedExtensions.exec(file.name)) filesInvalid = true
      return file
    })
    filesExced && alert('The File is too large')
    filesInvalid && alert('The File is not valid!')
    const validOnChange = !filesExced && !filesInvalid && onChange && files?.length
    validOnChange && onChange(files)
  }

  const onClickPill = async (item: Attachment) => {
    onRemove && onRemove(item)
  }

  return (
    <Wrapper>
      <InputContainer>
        <InputFileUpload
          type='file'
          name=''
          className='file'
          multiple={multiple}
          onChange={onChangeFiles}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between', alignSelf: 'center', width: '100%', alignItems: 'center' }}>
          <img src={`${REACT_ENV.PUBLIC_URL}/images/${isMobile ? 'upload-white.png' : 'uploadFile.png'}`} alt='upload img' width="18px" height="21px" />
          <InputTitle variant='label' color='textSecondary'>
            {loadingFiles ? 'Loading...' : 'Upload drawing and/or pictures'}
          </InputTitle>
          <ImgPlusWhite src={`${REACT_ENV.PUBLIC_URL}/images/${isMobile ? 'plus-icon-white' : 'plus-icon'}.png`} />
        </div>
      </InputContainer>
      {value?.length
        ? <PillContainer>{Array.from(value).map((file, i) => (
          <FilePill key={`PILL_${i}`} title={file.name}>
            <TextPill>{file.name}</TextPill>
            <CloseButton onClick={() => onClickPill(file)}>&times;</CloseButton>
          </FilePill>
        ))} </PillContainer>
        : null
      }
    </Wrapper>
  )
}

export default InputFile
