import * as types from './types'
import { forwardPayload, noPayload } from 'src/utils/storeHelper'
import { Accessibility, AreasScanned, BasicData, Credentials, Email, FinalStep, IdValue, LevelInfo, ScopeOfWork, SiteExterior, stepCompleted, StepUpdateProps, SwapPriorities } from 'src/interfaces/store/quotation/interfaces'
import { ScopeSelectorOption } from 'src/components/organisms/ScopeSelector/types'
import { FileItem } from 'src/components/molecules/InputFIle/types'

export const initRFPAction = forwardPayload<Credentials>(types.INIT_RFP_ACTION)
export const initRFPActionCompleted = forwardPayload<Credentials>(types.INIT_RFP_ACTION_COMPLETED)
export const initRFPActionFailed = forwardPayload<Error>(types.INIT_RFP_ACTION_FAILED)

export const setEmailAction = forwardPayload<Email>(types.SET_EMAIL)

export const donwloadRFPAction = forwardPayload<string | undefined>(types.DONWLOAD_RFP_ACTION)

export const updateRFPAction = forwardPayload<StepUpdateProps>(types.UPDATE_RFP_ACTION)
export const updateRFPActionCompleted = noPayload(types.UPDATE_RFP_ACTION_COMPLETED)
export const updateRFPActionFailed = forwardPayload<Error>(types.UPDATE_RFP_ACTION_FAILED)

export const resetStore = noPayload(types.RESET_STORE)

export const setBasicDataAction = forwardPayload<BasicData>(types.SET_BASIC_DATA_ACTION)

export const nextStepAction = noPayload(types.NEXT_STEP_ACTION)

export const setScopeOfWork = forwardPayload<ScopeOfWork>(types.SET_SCOPE_OF_WORK_ACTION)

export const setAreasScanned = forwardPayload<AreasScanned>(types.SET_AREAS_TO_BE_SCANNED_ACTION)

export const swapPrioritiesAction = forwardPayload<SwapPriorities>(types.SWAP_PRIORITIES_ACTION)

export const setScopeAction = forwardPayload<ScopeSelectorOption>(types.SET_SCOPE_ACTION)

export const setFacilityTypeAction = forwardPayload<IdValue>(types.SET_FACILITY_TYPE_ACTION)

export const setScanZoneAction = forwardPayload<IdValue>(types.SET_SCAN_ZONE_ACTION)

export const setLevelAction = forwardPayload<IdValue>(types.SET_LEVEL_ACTION)
export const setLevelInfoAction = forwardPayload<LevelInfo>(types.SET_LEVEL_INFO_ACTION)
export const setCeilingTypeAction = forwardPayload<LevelInfo>(types.SET_CEILING_TYPE_ACTION)
export const setTilesRemovedAction = forwardPayload<IdValue | null>(types.SET_TILES_REMOVED_ACTION)
export const setSpecialRequirementAction = forwardPayload<string>(types.SET_SPECIAL_REQUIREMENT_ACTION)

export const setAccessibilityAction = forwardPayload<Accessibility>(types.SET_ACCESSIBILITY_ACTION)

export const setRoofAccesibleAction = forwardPayload<IdValue | null>(types.SET_ROOF_ACCESIBLE_ACTION)
export const setHowCanAccessAction = forwardPayload<string>(types.SET_HOW_ACCESS_ACTION)
export const setIsUsedAsTerraceAction = forwardPayload<IdValue | null>(types.SET_IS_TERRACE_ACTION)
export const setCanDroneFlyAction = forwardPayload<IdValue | null>(types.SET_CAN_DRONE_FLY_ACTION)
export const setSQTFAction = forwardPayload<string | null>(types.SET_SQTF_ACTION)

export const setLinearFeetAction = forwardPayload<string>(types.SET_LINEAR_FEET_ACTION)
export const setFacadeHeightAction = forwardPayload<string>(types.SET_FACADE_HEIGHT_ACTION)
export const setPictureAction = forwardPayload<FileItem | null>(types.SET_PICTURE_ACTION)

export const setFinalStepAction = forwardPayload<FinalStep>(types.SET_FINAL_STEP_ACTION)

export const setSiteExteriorAction = forwardPayload<SiteExterior>(types.SET_SITE_EXTERIOR_ACTION)

export const setStepsCompletedAction = forwardPayload<stepCompleted | null>(types.SET_STEPS_COMPLETED_ACTION)

export const setCurrentStepdAction = forwardPayload<{ currentStep: number }>(types.SET_CURRENT_STEP_ACTION)

export const finishQuote = noPayload(types.FINISH_QUOTE)
export const emptyState = noPayload(types.EMPTY_STATE)
