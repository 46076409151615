/* eslint-disable no-unused-vars */
export enum StoreStatus {
    NOT_STARTED='NOT_STARTED',
    START_QUOTE='START_QUOTE',
    QUOTE_IN_PROGRESS='QUOTE_IN_PROGRESS',
    LOADING='LOADING',
    LOADED='LOADED',
    END_QUOTATION='END_QUOTATION'
}

export enum EModalType {
    LOD = 'lod',
    BIM = 'bim',
    SCANNING = 'scanning'
}
