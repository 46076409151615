import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

export default abstract class HttpService {
  headers: { [key: string]: string }
  $axios: AxiosInstance

  constructor (config: AxiosRequestConfig) {
    this.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    this.$axios = Axios.create({
      ...config,
      headers: {
        ...config.headers,
        ...this.headers,
      },
    })
  }
}
