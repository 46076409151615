import { devices } from 'src/utils'
import styled from 'styled-components'
import Theme from 'src/Theme'

export const Wrapper = styled.section<any>`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  position: relative;

  padding: 1.4rem 2.6rem;

  height: 3.12rem;
  width: 100%;

  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  cursor: pointer;

  @media ${devices.mobileS} and (max-width: 560px){
    padding:5px;
  }

  & .text-thin {
    font-weight: 400;
    margin-left: 1.8rem;

    @media ${devices.mobileS} and (max-width: 560px){ 
      font-size: 1rem;
    }
  }

  &.disabled {
    background: ${({ theme }) => theme.palette.primaryDisabled};
    pointer-events: none;
  }
`

export const DatePickerModal = styled.div`
  align-items: center;
  background-color: rgba(0,0,0,.3);
  bottom:0;
  display: flex;
  height: 100vh;
  justify-content: center;

  left:0;
  position:fixed;
  right:0;
  top:0;
  width: 100%;
  z-index: 3;
`

export const DatePickerContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 21rem;
  pointer-events: all;
  position: relative;
  width: 100%;
  z-index: 2;

  @media ${devices.mobileS} and (max-width: 560px){ 
    max-width: 100%;
  }
`

export const DatePickerHeader = styled.div`
  -moz-border-radius-topleft: .5rem;
  -moz-border-radius-topright: .5rem;
  -webkit-border-top-left-radius: .5rem;
  -webkit-border-top-right-radius: .5rem;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.white};
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
  display: flex;
  height: 8rem;
  justify-content: center;
  position: relative;
  width: 100%;

  & .to-separator {
    font-weight: 400;
    margin-bottom: 10px;
  }
`

export const DatePickerBody = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.white};
  display: flex;
  flex-direction:column;
  justify-content: center;
  min-height: 18rem;
  width: 100%;

  & .react-datepicker {
    border: 0;
    &__navigation {
      top: 8px;
    }
    &__header {
      background-color: transparent;
      border-bottom-color: transparent;
      padding: 1rem 0 0;
    }
    &__current-month {
      color: ${Theme.palette.calendar};
      font-size: 1.2rem;
      font-weight: 700;
      padding: 0 0 1rem;
      margin-bottom: 1rem;
      border-bottom: 1px solid ${Theme.palette.lightGrey};
    }
    &__day-name {
      align-items: center;
      color: ${({ theme }) => theme.palette.calendarDay};
      display: inline-flex;
      font-size: 1rem;
      font-weight: 600;
      height: 2rem;
      justify-content: center;
      width: 2rem;
    }

    &__month-container {
      height: auto;
    }

    &__week {
      display: flex;
    }

    &__day {
      align-items: center;
      border-radius: 50%;
      color: ${Theme.palette.calendar};
      display: inline-flex;
      font-size: 1rem;
      font-weight: 600;
      height: 2rem;
      justify-content: center;
      width: 2rem;
    }

    &__day--selected:not(.react-datepicker__day--outside-month:empty),
    &__day--in-range.react-datepicker__day--selected:not(.react-datepicker__day--outside-month:empty) {
      background-color: ${({ theme }) => theme.palette.primary};
      border-radius: 50%;
      color: white;
    }

    &__day--in-selecting-range.react-datepicker__day--range-start:not(.react-datepicker__day--outside-month:empty) {
      border-radius: 50% 0 0 50%;
    }

    &__day--in-range {
      background-color: ${({ theme }) => theme.palette.dateInRange};
      color: inherit;
    }

    &__day--in-range.react-datepicker__day--range-start:not(.react-datepicker__day--outside-month:empty) {
      background-color: ${({ theme }) => theme.palette.primary};
      border-radius: 50% 0 0 50%;
      color: white;
    }

    &__day--in-range.react-datepicker__day--range-end:not(.react-datepicker__day--outside-month:empty) {
      background-color: ${({ theme }) => theme.palette.primary};
      border-radius: 0 50% 50% 0;
      color: white;
    }

    &__day--in-range.react-datepicker__day--range-start.react-datepicker__day--outside-month:empty {
      border-radius: 50% 0 0 50%;
    }

    &__day--in-range.react-datepicker__day--range-end.react-datepicker__day--outside-month:empty {
      border-radius: 0 50% 50% 0;
    }

    &__day--in-selecting-range {
      background-color: ${({ theme }) => theme.palette.dateInRange};
      color: inherit;
    }

    &__day--in-selecting-range.react-datepicker__day--selecting-range-start {
      background-color: ${({ theme }) => theme.palette.dateInRange};
      border-radius: 50% 0 0 50%;
      width: 2rem;
    }

    &__day--in-selecting-range:hover {
      border-radius: 0 50% 50% 0;
    }

    &__day--in-range,
    &__day--in-selecting-range {
      border-radius: 0;
      box-sizing: border-box;
      margin-left: 0;
      margin-right: 0;
      width: calc(2rem + 0.166rem * 2);
    }

    &__day--in-range:last-child,
    &__day--in-selecting-range:last-child {
      border-radius: 0px 50% 50% 0px;
    }

    &__day--in-range:first-child,
    &__day--in-selecting-range:first-child {
      border-radius: 50% 0 0 50%;
    }

    &__day--today {
      font-weight: 600;
      position: relative;
    }
    &__day--today:before {
      background-color: transparent;
      border-radius: 50%;
      content: '';
      display: flex;
      height: 2rem;
      pointer-events: none;
      position: absolute;
      width: 2rem;
    }
  }
`

export const CloseBtn = styled.span`
  align-items: center;
  background-color: ${Theme.palette.close};
  border-radius: 50%;
  color: white;
  cursor:pointer;
  display: flex;
  font-size: 1.25rem;
  font-weight: 300;
  height: 1rem;
  justify-content: center;
  padding: .1rem 0 0;
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 1rem;
  z-index: 2;
`

export const DatePickerActions = styled.div`
  -moz-border-radius-bottomleft: .5rem;
  -moz-border-radius-bottomright: .5rem;
  -webkit-border-bottom-left-radius: .5rem;
  -webkit-border-bottom-right-radius: .5rem;
  background-color: ${({ theme }) => theme.palette.white};
  border-bottom-left-radius: .5rem;
  border-bottom-right-radius: .5rem;
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;

  & .text-only {
    appearance: none;
    background-color: transparent;
    color: ${({ theme }) => theme.palette.primary};
    font-size: 1.5rem;
    margin-right: .6rem;
    min-width: 6.8rem;
  }
  
  & .text-only:hover {
    background-color: transparent;
    color: ${({ theme }) => theme.palette.primaryHover};
  }
`
