import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Separator from 'src/components/atoms/Separator'
import Typography from 'src/components/atoms/Typography'
import { CardItemProps } from 'src/components/molecules/CardItem/types'
import FacilityTypeSelector from 'src/components/organisms/FacilityTypeSelector/index'
import {
  nextStepAction,
  setCurrentStepdAction,
  setScopeOfWork,
  setStepsCompletedAction,
  updateRFPAction,
} from 'src/state/quotation/actions'
import { capitalize } from 'lodash'
import { selectBasicData, selectCurrentStep, selectScopeOfWork } from 'src/state/quotation/selectors'
import { useNavigate } from 'react-router'
import styled from 'styled-components'
import Guard from '../Guard'
import { isExist } from 'src/utils/validationsHelper'
import FooterButtons from '../../molecules/FooterButtons'
import Theme from 'src/Theme'
import useRefresh from 'src/hook/useQuery/useRefresh'
import { facilityOptions } from 'src/shared/Data'
import { BgMobile, ContainerMobile, GroupError, TextMobile, TitleMobile } from 'src/shared/styles'
import { ERRORLABEL_TYPE_BUILDING, REACT_ENV } from 'src/utils/config'
import Img from 'src/components/atoms/Image'

const Wrapper = styled.div`
  background-color: ${Theme.palette.borderInput};
  border-radius: .5rem;
  border: 1px solid ${Theme.palette.lightGrey};
  display: flex;
  flex-direction: column;
  padding: 0 0 2rem;
  width: 100%;
`

const Step2 = () => {
  const dispatch = useDispatch()
  const router = useNavigate()
  const currentStep = useSelector(selectCurrentStep)
  const { reload, setReload, data } = useRefresh('ScopeOfWork')
  const [buildings, setBuildings] = useState<CardItemProps[]>([])
  const [cardBuildingDisabled, setCardBuildingDisabled] = useState<boolean>(true)
  const [otherBuildingCardPurpose, setCardBuildingPurpose] = useState<string>('')
  const [required, setRequired] = useState<boolean>(false)
  const { typeOfBuildings, otherBuilding } = useSelector(selectScopeOfWork)
  const id = useSelector(selectBasicData).id

  const onChangeFacilityType = (val: CardItemProps) => {
    if (isExist(buildings, val.id).length) {
      const removeOption = buildings.filter((opt) => opt.id !== val.id)
      setBuildings(removeOption)
    } else {
      setBuildings([
        ...buildings,
        {
          id: val!.id,
          title: capitalize(val!.title),
          image: val!.image,
          description: val!.description,
        },
      ])
    }
  }

  const gotoNextStep = () => {
    if (!buildings.length || (!cardBuildingDisabled && !otherBuildingCardPurpose)) {
      setRequired(true)
      return
    }
    const updData = {
      otherBuilding: otherBuildingCardPurpose,
      typeOfBuildings: buildings,
    }
    dispatch(
      updateRFPAction({
        id: sessionStorage.getItem('idLanding') ?? id!,
        title: 'Scope of work',
        comment: 'Scope of work',
        data: {
          ...updData,
        },
        stepType: 'ScopeOfWork',
      }),
    )
    dispatch(setScopeOfWork({
      ...updData,
    }))
    dispatch(nextStepAction())
    dispatch(setStepsCompletedAction({ id: 3 }))
    router('/quote/3')
  }

  function goToBack () {
    dispatch(setCurrentStepdAction({
      currentStep: Number(currentStep) - 1,
    }))
    router('/quote/1')
  }

  function setInfoStep (data:any) {
    const {
      typeOfBuildings,
      otherBuilding,
    } = data

    typeOfBuildings && setBuildings(typeOfBuildings)
    otherBuilding && setCardBuildingPurpose(otherBuilding)
    otherBuilding && setCardBuildingDisabled(false)
  }

  useEffect(() => {
    setInfoStep({
      typeOfBuildings,
      otherBuilding,
    })
  }, [])

  useEffect(() => {
    if (!reload) return
    dispatch(setStepsCompletedAction({ id: 1 }))
    // eslint-disable-next-line no-prototype-builtins
    if (!data.hasOwnProperty('typeOfBuildings')) return
    setInfoStep(data)
    dispatch(setCurrentStepdAction({
      currentStep: 2,
    }))
    setReload(false)
  }, [reload, data])

  return (
    <Guard>
        <TitleMobile>Two steps to go!</TitleMobile>
        <Separator size='1rem' />
        <TextMobile>Only fields marked with an askerisk (*) are required</TextMobile>
      <Separator size='20px' />
      <Wrapper>
        <Typography variant='label' fontSize="1rem" style={{ padding: '1rem 0 0 1rem' }}>
          Type of Building (select one or more)*
        </Typography>
        <Separator size='20px' />
        <FacilityTypeSelector
          options={facilityOptions}
          values={buildings}
          onChange={onChangeFacilityType}
          disabled={cardBuildingDisabled}
          setDisabled={setCardBuildingDisabled}
          onChangeOther={(val: string) => setCardBuildingPurpose(val)}
          placeholder={(!cardBuildingDisabled && !otherBuildingCardPurpose) ? 'To continue you must write an option' : ''}
          other={otherBuildingCardPurpose}
          required={required}
        />
      </Wrapper>
      <Separator size='20px' />
      {required &&
        <GroupError>
          <Img
            alt="icon-error"
            src={`${REACT_ENV.PUBLIC_URL}/images/icon-error.png`}
            width='1.25rem'
          />
          <Typography variant='span' color={Theme.palette.errorBorder} bold>
            {ERRORLABEL_TYPE_BUILDING}
          </Typography>
        </GroupError>
      }
      <Separator size="5.5rem" mobile/>
      <ContainerMobile>
        <FooterButtons
          gotoNextStep={gotoNextStep}
          goBack={goToBack}
          label='Continue'
          currentStep={currentStep}
        />
        <BgMobile />
      </ContainerMobile>
      <Separator size='20px' />
    </Guard>
  )
}

export default Step2
