import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import Panel from 'src/components/atoms/Panel'
import Header from 'src/components/organisms/Header'
import useQuery from 'src/hook/useQuery'

interface TemplateProps {
  children: any,
  onHeader?: string,
}

const BasicTemplate: React.FC<TemplateProps> = ({
  children,
  onHeader,
}: TemplateProps) => {
  const query = useQuery()

  if (query.get('data')) return <Navigate to="/" />

  const callBeforeunload = (evento: any) => {
    evento.preventDefault()
    evento.returnValue = ''
    sessionStorage.setItem('refresh', 'true')
    return ''
  }
  useEffect(() => {
    if (onHeader?.includes('Thank you')) {
      window.removeEventListener('beforeunload', callBeforeunload)
      return
    }
    window.addEventListener('beforeunload', callBeforeunload)
    return () => {
      window.removeEventListener('beforeunload', callBeforeunload)
    }
  }, [])

  return (
    <Panel
      color='#F0F0F0'
      align='center'
      justify='flex-start'
      direction='column'
      width='100%'
      height="100%">
      <Header onHeader={onHeader} />
      {children}
    </Panel>
  )
}

export default BasicTemplate
