import React, { useState, createContext } from 'react'
import SliderModal from 'src/components/organisms/Modals/SliderModal'

export interface IModalContext {
  setModal: React.Dispatch<any>,
  setInfo: React.Dispatch<any>,
  setTypeModal: React.Dispatch<any>,
  setModalTicket: React.Dispatch<any>,
  modalTicket: any
}

const InitialState = {
  setModal: () => { },
  setInfo: () => { },
  setTypeModal: () => { },
  setModalTicket: () => { },
  modalTicket: [],
}

export const ModalContext = createContext<IModalContext>(InitialState)

type IModal = {
  children: any
}
const ModalProvider = ({
  children,
  ...props
}: IModal) => {
  const [modal, setModal] = useState<boolean>(false)
  const [typeModal, setTypeModal] = useState<string>('slider')
  const [info, setInfo] = useState<string>('')
  const [modalTicket, setModalTicket] = useState<[]>([])
  const modals: any = {
    slider: <SliderModal modal={modal} setModal={setModal} info={info} />,
  }

  return (
    <ModalContext.Provider value={{ setModal, setInfo, setTypeModal, setModalTicket, modalTicket }} {...props} >
      {children}
      {modal && modals[typeModal]}
    </ModalContext.Provider>
  )
}

export default ModalProvider
