import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import Separator from 'src/components/atoms/Separator'
import TextArea from 'src/components/atoms/TextArea'
import Typography from 'src/components/atoms/Typography'
import MultipleSelect from 'src/components/molecules/MultipleSelect'
import { IdValue } from 'src/interfaces/store/quotation/interfaces'
import {
  setAccessibilityAction,
  setCurrentStepdAction,
  setSpecialRequirementAction,
  setStepsCompletedAction,
  updateRFPAction,
} from 'src/state/quotation/actions'
import {
  selectAccessibility,
  selectBasicData,
  selectCurrentStep,
  selectSpecialRequirement,
} from 'src/state/quotation/selectors'
import { DateFormat } from 'src/utils/formatHelper'
import { isExist } from 'src/utils/validationsHelper'
import DateRangePicker from '../DateRangePicker'
import DatePicker from '../DatePicker'
import Guard from '../Guard'
import { buildingAreasOptions, buildingConditionsOptions } from 'src/shared/Data'
import FooterButtons from '../../molecules/FooterButtons'
import { BgMobile, ContainerMobile, Grid, GridItem, TitleMobile, Wrapper } from 'src/shared/styles'
import { MAX_CHARACTERS_TEXTAREA, OTHERLABEL } from 'src/utils/config'
import { DateButton } from '../DateRangePicker/styles'
import useRefresh from 'src/hook/useQuery/useRefresh'

const Step4: React.FC = () => {
  const dispatch = useDispatch()
  const router = useNavigate()
  const { reload, setReload, data } = useRefresh('Accessibility')
  const currentStep = useSelector(selectCurrentStep)
  const specialRequirement = useSelector(selectSpecialRequirement)
  const id = useSelector(selectBasicData).id

  const [conditions, setConditions] = useState<IdValue[]>([])
  const [otherBuildingConditionsPurpose, setOtherBuildingConditionsPurpose] = useState<string>('')
  const [comments, setComments] = useState<string>('')
  const [disableConditions, setDisabledConditions] = useState<boolean>(true)
  const { buildingConditions, otherBC, startQuotationDate, additionalComment } = useSelector(selectAccessibility)
  const [tentativeDate, setTentativeDate] = useState<any>({
    startDate: null,
    endDate: null,
  })
  const [dueDateClient, setDueDateClient] = useState<any>(null)
  const [showDatePicker, setShowDate] = useState<boolean>(false)
  const [showDateRangePicker, setShowRangeDate] = useState<boolean>(false)
  const [required, setRequired] = useState<boolean>(false)

  const onChangeConditions = (val: IdValue) => {
    if (isExist(conditions, val.id).length) {
      const removeOption = conditions.filter((opt) => opt.id !== val.id)
      setConditions(removeOption)
      val.id === OTHERLABEL && setOtherBuildingConditionsPurpose('')
    } else {
      setConditions([...conditions, val])
      if (val.id === '10') {
        const otherVal = val.value.split(':')
        otherVal.length > 1 && setOtherBuildingConditionsPurpose(otherVal[1].trim())
      }
    }
  }

  const nextStep = () => {
    const updData = {
      additionalComment: comments,
      buildingConditions: conditions,
      otherBC: otherBuildingConditionsPurpose,
    }
    dispatch(updateRFPAction({
      id: sessionStorage.getItem('idLanding') ?? id!,
      title: 'Accessibility',
      comment: 'Building Conditions / Special Requirements / Date of Quote',
      data: {
        ...updData,
        specialRequirement,
        dueDateClient: dueDateClient ? format(dueDateClient, DateFormat) : null,
        endDate: null,
        tentativeDate: {
          startDate: tentativeDate.startDate ? format(tentativeDate.startDate, DateFormat) : null,
          endDate: tentativeDate.endDate ? format(tentativeDate.endDate, DateFormat) : null,
        },
      },
      stepType: 'Accessibility',
    }))
    dispatch(setAccessibilityAction({
      ...updData,
      startQuotationDate: dueDateClient,
      endQuotationDate: null,
      tentativeDate: tentativeDate,
    }))
    dispatch(setStepsCompletedAction({ id: 5 }))
    buildingAreasOptions.map((building: any) => {
      if (Object.prototype.hasOwnProperty.call(building, 'input')) building.input = ''
      return building
    })
    router('/thankyou')
  }

  const onChangeSpecialReq = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(setSpecialRequirementAction(evt.target.value))
  }

  const onChangeComments = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComments(evt.target.value)
  }

  const onChangeTentativeDate = (dates: any) => {
    setTentativeDate(dates)
  }

  const onChangeDate = (dates: Date | null) => {
    setDueDateClient(dates)
  }

  function goToBack () {
    dispatch(setAccessibilityAction({
      buildingConditions: conditions,
      otherBC: otherBuildingConditionsPurpose,
      startQuotationDate: dueDateClient,
      endQuotationDate: null,
      additionalComment: comments,
      tentativeDate: tentativeDate,
    }))
    dispatch(setCurrentStepdAction({
      currentStep: Number(currentStep) - 1,
    }))
    router('/quote/3')
  }

  function setInfoStep (data:any) {
    const {
      additionalComment,
      buildingConditions,
      otherBC,
      startQuotationDate,
    } = data
    buildingConditions && setConditions(buildingConditions)
    additionalComment && setComments(additionalComment)
    otherBC && setOtherBuildingConditionsPurpose(otherBC)
    otherBC && setDisabledConditions(false)
    startQuotationDate && setDueDateClient(startQuotationDate)
  }

  useEffect(() => {
    setInfoStep({
      additionalComment,
      buildingConditions,
      otherBC,
      startQuotationDate,
    })
  }, [])

  useEffect(() => {}, [showDateRangePicker])

  useEffect(() => {
    if (!reload) return
    [1, 2, 3].map((step) => dispatch(setStepsCompletedAction({ id: step })))
    // eslint-disable-next-line no-prototype-builtins
    if (!data.hasOwnProperty('buildingConditions')) return
    setInfoStep(data)
    dispatch(setCurrentStepdAction({
      currentStep: 4,
    }))
    setReload(false)
  }, [reload, data])
  return (
    <Guard>
      <TitleMobile>Almost there!</TitleMobile>
      <Grid>
        <GridItem>
          <Wrapper style={{ padding: '0 0 .5rem' }}>
            <Typography
              variant='label'
              fontSize='1rem'
              style={{ padding: '1rem 0 0 1rem' }}>
              Building Conditions (select one or more)
            </Typography>
            <Separator size=".62rem" />
            <MultipleSelect
              options={buildingConditionsOptions}
              values={conditions}
              onChange={onChangeConditions}
              onChangeOther={(val: string) => setOtherBuildingConditionsPurpose(val)}
              other={otherBuildingConditionsPurpose}
              direction={'row'}
              disabled={disableConditions}
              setDisabled={setDisabledConditions}
              placeholder={'To continue you must write an option'}
              required={required}
              sectionName='building-condition'
            />
          </Wrapper>
        </GridItem>
        <GridItem>
          <TextArea
            fontSize='1rem'
            height="7.5rem"
            label='Access Requirements and Site Constraints'
            maxlength={MAX_CHARACTERS_TEXTAREA}
            onInput={onChangeSpecialReq}
            placeholder='Indicate specific requirements like: if ladder or scissor lift is required to access to certain spaces, roof accessibility details, if after-hour work is required or allowed, etc.'
            value={specialRequirement ?? ''}
            id="requirements-contraints"
          />
          <Wrapper>
            <Typography
              variant='label'
              fontSize='1rem'
              style={{ margin: '0 0', padding: '1rem 0 0 1rem' }}>
              Scanning Tentative Date
            </Typography>
            <Typography
              variant='p'
              color='black'
              fontSize=".8rem"
              style={{ margin: '0 0', padding: '.5rem 0 0 1rem' }}>
              Select a day, week or month if actual scanning date is not confirmed
            </Typography>
            <Separator size='.62rem' />
            <Grid template='30% 65%' align='center' gap="0" justify='center' templateMobile='100%'>
              <GridItem align='center'>
                <DateButton onClick={() => setShowRangeDate(true)} data-test="btn-select-date">
                    SELECT DATE
                </DateButton>
              </GridItem>
              <GridItem align='center'>
                <DateRangePicker
                  value={[tentativeDate.startDate, tentativeDate.endDate]}
                  onChange={onChangeTentativeDate}
                  handleDate={{ showDate: showDateRangePicker, setShowDate: setShowRangeDate }}
                  onSubmit={() => setShowRangeDate(false)}
                  />
              </GridItem>
            </Grid>
            <Separator size='.62rem' />
          </Wrapper>
          <TextArea
            fontSize='1rem'
            height="7.7rem"
            label='Additional Comments'
            maxlength={MAX_CHARACTERS_TEXTAREA}
            onInput={onChangeComments}
            placeholder='Add any other additional information you consider necessary to describe the project scope.'
            value={comments ?? ''}
            id="additional-comments"
          />
        </GridItem>
      </Grid>
      {showDatePicker && (
      <Wrapper>
          <DatePicker
            value={dueDateClient}
            onChange={onChangeDate}
            handleDate={{ showDate: true, setShowDate: setShowDate }}
            onSubmit={nextStep}
          />
      </Wrapper>
      )}
      <Separator size='1.25rem' />
      <Separator size="4.5rem" mobile/>
      <ContainerMobile>
        <FooterButtons
          gotoNextStep={() => {
            if ((!disableConditions && !otherBuildingConditionsPurpose)) {
              setRequired(true)
              return
            }
            setShowDate(true)
          }}
          goBack={goToBack}
          label='Submit'
          currentStep={4}
        />
        <BgMobile />
      </ContainerMobile>
      <Separator size='1.25rem' />
    </Guard >
  )
}

export default Step4
