import React, { createContext, FC, ReactNode, useState } from 'react'

interface AuthContextProps {
    children: ReactNode,
}

export interface IAuth {
    token: string;
    config: {
      REACT_APP_RECAPTCHA_SITE_KEY: string,
      REACT_APP_MAPS_KEY: string,
      REACT_APP_API_KEY: string,
    };
    keys: any
}

export interface IAContext {
    auth: IAuth,
    setAuth: React.Dispatch<React.SetStateAction<IAuth>>
}

const defaultValue = {
  auth: {
    token: '',
    config: {
      REACT_APP_RECAPTCHA_SITE_KEY: '',
      REACT_APP_MAPS_KEY: '',
      REACT_APP_API_KEY: '',
    },
    keys: {},
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setAuth: () => {},
}

export const AuthContext = createContext<IAContext>(defaultValue)

const AuthContextProvider: FC<AuthContextProps> = ({
  children,
}) => {
  const InitialState: IAuth = {
    token: '',
    config: {
      REACT_APP_RECAPTCHA_SITE_KEY: '63615c323a783c914172e2f9',
      REACT_APP_MAPS_KEY: '63658fc83a783c9141740f08',
      REACT_APP_API_KEY: '63658fdc3a783c9141740f0f',
    },
    keys: {
      REACT_APP_RECAPTCHA_SITE_KEY: '',
      REACT_APP_MAPS_KEY: '',
      REACT_APP_API_KEY: '',
    },
  }
  const [auth, setAuth] = useState<IAuth>(InitialState)

  return (
    <AuthContext.Provider value={{
      auth,
      setAuth,
    }}>
        {children}
    </AuthContext.Provider>
  )
}

export default AuthContextProvider
