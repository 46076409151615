import React from 'react'
import { useRoutes } from 'react-router-dom'
import Main from './pages/Main'
import Quote from './pages/Quote'
import ThankyouPage from './pages/ThankyouPage'

export default function Router () {
  return useRoutes([
    {
      path: '/',
      element: <Main />,
    },
    {
      path: '/:id',
      element: <Main />,
    },
    {
      path: '/quote/1/:id',
      element: <Quote />,
    },
    {
      path: '/quote/1',
      element: <Quote />,
    },
    {
      path: '/quote/2',
      element: <Quote />,
    },
    {
      path: '/quote/3',
      element: <Quote />,
    },
    {
      path: '/quote/4',
      element: <Quote />,
    },
    {
      path: '/thankyou',
      element: <ThankyouPage />,
    },
  ])
}
