import { applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { combineEpics, createEpicMiddleware } from 'redux-observable'
import * as quotationEpics from 'src/state/quotation/epics'
// Store
import { quotationReducer } from 'src/state/quotation/reducers'
import QuotationService from 'src/services/QuotationService'
import { REACT_ENV } from 'src/utils/config'

export const rootReducer = combineReducers({
  quotation: quotationReducer,
})

const epicMiddleware = createEpicMiddleware({
  dependencies: {
    QuotationService: new QuotationService({
      baseURL: REACT_ENV.REACT_APP_BASE_URL,
    }),
  },
})

export const rootEpic: any = combineEpics(
  quotationEpics.initRFPEpic,
  quotationEpics.updateRFP,
  quotationEpics.donwloadRFP,
)

export type AppState = ReturnType<typeof rootReducer>
const composeEnhancers = composeWithDevTools({})

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(epicMiddleware)),
)

epicMiddleware.run(rootEpic)

export default store
