import React, { ReactNode, useLayoutEffect, useRef } from 'react'
import GuardProvider from '../../templates/GuardProvider'
import Panel from '../../atoms/Panel'
import { Grid, GridItem, SideBar } from 'src/shared/styles'
import packageJson from '../../../../package.json'
import { REACT_ENV } from 'src/utils/config'
import Typography from 'src/components/atoms/Typography'
import Img from 'src/components/atoms/Image'

interface GuardProps {
  children: ReactNode,
  color?: string,
  align?: 'center' | 'flex-end' | 'flex-start' | 'stretch',
  direction?: 'column' | 'row',
  margin?: string,
  width?: string,
  height?: string,
  maxWidth?: string
}

const Guard: React.FC<GuardProps> = ({
  align = 'flex-start',
  children,
  color = '#F0F0F0',
  direction = 'column',
  height,
  margin = '6rem auto 1rem',
  maxWidth = '94rem',
  width = '92%',
}: GuardProps) => {
  const guardRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (guardRef.current !== null) {
      guardRef.current.scrollIntoView()
    }
  }, [])

  return (
    <div ref={guardRef}>
      <GuardProvider>
        <Grid template='5% 95%' templateMobile='100%'>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <SideBar>
              <Img
                src={`${REACT_ENV.PUBLIC_URL}/images/vertical-sidebar.png`}
                width="100%"
              />
              <Typography variant='versionLabel' fontSize='1rem' color='white'>
                v{packageJson.version}
              </Typography>
            </SideBar>
          </div>
          <GridItem>
            <Panel
              color={color}
              align={align}
              direction={direction}
              margin={margin}
              width={width}
              height={height}
              maxWidth={maxWidth}
            >
              {children}
            </Panel>
          </GridItem>
        </Grid>
      </GuardProvider>
    </div>
  )
}

export default Guard
