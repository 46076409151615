export const REACT_ENV = (window._env_ ?? process.env)

export const OTHERLABEL = 'Other'
export const OTHERSLABEL = 'Others'
export const ERRORLABEL = 'Field is mandatory to be completed'
export const ERRORLABEL_TYPE_BUILDING = 'Project type is required'
export const ERRORLABEL_FILE_LINKS = 'Please save link before to continue'
export const SLIDER_PREV_LABEL = 'prev'
export const SLIDER_NEXT_LABEL = 'next'
export const SAVE_LINK = 'Save link'

export const isMobile = (window.innerWidth <= 760)

export const MAX_CHARACTERS_TEXTAREA = 1000
