import React, { FC } from 'react'
import { InputStyle } from 'src/components/atoms/Input'
import PhoneField from 'src/components/atoms/PhoneInput'
import Typography from 'src/components/atoms/Typography'
import { Grid, GridItem } from 'src/shared/styles'
import Theme from 'src/Theme'
import { devices } from 'src/utils'
import styled from 'styled-components'

interface ContactProjectProps {
    label: string;
    value: {
      name: string;
      companyName: string;
      phone: string;
    };
    onChange: (data:any) => void;
    fontSize: string;
}

const Container = styled.div`
    background-color: ${Theme.palette.borderInput};
    border-radius: .5rem;
    border: 1px solid ${Theme.palette.lightGrey};
    display: flex;
    flex-direction: column;
    padding: 1rem;
    width: 100%;

  @media ${devices.mobileS} and (max-width: 560px){
    padding: 1rem .5rem;
  }
`

const ContactProject: FC<ContactProjectProps> = ({
  label,
  value,
  onChange,
  ...rest
}) => {
  function handleChangeName (evt: React.ChangeEvent<HTMLInputElement>) {
    onChange({
      ...value,
      name: evt.target.value,
    })
  }

  function handleChangeCompanyName (evt: React.ChangeEvent<HTMLInputElement>) {
    onChange({
      ...value,
      companyName: evt.target.value,
    })
  }

  function handleChangePhone (phone: any) {
    const formattedValue = phone.startsWith('+') ? phone : `+${phone}`
    onChange({
      ...value,
      phone: formattedValue,
    })
  }
  return (
    <Container>
      <Typography htmlFor='ContactProject' fontSize={rest.fontSize} variant='label'>
          {label}
      </Typography>
      <Grid template='49% 48%' align='center'>
        <GridItem>
          <InputStyle
            value={value.name}
            name='contactName'
            type='text'
            withBorder={true}
            placeholder='Name'
            fontSize='1rem'
            onChange={handleChangeName}
            data-test="contact-name"
          />
        </GridItem>
        <GridItem>
          <InputStyle
            value={value.companyName}
            name='contactCompanyName'
            type='text'
            withBorder={true}
            placeholder='Company Name'
            fontSize='1rem'
            onChange={handleChangeCompanyName}
            data-test="company-name"
          />
        </GridItem>
      </Grid>
      <PhoneField value={value.phone} onChange={handleChangePhone} />
    </Container>
  )
}

export default ContactProject
