import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import QuotationService from 'src/services/QuotationService'
import { setAccessibilityAction, setAreasScanned, setBasicDataAction, setScopeOfWork } from 'src/state/quotation/actions'
import { REACT_ENV } from 'src/utils/config'

const useRefresh = (
  detailType: string,
) => {
  const dispatch = useDispatch()
  const [reload, setReload] = useState(false)
  const [data, setData] = useState<any>({})
  const setStore: any = {
    BasicData: (detail: any) => {
      return dispatch(setBasicDataAction({
        id: sessionStorage.getItem('idLanding') ?? '',
        ...detail,
      }))
    },
    ScopeOfWork: (detail: any) => {
      return dispatch(setScopeOfWork({
        ...detail,
      }))
    },
    AreasToBeScanned: (detail: any) => {
      return dispatch(setAreasScanned({
        ...detail,
      }))
    },
    Accessibility: (detail: any) => {
      return dispatch(setAccessibilityAction({
        ...detail,
      }))
    },
  }

  useEffect(() => {
    new QuotationService({
      baseURL: REACT_ENV.REACT_APP_BASE_URL,
    }).getRFP(
      sessionStorage.getItem('idLanding'),
    ).then(
      (resp) => {
        const {
          details,
          email,
        } = resp.data
        if (!details.length) return

        details.map((detail: any) => {
          if (detail.detailType === detailType) setData(detail.objValue)
          return setStore[detailType](detail.objValue)
        })
        sessionStorage.setItem('email', email)
        sessionStorage.removeItem('refresh')
        setReload(true)
      },
    )
  }, [])

  return {
    reload,
    setReload,
    data,
  }
}

export default useRefresh
