import { Carousel } from 'react-responsive-carousel'
import { EModalType } from 'src/interfaces/store/types'
import { devices } from 'src/utils'
import styled, { css } from 'styled-components'

export const TitleModal = styled.h2`
    color: ${({ theme }) => theme.palette.primary};
    font-size: 1.938rem;
    font-family: Montserrat;
    margin: 0.5rem;
    @media ${devices.mobileS} and (max-width: 35rem) {
      font-size: 1rem;
    }
`

export const DescriptionModal = styled.p<{
    type: string,
}>`
    font-size: 0.813rem;
    max-width: ${({ type }) => type === 'scanning' ? '30rem' : '90%'};
    width: 100%;
    @media ${devices.mobileS} and (max-width: 35rem) {
      font-size: .8rem;
    }
`

export const SliderCarousel = styled(Carousel)`
    max-width: 70rem;
    width: 100%;
    ul.control-dots,
    p.carousel-status {
        display: none;
    }

    & li:not(:first-child) {
      border-left: 0.063rem solid ${({ theme }) => theme.palette.gray_ligth3};
    }
`

export const SliderItem = styled.div`
  width: 100%;
  padding: 1rem;
`

export const SliderTitle = styled.h2<{ margin?: string, height?: string }>`
    color: ${({ theme }) => theme.palette.primary};
    font-family: ${({ theme }) => theme.font.primary};
    font-size: 1.063rem;
    height: ${({ height }) => height ?? '2rem'};
    margin: ${({ margin }) => margin ?? '1rem 0'};
    text-align: left;

    @media ${devices.mobileS} and (max-width: 35rem) {
      margin: .5rem 0 0;
      height: auto;
    }
`

export const SliderSubTitle = styled.label`
  color: ${({ theme }) => theme.palette.primary};
  display: block;
  font-family: ${({ theme }) => theme.font.primary};
  font-size: 0.875rem;
  font-weight: 400;
  height: 2rem;
  margin: 0.25rem 0rem;
  text-align: left;
`

export const SliderDescription = styled.p`
    font-family: ${({ theme }) => theme.font.primary};
    font-size: 0.938rem;
    margin-top: 0rem;
    min-height: 5rem;
    text-align: left;
`

export const CoverImg = styled.div<{
  src: string,
  type?: EModalType,
  backgroundSize?: string;
}>`
  background: url(${({ src }) => src}) no-repeat center center;
  border-radius: 1rem;
  height: 15.25rem;
  width: 100%;

  ${({ type, backgroundSize = 'contain' }) => type === EModalType.LOD
  ? css`
    background-position: center;
    background-size: ${backgroundSize};
    height: 8rem;
  `
  : ''}

  @media ${devices.mobileS} and (max-width: 35rem) {
    height: 12rem;
  }
`
export const PrevArrow = styled.div`
  cursor: pointer;
  left: 10px;
  position: absolute;
  top: 45%;
  z-index: 2;
`

export const NextArrow = styled.div`
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 45%;
`

export const ContainerImg = styled.div`
 align-items: center;
 display: flex;
 justify-content: space-between;

 & > div {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 33%;
 }
`
