import Typography from 'src/components/atoms/Typography'
import Theme from 'src/Theme'
import { devices } from 'src/utils'
import styled, { css } from 'styled-components'
import { CardContainerProps } from './types'

export const CardContainer = styled.div<CardContainerProps>`
  background-color: ${Theme.palette.white};
  border-radius: .5rem;
  border: 2px solid ${Theme.palette.lightGrey};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 50%;
  justify-content:space-between;
  padding-bottom: 10px;
  width: 100%;

  @media ${devices.mobileS} and (max-width: 35rem){
    border-radius: 24px;
    height: 16.5rem;
    justify-content: initial;
    max-width: 20.5rem;
    overflow:hidden;
    width:90%;

    &:nth-child(12) {
      height: 18.5rem;
    }
  
    & div{
      display:flex;
      flex-direction:column;
    }
  }

  @media ${devices.tablet} and (max-width: 64rem){
    height: 15.62rem;
    overflow:hidden;
    width: 13.12rem;

    & div{
      display:flex;
      flex-direction:column;
    }
  }

  & .card-item-title {
    display: flex;
    padding:  .2rem .2rem 0;
    text-align: center;
  }

  & .card-item-description {
    font-family: ${Theme.font.primary};
    font-size: .8rem;
    font-weight: 500;
    margin: .5rem 0;
    padding: 0 .2rem 2rem;
    text-align: center;
    @media ${devices.mobileS} and (max-width: 35rem) {
      padding: 0 .5rem .5rem;
    }
  }

  ${({ isSelected }) => isSelected
  ? css`
    border: 4px solid ${Theme.palette.primary};
  `
  : null}

  @media (min-width: 53.75rem) {
    max-width: 15%;
  }

  @media (min-width: 35rem) and (max-width: 51.25rem){
    max-width: 29%;
  }

  & .card-item-img {
    -moz-border-radius-topleft: 5px;
    -moz-border-radius-topright: 5px;
    -webkit-border-top-left-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 100%;
    height: 10rem;
    width: 100%;
  }
  transition: all .2s ease-in-out;
  &:hover {
    -moz-box-shadow: 1px -1px 7px 1px rgba(0,0,0,0.75);
    -webkit-box-shadow: 1px -1px 7px 1px rgba(0,0,0,0.75);
    box-shadow: 1px -1px 7px 1px rgba(0,0,0,0.75);
    transform: scale(1.05);
  }

  ${({ width = '20rem' }) => +width.replace(/px/g, '') < 346 && css`
    & .card-item-description {
      font-family: ${Theme.font.primary};
      font-size: 1rem;
      font-weight: 600;
    }

    & ${TitleContainer} {
      position: relative;
    }
  `}
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: .2rem 0;
  padding: 0;
`
export const CardTitle = styled<any>(Typography)`
  align-self: center;
  box-sizing: border-box;
  color: ${Theme.palette.black};
  display: flex;
  font-family: ${Theme.font.primary};
  height: 2.5rem;
  justify-content:center;
  margin: 5px 0 0 0;
  padding-right: 0!important;
  width: 13rem;
  word-wrap: break-word;
  @media ${devices.mobileS} and (max-width: 35rem){
    height: 2rem;
    width: 100%;
  }
`

export const CardInput = styled.textarea<any>`
  border-radius: .5rem; 
  border: 2px solid ${props => props.required ? Theme.palette.error : Theme.palette.lightGrey};
  font-family: ${Theme.font.primary};
  font-size: .8rem;
  height: 20%;
  line-height: 1rem;
  margin: 1rem auto 0;
  min-height: 2rem;
  padding: .5rem .5rem;
  resize: none;
  width: 90%;

  &:disabled {
    background: rgba(190, 190, 190, 0.53);
  }

  @media ${devices.mobileS} and (max-width: 35rem){
    min-height: 3.85rem;
    &::placeholder {
      display: flex;
      justify-content: center;
      padding: 0 2rem 0;
      text-align: center;
      width: 10rem;
    }
  }
`

export const CardImg = styled.div<{src: string}>`
  background-size: cover;
  background: url(${({ src }) => src}) no-repeat center center;
  max-height: 10rem;
  min-height: 8rem;
  height: 100%;
  width: 100%;

  @media ${devices.mobileS} and (max-width: 35rem){
    min-height: 10rem;
    width: 100%;
  }
`
