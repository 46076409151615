import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

interface GuardProviderProps {
    children: React.ReactNode
}

const GuardProvider: React.FC<GuardProviderProps> = ({
  children,
}: GuardProviderProps) => {
  const [searchParams] = useSearchParams()
  const router = useNavigate()
  const id = searchParams.get('id')

  useEffect(() => {
    if (id && !localStorage.idSession) {
      router(`/?id=${id}`)
    }
  }, [])
  return (
    <>
        {children}
    </>
  )
}

export default GuardProvider
