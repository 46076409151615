import React, { FC } from 'react'
import { ModalHeader } from './style'

interface IHeader {
  children: React.ReactNode,
  variant?: string,
}
const Header:FC<IHeader> = ({
  children,
  variant,
  ...props
}: IHeader) => {
  return (
    <ModalHeader {...props}>
      {children}
    </ModalHeader>
  )
}

export default Header
